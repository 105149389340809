import { NoteApi, NoteUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { NotePriorityEnum } from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

import { SafeHtml } from '@angular/platform-browser';

import { FacilityBase } from 'src/app/models/facility/facility.model';
import { CommLogUser } from 'src/app/models/note/comm-log-user.model';
import { NoteType } from 'src/app/models/note/note-type.model';

const api = apiDecorator<NoteApi>();
const apiUpdate = apiDecorator<NoteUpdateApi>();

type NoteArgs = Omit<
  ClassProperties<Note>,
  // Omit computed properties set on construct from outside data.
  'bodyNoHtml' | 'bodySafeHtml'
>;

abstract class NoteBase {
  public constructor(props: ClassProperties<NoteBase>) {
    this.body = props.body;
    this.createdDate = props.createdDate;
    this.date = props.date;
    this.facility = props.facility;
    this.isFavorite = props.isFavorite;
    this.isSigned = props.isSigned;
    this.noteFrom = props.noteFrom;
    this.noteTo = props.noteTo;
    this.priority = props.priority;
    this.replies = props.replies;
    this.type = props.type;
  }

  @api({ key: 'body' }) public readonly body: string | null;
  @api({ key: 'createdAt' }) public readonly createdDate: DateTime;
  @api({ key: 'date' }) public readonly date: DateTime;
  @api({ key: 'facility' }) public readonly facility: FacilityBase;
  @api({ key: 'favorited' }) public readonly isFavorite: boolean;
  @api({ key: 'signed' }) public readonly isSigned: boolean;
  @api({ key: 'noteFrom' }) public readonly noteFrom: CommLogUser;
  @api({ key: 'noteTo' }) public readonly noteTo: CommLogUser;
  @api({ key: 'priority' }) public readonly priority: NotePriorityEnum;
  @api({ key: 'replyCount' }) public readonly replies: number;
  @api({ key: 'type' }) public readonly type: NoteType;
}

export class Note extends NoteBase {
  public constructor(props: ClassProperties<NoteArgs>) {
    super(props);

    this.id = props.id;

    // Computed properties
    this.bodyNoHtml = this.body?.stripHtml() || null;
    this.bodySafeHtml = this.body?.toSafeHtml() || null;
  }

  /**
   * The io-ts codec for runtime type checking of the Note API model.
   */
  public static readonly Codec = io.type(
    {
      body: io.union([io.string, io.null]),
      createdAt: io.string,
      date: io.string,
      facility: FacilityBase.BaseCodec,
      favorited: io.boolean,
      id: io.number,
      noteFrom: CommLogUser.Codec,
      noteTo: CommLogUser.Codec,
      priority: io.union([
        io.literal(NotePriorityEnum.INFORMATIONAL),
        io.literal(NotePriorityEnum.URGENT),
        io.literal(NotePriorityEnum.WARNING),
      ]),
      replyCount: io.number,
      signed: io.boolean,
      type: NoteType.Codec,
    },
    'NoteApi',
  );

  @api({ key: 'id' }) public readonly id: number;

  // Computed properties
  public readonly bodyNoHtml: string | null;
  public readonly bodySafeHtml: SafeHtml | null;

  /**
   * Deserializes a Note object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Note object.
   * @throws An error if the value is not a valid Note object.
   */
  public static async deserialize(value: NonNullable<NoteApi>): Promise<Note> {
    const decoded = decode(Note.Codec, value);
    return new Note({
      body: decoded.body,
      createdDate: await DateTime.fromISO(
        decoded.createdAt,
      ).toCurrentFacilityTime(),
      date: await DateTime.fromISO(decoded.date).toCurrentFacilityTime(),
      facility: decoded.facility,
      id: decoded.id,
      isFavorite: decoded.favorited,
      isSigned: decoded.signed,
      noteFrom: CommLogUser.deserialize(decoded.noteFrom),
      noteTo: CommLogUser.deserialize(decoded.noteTo),
      priority: decoded.priority,
      replies: decoded.replyCount,
      type: NoteType.deserialize(decoded.type),
    });
  }

  /**
   * Deserializes a list of Note objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Note objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Note objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<NoteApi>>,
  ): Promise<readonly Note[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Note objects.');
    }
    return Promise.all(values.map(Note.deserialize));
  }
}

export class NoteUpdate extends NoteBase {
  public constructor(props: ClassProperties<NoteUpdate>) {
    super(props);

    this.signature = props.signature;
  }

  @apiUpdate({ key: 'signature' }) public readonly signature: string | null;

  /**
   * Serialize the data to a format that the API accepts.
   *
   * @returns The model used for updating Note data in the API.
   */
  public serialize(): NoteUpdateApi {
    const createdAt = this.createdDate.toISO();
    if (!createdAt) {
      throw new Error('Invalid created at date.');
    }

    const date = this.date.toISO();
    if (!date) {
      throw new Error('Invalid date.');
    }

    return {
      body: this.body,
      createdAt,
      date,
      facility: this.facility,
      favorited: this.isFavorite,
      noteFrom: {
        first: this.noteFrom.firstName,
        id: this.noteFrom.id,
        image: this.noteFrom.image,
        last: this.noteFrom.lastName,
      },
      noteTo: {
        first: this.noteTo.firstName,
        id: this.noteTo.id,
        image: this.noteTo.image,
        last: this.noteTo.lastName,
      },
      priority: this.priority,
      replyCount: this.replies,
      signature: this.signature,
      signed: this.isSigned,
      type: this.type,
    };
  }
}
