import { ClientUrgentAlertUserApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { NameId } from 'src/app/models/core/name-id.model';
import { UserBase } from 'src/app/models/user/user.model';

const api = apiDecorator<ClientUrgentAlertUserApi>();

export class ClientUrgentAlertUser extends UserBase {
  public constructor(props: ClassProperties<ClientUrgentAlertUser>) {
    super(props);

    this.doctor = props.doctor;
    this.guid = props.guid;
    this.role = props.role;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Urgent Alert User
   * API model.
   */
  public static readonly Codec = io.intersection([
    UserBase.BaseCodec,
    io.type(
      {
        doctor: io.boolean,
        guid: io.string,
        role: io.union([NameId.Codec, io.null]),
      },
      'ClientUrgentAlertUserApi',
    ),
  ]);

  @api({ key: 'doctor' }) public readonly doctor: boolean;
  @api({ key: 'guid' }) public readonly guid: string;
  @api({ key: 'role' }) public readonly role: NameId | null;

  /**
   * Deserializes a Client Urgent Alert User object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Urgent Alert User object.
   * @throws An error if the value is not a valid Client Urgent Alert User
   * object.
   */
  public static override deserialize(
    value: NonNullable<ClientUrgentAlertUserApi>,
  ): ClientUrgentAlertUser {
    const decoded = decode(ClientUrgentAlertUser.Codec, value);
    const userBase = UserBase.deserialize(decoded);
    return new ClientUrgentAlertUser({
      ...decoded,
      ...userBase,
      role: decoded.role ? NameId.deserialize(decoded.role) : null,
    });
  }

  /**
   * Deserializes a list of Client Urgent Alert User objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Urgent Alert User objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Urgent Alert
   * User objects.
   */
  public static override deserializeList(
    values: ReadonlyArray<NonNullable<ClientUrgentAlertUserApi>>,
  ): readonly ClientUrgentAlertUser[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Urgent Alert User objects.');
    }
    return values.map(ClientUrgentAlertUser.deserialize);
  }
}
