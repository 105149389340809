import { firstValueFrom } from 'rxjs';
import { BillingRule } from 'src/app/models';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AlertService } from '../root/alert.service';
import { BillingRulesService } from './billing-rules.service';

@Injectable()
export class BillingRuleResolverService {
  public constructor(
    private readonly alert: AlertService,
    private readonly billingRulesService: BillingRulesService,
    private readonly router: Router,
  ) {}

  public async resolve(
    next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Promise<BillingRule | null> {
    const id = Number(next.params['id']);
    if (isNaN(id)) {
      return this.handleNotFound();
    }

    const billingRule = await firstValueFrom(this.billingRulesService.get(id));
    if (!billingRule) {
      return this.handleNotFound();
    }

    return billingRule;
  }

  private handleNotFound(): null {
    this.alert.error({
      message: `Invalid Billing Payor Rule, please try again.`,
    });
    const route: PageRoute = '/settings/billing/rules';
    this.router.navigate([route]);
    return null;
  }
}
