import { PrivatePaymentApi, PrivatePaymentPagedListApi } from 'api/models';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { SortOrderEnum } from 'src/app/enumerators';
import { Client, PrivatePayment, PrivatePaymentUpdate } from 'src/app/models';
import { PagedListCodec, decode, parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ClientPrivatePaymentService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Delete a private payment record by id.
   *
   * @param privatePaymentId The id of the private payment to delete.
   * @returns True if the private payment was deleted, false otherwise.
   */
  public delete(
    clientId: Client['id'],
    privatePaymentId: PrivatePayment['id'],
  ): Observable<boolean> {
    return this.httpClient
      .delete<
        boolean | undefined
      >(`${config.api.url}/clients/${clientId}/private-pay/${privatePaymentId}`, { observe: 'response' })
      .pipe(
        // Return bool based on 'success' status codes (200's) check.
        map((response) => response.status >= 200 && response.status < 300),
        catchError((error: unknown) => {
          console.error(error);
          return of(false);
        }),
      );
  }

  /**
   * Fetches a paged list of private payments for a client.
   *
   * @param clientId The id of the client to fetch private payments for.
   * @returns A list of private payments for the client.
   */
  public getPagedList(
    clientId: Client['id'],
    requestParameters?: readonly ClientPrivatePaymentRequestParam[],
  ): Observable<PagedList<PrivatePayment> | undefined> {
    return this.httpClient
      .get<PrivatePaymentPagedListApi | undefined>(
        `${config.api.url}/clients/${clientId}/private-pay`,
        {
          params: parseHttpParams(requestParameters),
        },
      )
      .pipe(
        switchMap(async (response) => {
          if (response === undefined) return undefined;
          const pagedClientList: PagedList<PrivatePayment> = {
            ...decode(PrivatePaymentPagedListCodec, response),
            results: await PrivatePayment.deserializeList(response.results),
          };
          return pagedClientList;
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Patch a client's insurance private payment record.
   *
   * @param clientId The id of the client to patch the private payment for.
   * @param privatePaymentId The id of the private payment to patch.
   * @param privatePaymentUpdate The private payment values to patch.
   * @returns The patched private payment record, or undefined on error.
   */
  public patch(
    clientId: number,
    privatePaymentId: PrivatePayment['id'],
    privatePaymentUpdate: PrivatePaymentUpdate,
  ): Observable<PrivatePayment | undefined> {
    return this.httpClient
      .patch<
        PrivatePaymentApi | undefined
      >(`${config.api.url}/clients/${clientId}/private-pay/${privatePaymentId}`, privatePaymentUpdate.serialize())
      .pipe(
        switchMap((response) =>
          response ? PrivatePayment.deserialize(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Add (post) a client's private payment record.
   *
   * @param clientId The id of the client to add the new private payment for.
   * @param privatePaymentUpdate The private payment values to post.
   */
  public post(
    clientId: Client['id'],
    privatePaymentUpdate: PrivatePaymentUpdate,
  ): Observable<PrivatePayment | undefined> {
    return this.httpClient
      .post<
        PrivatePaymentApi | undefined
      >(`${config.api.url}/clients/${clientId}/private-pay`, privatePaymentUpdate.serialize())
      .pipe(
        switchMap((response) =>
          response ? PrivatePayment.deserialize(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}

const PrivatePaymentPagedListCodec = PagedListCodec(
  PrivatePayment.Codec,
  'PrivatePaymentPagedListApi',
);

/**
 * Request parameter interface for use with the Billing API.
 */
export interface ClientPrivatePaymentRequestParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'order' | 'sort' | 'pageNumber' | 'pageSize';
  /** The value to use for the query parameter. */
  value: 'addedAt' | SortOrderEnum | number;
}
