import { Component, HostListener, OnDestroy } from '@angular/core';

import { HoverPreviewRef } from 'src/app/components/hover-preview/hover-preview.component';

@Component({
  selector: 'alleva-image-hover-preview',
  template: `<img [src]="imgHoverPreviewData.src" />`,
  styles: [
    `
      @import 'src/app/styles/colors';
      :host {
        border: 2px solid $gray-mid;
        display: block;
        max-height: 500px;
        max-width: 500px;
      }
    `,
  ],
})
export class ImageHoverPreviewComponent implements OnDestroy {
  public constructor(
    private readonly hoverPreviewReference: HoverPreviewRef<ImageHoverPreviewData>,
  ) {}

  public readonly imgHoverPreviewData: ImageHoverPreviewData =
    this.hoverPreviewReference.data;

  @HostListener('mouseleave', ['$event'])
  public closeImagePreview(_: MouseEvent): void {
    this.hoverPreviewReference.closePreview();
  }

  public ngOnDestroy(): void {
    this.hoverPreviewReference.closePreview();
  }
}

export interface ImageHoverPreviewData {
  src: string;
}
