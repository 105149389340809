import {
  ReleaseOfInformationApi,
  ReleaseOfInformationUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { ClientContact } from 'src/app/models/client/client-contact.model';

import { NameId } from './core/name-id.model';

const api = apiDecorator<ReleaseOfInformationApi>();
const apiUpdate = apiDecorator<ReleaseOfInformationUpdateApi>();

abstract class ReleaseOfInformationBase {
  public constructor(props: ClassProperties<ReleaseOfInformationBase>) {
    this.copyAccepted = props.copyAccepted;
    this.endDate = props.endDate;
    this.startDate = props.startDate;
  }

  @api({ key: 'copyAccepted' }) public copyAccepted: boolean;
  @api({ key: 'endDate' }) public endDate: DateTime;
  @api({ key: 'startDate' }) public startDate: DateTime;
}

export class ReleaseOfInformation extends ReleaseOfInformationBase {
  public constructor(props: ClassProperties<ReleaseOfInformation>) {
    super(props);

    this.clientSignature = props.clientSignature;
    this.contact = props.contact;
    this.createdBy = props.createdBy;
    this.createdDate = props.createdDate;
    this.description = props.description;
    this.expirationDate = props.expirationDate;
    this.guardianSignature = props.guardianSignature;
    this.guardianSignatureDate = props.guardianSignatureDate;
    this.id = props.id;
    this.informationDisclosed = props.informationDisclosed;
    this.isRevoked = props.isRevoked;
    this.leadId = props.leadId;
    this.otherInformation = props.otherInformation;
    this.purpose = props.purpose;
    this.releaseMethods = props.releaseMethods;
    this.revokeDate = props.revokeDate;
    this.revokeReason = props.revokeReason;
    this.revokeSignature = props.revokeSignature;
    this.serviceFromDate = props.serviceFromDate;
    this.serviceToDate = props.serviceToDate;
    this.staffSignature = props.staffSignature;
    this.submitDate = props.submitDate;
  }

  /**
   * The io-ts codec for runtime type checking of the Release Of Information
   * (ROI) API model.
   */
  public static readonly Codec = io.type(
    {
      contact: ClientContact.Codec,
      copyAccepted: io.boolean,
      createdBy: io.union([io.string, io.null]),
      createdDate: io.string,
      description: io.union([io.string, io.null]),
      endDate: io.string,
      expirationDate: io.union([io.string, io.null]),
      guardianSignature: io.union([io.string, io.null]),
      guardianSignatureDate: io.union([io.string, io.null]),
      id: io.number,
      informationDisclosed: io.union([io.array(NameId.Codec), io.null]),
      isRevoked: io.boolean,
      leadId: io.number,
      otherInformation: io.union([io.string, io.null]),
      purpose: io.union([io.array(NameId.Codec), io.null]),
      releaseMethods: io.union([io.array(NameId.Codec), io.null]),
      revokeDate: io.union([io.string, io.null]),
      revokeReason: io.union([io.string, io.null]),
      revokeSignature: io.union([io.string, io.null]),
      serviceFrom: io.union([io.string, io.null]),
      serviceTo: io.union([io.string, io.null]),
      signature: io.union([io.string, io.null]),
      staffSignature: io.union([io.string, io.null]),
      startDate: io.string,
      submitDate: io.union([io.string, io.null]),
    },
    'ReleaseOfInformationApi',
  );

  @api({ key: 'signature' }) public clientSignature: Base64<'png'> | null;
  @api({ key: 'contact' }) public contact: ClientContact;
  @api({ key: 'createdBy' }) public createdBy: string | null;
  @api({ key: 'createdDate' }) public createdDate: DateTime;
  @api({ key: 'description' }) public description: string | null;
  @api({ key: 'expirationDate' }) public expirationDate: DateTime | null;
  @api({ key: 'guardianSignature' })
  public guardianSignature: Base64<'png'> | null;
  @api({ key: 'guardianSignatureDate' })
  public guardianSignatureDate: DateTime | null;
  @api({ key: 'id' }) public id: number;
  @api({ key: 'informationDisclosed' }) public informationDisclosed:
    | readonly NameId[]
    | null;
  @api({ key: 'isRevoked' }) public isRevoked: boolean;
  @api({ key: 'leadId' }) public leadId: number;
  @api({ key: 'otherInformation' }) public otherInformation: string | null;
  @api({ key: 'purpose' }) public purpose: readonly NameId[] | null;
  @api({ key: 'releaseMethods' }) public releaseMethods:
    | readonly NameId[]
    | null;
  @api({ key: 'revokeDate' }) public revokeDate: DateTime | null;
  @api({ key: 'revokeReason' }) public revokeReason: string | null;
  @api({ key: 'revokeSignature' }) public revokeSignature: Base64<'png'> | null;
  @api({ key: 'serviceFrom' }) public serviceFromDate: DateTime | null;
  @api({ key: 'serviceTo' }) public serviceToDate: DateTime | null;
  @api({ key: 'staffSignature' }) public staffSignature: Base64<'png'> | null;
  @api({ key: 'submitDate' }) public submitDate: DateTime | null;

  /**
   * Deserializes a Release of Information object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Release of Information object.
   * @throws An error if the value is not a valid Release of Information
   * object.
   */
  public static async deserialize(
    value: NonNullable<ReleaseOfInformationApi>,
  ): Promise<ReleaseOfInformation> {
    const decoded = decode(ReleaseOfInformation.Codec, value);
    return new ReleaseOfInformation({
      ...decoded,
      clientSignature: decoded.signature as Base64<'png'> | null,
      contact: await ClientContact.deserialize(decoded.contact),
      createdDate: await DateTime.fromISO(
        decoded.createdDate,
      ).toCurrentFacilityTime(),
      endDate: await DateTime.fromISO(decoded.endDate).toCurrentFacilityTime(),
      expirationDate: decoded.expirationDate
        ? await DateTime.fromISO(decoded.expirationDate).toCurrentFacilityTime()
        : null,
      guardianSignature: decoded.guardianSignature as Base64<'png'> | null,
      guardianSignatureDate: decoded.guardianSignatureDate
        ? await DateTime.fromISO(
            decoded.guardianSignatureDate,
          ).toCurrentFacilityTime()
        : null,
      informationDisclosed: decoded.informationDisclosed
        ? NameId.deserializeList(decoded.informationDisclosed)
        : null,
      purpose: decoded.purpose ? NameId.deserializeList(decoded.purpose) : null,
      releaseMethods: decoded.releaseMethods
        ? NameId.deserializeList(decoded.releaseMethods)
        : null,
      revokeDate: decoded.revokeDate
        ? await DateTime.fromISO(decoded.revokeDate).toCurrentFacilityTime()
        : null,
      revokeSignature: decoded.revokeSignature as Base64<'png'> | null,
      serviceFromDate: decoded.serviceFrom
        ? await DateTime.fromISO(decoded.serviceFrom).toCurrentFacilityTime()
        : null,
      serviceToDate: decoded.serviceTo
        ? await DateTime.fromISO(decoded.serviceTo).toCurrentFacilityTime()
        : null,
      staffSignature: decoded.staffSignature as Base64<'png'> | null,
      startDate: await DateTime.fromISO(
        decoded.startDate,
      ).toCurrentFacilityTime(),
      submitDate: decoded.submitDate
        ? await DateTime.fromISO(decoded.submitDate).toCurrentFacilityTime()
        : null,
    });
  }

  /**
   * Deserializes a list of Release of Information objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Release of Information objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Release of
   * Information objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<ReleaseOfInformationApi>>,
  ): Promise<readonly ReleaseOfInformation[]> {
    if (!Array.isArray(values)) {
      throw new Error(
        'Expected array of Release Of Information (ROI) objects.',
      );
    }
    return Promise.all(values.map(ReleaseOfInformation.deserialize));
  }
}

export class ReleaseOfInformationUpdate extends ReleaseOfInformationBase {
  public constructor(props: ClassProperties<ReleaseOfInformationUpdate>) {
    super(props);

    this.description = props.description;
    this.expirationDate = props.expirationDate;
    this.guardianSignature = props.guardianSignature;
    this.guardianSignatureDate = props.guardianSignatureDate;
    this.informationDisclosed = props.informationDisclosed;
    this.otherInformation = props.otherInformation;
    this.purpose = props.purpose;
    this.releaseMethods = props.releaseMethods;
    this.serviceFrom = props.serviceFrom;
    this.serviceTo = props.serviceTo;
    this.signature = props.signature;
    this.staffSignature = props.staffSignature;
    this.startSubmitDate = props.startSubmitDate;
    this.submitDate = props.submitDate;
  }

  @apiUpdate({ key: 'description' }) public readonly description: string;
  @apiUpdate({ key: 'expirationDate' })
  public readonly expirationDate: DateTime;
  @apiUpdate({ key: 'guardianSignature' })
  public readonly guardianSignature: Base64<'png'>;
  @apiUpdate({ key: 'guardianSignatureDate' })
  public readonly guardianSignatureDate: DateTime;
  @apiUpdate({ key: 'informationDisclosed' })
  public readonly informationDisclosed: ReadonlyArray<{ id: number }>;
  @apiUpdate({ key: 'otherInformation' })
  public readonly otherInformation: string;
  @apiUpdate({ key: 'purpose' }) public readonly purpose: ReadonlyArray<{
    id: number;
  }>;
  @apiUpdate({ key: 'releaseMethods' })
  public readonly releaseMethods: ReadonlyArray<{ id: number }>;
  @apiUpdate({ key: 'serviceFrom' }) public readonly serviceFrom: DateTime;
  @apiUpdate({ key: 'serviceTo' }) public readonly serviceTo: DateTime;
  @apiUpdate({ key: 'signature' }) public readonly signature: Base64<'png'>;
  @apiUpdate({ key: 'staffSignature' })
  public readonly staffSignature: Base64<'png'>;
  @apiUpdate({ key: 'startSubmitDate' })
  public readonly startSubmitDate: DateTime;
  @apiUpdate({ key: 'submitDate' }) public readonly submitDate: DateTime;

  /**
   * Serialize the data to a format that the API accepts.
   */
  public serialize(): ReleaseOfInformationUpdateApi {
    return {
      copyAccepted: this.copyAccepted,
      description: this.description,
      endDate: this.endDate.toISODate()!,
      expirationDate: this.expirationDate.toISODate()!,
      guardianSignature: this.guardianSignature,
      guardianSignatureDate: this.guardianSignatureDate.toISODate()!,
      informationDisclosed: this.informationDisclosed,
      otherInformation: this.otherInformation,
      purpose: this.purpose,
      releaseMethods: this.releaseMethods,
      serviceFrom: this.serviceFrom.toISODate()!,
      serviceTo: this.serviceTo.toISODate()!,
      signature: this.signature,
      staffSignature: this.staffSignature,
      startDate: this.startDate.toISODate()!,
      startSubmitDate: this.startSubmitDate.toISODate()!,
      submitDate: this.submitDate.toISODate()!,
    };
  }
}
