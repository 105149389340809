import { ClientCIWABApi, ClientCIWABUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import {
  ClientCIWABAssessment,
  ClientCIWABAssessmentUpdate,
} from 'src/app/models/client/client-ciwa-b-assessment.model';
import {
  ClientCIWABCreator,
  ClientCIWABCreatorUpdate,
} from 'src/app/models/client/client-ciwa-b-creator.model';
import {
  ClientCIWABReviewer,
  ClientCIWABReviewerUpdate,
} from 'src/app/models/client/client-ciwa-b-reviewer.model';
import {
  ClientCIWABVitals,
  ClientCIWABVitalsUpdate,
} from 'src/app/models/client/client-ciwa-b-vitals.model';

const api = apiDecorator<ClientCIWABApi>();
const apiUpdate = apiDecorator<ClientCIWABUpdateApi>();

abstract class ClientCIWABBase {
  public constructor(props: ClassProperties<ClientCIWABBase>) {
    this.date = props.date;
    this.notes = props.notes;
  }

  @api({ key: 'date' }) public readonly date: DateTime;
  @api({ key: 'notes' }) public readonly notes: string | null;
}

/**
 * The Client CIWA-B ("Clinical Withdrawal Assessment - Benzodiazepines") model.
 */
export class ClientCIWAB extends ClientCIWABBase {
  public constructor(props: ClassProperties<ClientCIWAB>) {
    super(props);

    this.administered = props.administered;
    this.assessment = props.assessment;
    this.createdBy = props.createdBy;
    this.id = props.id;
    this.reviewers = props.reviewers;
    this.score = props.score;
    this.vitals = props.vitals;
  }

  /**
   * The io-ts codec for runtime type checking of the Client CIWA-B API model.
   */
  public static readonly Codec = io.type(
    {
      administered: io.union([ClientCIWABReviewer.Codec, io.undefined]),
      assessment: ClientCIWABAssessment.Codec,
      createdBy: ClientCIWABCreator.Codec,
      date: io.string,
      id: io.number,
      notes: io.union([io.string, io.null]),
      reviewers: io.union([io.array(ClientCIWABReviewer.Codec), io.null]),
      score: io.number,
      vitals: io.union([ClientCIWABVitals.Codec, io.null]),
    },
    'ClientCIWABApi',
  );

  @api({ key: 'administered' })
  public readonly administered: ClientCIWABReviewer | null;
  @api({ key: 'assessment' })
  public readonly assessment: ClientCIWABAssessment;
  @api({ key: 'createdBy' }) public readonly createdBy: ClientCIWABCreator;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'reviewers' }) public readonly reviewers:
    | readonly ClientCIWABReviewer[]
    | null;
  @api({ key: 'score' }) public readonly score: number;
  @api({ key: 'vitals' }) public readonly vitals: ClientCIWABVitals | null;

  /**
   * Deserializes a Client CIWA-B object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client CIWA-B object.
   * @throws An error if the value is not a valid Client CIWA-B object.
   */
  public static async deserialize(
    value: NonNullable<ClientCIWABApi>,
  ): Promise<ClientCIWAB> {
    const decoded = decode(ClientCIWAB.Codec, value);
    return new ClientCIWAB({
      administered: decoded.administered
        ? await ClientCIWABReviewer.deserialize(decoded.administered)
        : null,
      assessment: ClientCIWABAssessment.deserialize(decoded.assessment),
      createdBy: await ClientCIWABCreator.deserialize(decoded.createdBy),
      date: await DateTime.fromISO(decoded.date).toCurrentFacilityTime(),
      id: decoded.id,
      notes: decoded.notes ?? null,
      reviewers: decoded.reviewers
        ? await ClientCIWABReviewer.deserializeList(decoded.reviewers)
        : null,
      score: decoded.score,
      vitals: decoded.vitals
        ? await ClientCIWABVitals.deserialize(decoded.vitals)
        : null,
    });
  }

  /**
   * Deserializes a list of Client CIWA-B objects from an API/unknown model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client CIWA-B objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client CIWA-B objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<unknown>>,
  ): Promise<readonly ClientCIWAB[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client CIWA-B objects.');
    }
    return Promise.all(values.map(ClientCIWAB.deserialize));
  }
}

export class ClientCIWABUpdate extends ClientCIWABBase {
  public constructor(props: ClassProperties<ClientCIWABUpdate>) {
    super(props);

    this.administered = props.administered;
    this.createdBy = props.createdBy;
    this.reviewers = props.reviewers;
    this.assessment = props.assessment;
    this.vitals = props.vitals;
  }

  @apiUpdate({ key: 'administered' })
  public readonly administered: ClientCIWABReviewerUpdate;
  @apiUpdate({ key: 'assessment' })
  public readonly assessment: ClientCIWABAssessmentUpdate;
  @apiUpdate({ key: 'createdBy' })
  public readonly createdBy: ClientCIWABCreatorUpdate;
  @apiUpdate({ key: 'reviewers' }) public readonly reviewers:
    | readonly ClientCIWABReviewerUpdate[]
    | null;
  @apiUpdate({ key: 'vitals' }) public readonly vitals: ClientCIWABVitalsUpdate;

  public serialize(): ClientCIWABUpdateApi {
    return {
      administered: this.administered.serialize(),
      assessment: this.assessment.serialize(),
      createdBy: this.createdBy.serialize(),
      date: this.date.toISO()!,
      notes: this.notes,
      reviewers:
        this.reviewers?.map((reviewer) => reviewer.serialize()) ?? null,
      score: this.assessment.score,
      vitals: this.vitals.serialize(),
    };
  }
}
