import { DailyQuestionnaireResultsApi } from 'api/models';
import { DateTime } from 'luxon';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { SortOrderEnum } from 'src/app/enumerators';
import { Client, DailyQuestionnaireResults } from 'src/app/models';
import { parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the Daily Questionnaire API.
 */
@Injectable()
export class DailyQuestionnaireService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch and return all Daily Questionnaire results data by client id, from
   * date, and to date.
   *
   * @param clientId The id of the client to fetch Daily Questionnaire results
   * for.
   * @param fromDate The date to fetch Daily Questionnaire results from.
   * @param toDate The date to fetch Daily Questionnaire results to.
   * @param requestParameters Optional request parameters.
   * @returns All Daily Questionnaire results data on success, undefined on
   * error.
   */
  public getResults(
    clientId: Client['id'],
    fromDate: DateTime,
    toDate: DateTime,
    requestParameters?: readonly DailyQuestionnaireRequestParam[],
  ): Observable<readonly DailyQuestionnaireResults[] | undefined> {
    const params = parseHttpParams(requestParameters)
      ?.append('fromDate', fromDate.toISODate()!)
      ?.append('toDate', toDate.toISODate()!);
    return this.httpClient
      .get<readonly DailyQuestionnaireResultsApi[] | undefined>(
        `${config.api.url}/clients/${clientId}/daily-questionnaire-results`,
        {
          params: {
            ...params,
            fromDate: fromDate.toFormat('mm dd yyyy'),
            toDate: toDate.toFormat('mm dd yyyy'),
          },
        },
      )
      .pipe(
        switchMap((response) =>
          response
            ? DailyQuestionnaireResults.deserializeList(response)
            : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}

/**
 * Request parameter interface for use with the Daily Questionnaire API.
 */
interface DailyQuestionnaireRequestParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'orderby' | 'sort';
  /** The value to use for the query parameter. */
  value: keyof DailyQuestionnaireResults | SortOrderEnum;
}
