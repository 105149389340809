import {
  ClientUrgentAlertAcknowledgeUpdateApi,
  ClientUrgentAlertApi,
  ClientUrgentAlertResolveUpdateApi,
  ClientUrgentAlertUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { UrgentAlertStatusEnum } from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

import { ClientUrgentAlertAcknowledgement } from 'src/app/models/client/client-urgent-alert-acknowledgement.model';
import { ClientUrgentAlertUser } from 'src/app/models/client/client-urgent-alert-user.model';

const api = apiDecorator<ClientUrgentAlertApi>();
const apiUpdate = apiDecorator<ClientUrgentAlertUpdateApi>();

const apiAcknowledgeUpdate =
  apiDecorator<ClientUrgentAlertAcknowledgeUpdateApi>();
const apiResolveUpdate = apiDecorator<ClientUrgentAlertResolveUpdateApi>();

type ClientUrgentAlertArgs = Omit<
  ClassProperties<ClientUrgentAlert>,
  // Omit computed properties set on construct from outside data.
  'status'
>;

export class ClientUrgentAlert {
  public constructor(props: ClassProperties<ClientUrgentAlertArgs>) {
    this.acknowledgements = props.acknowledgements;
    this.createdBy = props.createdBy;
    this.createdDate = props.createdDate;
    this.id = props.id;
    this.message = props.message;
    this.resolved = props.resolved;
    this.resolvedBy = props.resolvedBy;

    // Computed values.
    this.status = this.resolved
      ? UrgentAlertStatusEnum.RESOLVED
      : UrgentAlertStatusEnum.ACTIVE;
  }

  /**
   * The io-ts codec for runtime type checking of the Urgent Alert API model.
   */
  public static readonly Codec = io.type(
    {
      acknowledgements: io.union([
        io.array(ClientUrgentAlertAcknowledgement.Codec),
        io.null,
      ]),
      createdBy: ClientUrgentAlertUser.Codec,
      date: io.string,
      id: io.number,
      message: io.union([io.string, io.null]),
      resolved: io.boolean,
      resolvedBy: io.union([ClientUrgentAlertUser.Codec, io.null]),
    },
    'ClientUrgentAlertApi',
  );

  @api({ key: 'acknowledgements' }) public readonly acknowledgements:
    | readonly ClientUrgentAlertAcknowledgement[]
    | null;
  @api({ key: 'createdBy' }) public readonly createdBy: ClientUrgentAlertUser;
  @api({ key: 'date' }) public readonly createdDate: DateTime;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'message' }) public readonly message: string | null;
  @api({ key: 'resolved' }) public readonly resolved: boolean;
  @api({ key: 'resolvedBy' })
  public readonly resolvedBy: ClientUrgentAlertUser | null;

  // Computed values.
  public readonly status: UrgentAlertStatusEnum;

  /**
   * Deserializes a Urgent Alert object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Urgent Alert object.
   * @throws An error if the value is not a valid Urgent Alert object.
   */
  public static async deserialize(
    value: NonNullable<ClientUrgentAlertApi>,
  ): Promise<ClientUrgentAlert> {
    const decoded = decode(ClientUrgentAlert.Codec, value);
    return new ClientUrgentAlert({
      acknowledgements: decoded.acknowledgements
        ? await ClientUrgentAlertAcknowledgement.deserializeList(
            decoded.acknowledgements,
          )
        : null,
      createdBy: await ClientUrgentAlertUser.deserialize(decoded.createdBy),
      createdDate: await DateTime.fromISO(decoded.date).toCurrentFacilityTime(),
      id: decoded.id,
      message: decoded.message,
      resolved: decoded.resolved,
      resolvedBy: decoded.resolvedBy
        ? await ClientUrgentAlertUser.deserialize(decoded.resolvedBy)
        : null,
    });
  }

  /**
   * Deserializes a list of Urgent Alert objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Urgent Alert objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Urgent Alert objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<ClientUrgentAlertApi>>,
  ): Promise<readonly ClientUrgentAlert[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Urgent Alert objects.');
    }
    return Promise.all(values.map(ClientUrgentAlert.deserialize));
  }
}

/**
 * The update model for acknowledging an Urgent Alert.
 */
export class ClientUrgentAlertUpdate {
  public constructor(props: ClassProperties<ClientUrgentAlertUpdate>) {
    this.message = props.message;
    this.signature = props.signature;
  }

  @apiUpdate({ key: 'message' }) public readonly message: string | null;
  @apiUpdate({ key: 'signature' }) public readonly signature: Base64<'png'>;

  public serialize(): ClientUrgentAlertUpdateApi {
    return {
      message: this.message,
      signature: this.signature,
    };
  }
}

/**
 * The update model for acknowledging an Urgent Alert.
 */
export class ClientUrgentAlertAcknowledgeUpdate {
  public constructor(
    props: ClassProperties<ClientUrgentAlertAcknowledgeUpdate>,
  ) {
    this.message = props.message;
    this.signature = props.signature;
  }

  @apiAcknowledgeUpdate({ key: 'message' }) public readonly message:
    | string
    | null;
  @apiAcknowledgeUpdate({ key: 'signature' })
  public readonly signature: Base64<'png'>;

  public serialize(): ClientUrgentAlertAcknowledgeUpdateApi {
    return {
      message: this.message,
      signature: this.signature,
    };
  }
}

/**
 * The update model for resolving an Urgent Alert.
 */
export class ClientUrgentAlertResolveUpdate {
  public constructor(props: ClassProperties<ClientUrgentAlertResolveUpdate>) {
    this.message = props.message;
    this.signature = props.signature;
  }

  @apiResolveUpdate({ key: 'message' }) public readonly message: string | null;
  @apiResolveUpdate({ key: 'signature' })
  public readonly signature: Base64<'png'>;

  public serialize(): ClientUrgentAlertResolveUpdateApi {
    return {
      message: this.message,
      signature: this.signature,
    };
  }
}
