import { CommentApi, CommentCreateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { UserBase } from 'src/app/models/user/user.model';

const api = apiDecorator<CommentApi>();

export class CommentBase {
  public constructor(props: ClassProperties<CommentBase>) {
    this.body = props.body;
    this.replyAt = props.replyAt;
  }

  @api({ key: 'body' }) public readonly body: string;
  @api({ key: 'replyAt' }) public readonly replyAt: DateTime;
}

export class Comment extends CommentBase {
  public constructor(props: ClassProperties<Comment>) {
    super(props);
    this.id = props.id;
    this.replyFrom = props.replyFrom;
  }

  /**
   * The io-ts codec for runtime type checking of the Comment model.
   */
  public static readonly Codec = io.type(
    {
      body: io.string,
      id: io.number,
      replyAt: io.string,
      replyFrom: UserBase.BaseCodec,
    },
    'CommentApi',
  );

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'replyFrom' }) public readonly replyFrom: UserBase;

  /**
   * Deserializes a Comment object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Comment object.
   * @throws An error if the value is not a valid Comment object.
   */
  public static async deserialize(
    value: NonNullable<CommentApi>,
  ): Promise<Comment> {
    const decoded = decode(Comment.Codec, value);
    return new Comment({
      ...decoded,
      replyAt: await DateTime.fromISO(decoded.replyAt).toCurrentFacilityTime(),
      replyFrom: UserBase.deserialize(decoded.replyFrom),
    });
  }

  /**
   * Deserializes a list of Comment objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Comment objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Comment
   * objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<CommentApi>>,
  ): Promise<readonly Comment[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Comment objects.');
    }
    return Promise.all(values.map(Comment.deserialize));
  }
}

export class CommentCreate extends CommentBase {
  public constructor(props: ClassProperties<CommentCreate>) {
    super(props);
    this.replyFrom = props.replyFrom;
  }

  public readonly replyFrom: { id: UserBase['id'] };

  public serialize(): CommentCreateApi {
    return {
      ...this,
      replyAt: this.replyAt.toISO(),
      replyFrom: {
        id: this.replyFrom.id,
      },
    };
  }
}
