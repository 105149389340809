import { DateTime } from 'luxon';
import { firstValueFrom } from 'rxjs';
import { FacilityService } from 'src/app/services';

declare module 'luxon' {
  interface DateTime {
    toCurrentFacilityTime: () => Promise<DateTime>;
  }
}

export function initializeDateTime(facilityService: FacilityService) {
  return (): void => {
    if (!DateTime.prototype.toCurrentFacilityTime) {
      DateTime.prototype.toCurrentFacilityTime =
        async function (): Promise<DateTime> {
          const currentFacility = await firstValueFrom(
            facilityService.currentFacilityChanges,
          );
          if (!currentFacility || !currentFacility.timeZone) {
            throw new Error('Current facility or timeZone is not set.');
          }
          return this.setZone(currentFacility.timeZone);
        };
    }
  };
}
