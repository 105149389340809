import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * A service that exposes the Angular `DomSanitizer` service dependency at the
 * root level.
 */
@Injectable({ providedIn: 'root' })
export class SanitizerService {
  public constructor(public readonly sanitizer: DomSanitizer) {}
}
