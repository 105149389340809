import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  startWith,
} from 'rxjs';
import { ScreenSizeEnum } from 'src/app/enumerators';
import {
  AppUpdateService,
  AuthenticationService,
  CurrentClientService,
  CurrentRouteDataService,
  MenuService,
  MetaService,
  ScreenLockService,
  ScreenSizeService,
} from 'src/app/services';
import {
  getFirstNonEmptyValueFrom,
  isNonEmptyValue,
  scrollTop,
  shareSingleReplay,
} from 'src/app/utilities';
import { config } from 'src/configs/config';

import { AfterContentInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

/**
 * The root component of the application that handles core routing features
 * and layout templating.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnInit, AfterContentInit {
  public constructor(
    private readonly appUpdateService: AppUpdateService,
    private readonly authenticationService: AuthenticationService,
    private readonly currentClientService: CurrentClientService,
    private readonly currentRouteDataService: CurrentRouteDataService,
    private readonly menuService: MenuService,
    private readonly metaService: MetaService,
    private readonly router: Router,
    private readonly screenLockService: ScreenLockService,
    private readonly screenSizeService: ScreenSizeService,
  ) {}

  protected readonly isQuickLaunchMenuEnabled =
    config.features.isQuickLaunchMenuEnabled;

  /**
   * Whether or not the screen is locked (i.e. the user is on the lock screen).
   */
  protected readonly isScreenLockedChanges =
    this.screenLockService.isLockedChanges;

  /**
   * Whether or not the page is loading (i.e. a route change is in progress).
   */
  protected readonly isLoadingChanges = this.router.events.pipe(
    filter(
      (event) =>
        event instanceof NavigationStart || event instanceof NavigationEnd,
    ),
    map((event) => (event instanceof NavigationStart ? true : false)),
    startWith(false),
    distinctUntilChanged(),
    shareSingleReplay(),
  );

  protected readonly hasAuthenticationErrorSignal =
    this.authenticationService.hasErrorSignal;

  /** Whether or not the user is authenticated (logged in). */
  protected readonly isAuthenticatedChanges =
    this.authenticationService.userChanges.pipe(map((user) => user !== null));

  /** The route data changes for the current route. */
  private readonly routeDataChanges =
    this.currentRouteDataService.routeDataChanges;

  /** Whether or not the user is logging out. */
  protected readonly isLoggingOutChanges =
    this.authenticationService.isLoggingOutChanges;

  public async ngOnInit(): Promise<void> {
    // Set non-dynamic meta tags.
    this.metaService.setNonDynamicMetaTags();

    // Initialize screen size service to detect mobile devices.
    this.screenSizeService.initialize();

    // Initialize the authentication service.
    await this.authenticationService.initialize();

    // Initialize the current client service.
    await this.currentClientService.initialize();

    // Initialize the screen lock service.
    await this.screenLockService.initialize();

    // Check and update the application version. This is only done in
    // production. However, you can test this locally with `start:pwa`.
    await this.appUpdateService.initialize();

    // Set page meta on route data changes.
    this.routeDataChanges
      .pipe(filter(isNonEmptyValue), untilDestroyed(this))
      .subscribe(async ({ meta }) => {
        this.metaService.setPageAuthor(meta.author);
        this.metaService.setPageTitle(meta.title);
        this.metaService.setPageDescription(meta.description);
        this.metaService.setPageKeywords(meta.keywords);
        await firstValueFrom(this.currentClientService.selectedChanges);
      });

    // Act on certain router events.
    this.isLoadingChanges.pipe(untilDestroyed(this)).subscribe((isLoading) => {
      if (isLoading) {
        // Scroll to top of page.
        scrollTop();
      }
    });
  }

  public async ngAfterContentInit(): Promise<void> {
    const currentScreenSize = await getFirstNonEmptyValueFrom(
      this.screenSizeService.currentScreenSizeChanges,
    );
    // If the screen size is mobile on app load, collapse the menu.
    this.menuService.isCollapsed = currentScreenSize === ScreenSizeEnum.MOBILE;
  }

  protected navigateToLogin(): void {
    this.authenticationService.logout();
  }
}
