import {
  InsuranceVerificationApi,
  InsuranceVerificationUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<InsuranceVerificationApi>();

export class InsuranceVerification {
  public constructor(props: ClassProperties<InsuranceVerification>) {
    this.by = props.by;
    this.date = props.date;
    this.signature = props.signature;
    this.verificationNumber = props.verificationNumber;
  }

  /**
   * The io-ts codec for runtime type checking of the Insurance Verification
   * API model.
   */
  public static readonly Codec = io.type(
    {
      by: io.union([io.string, io.null]),
      date: io.string,
      signature: io.union([io.string, io.null]),
      verificationNumber: io.union([io.string, io.null]),
    },
    'InsuranceVerificationApi',
  );

  @api({ key: 'by' }) public readonly by: string | null;
  @api({ key: 'date' }) public readonly date: DateTime;
  @api({ key: 'signature' }) public readonly signature: Base64<'png'> | null;
  @api({ key: 'verificationNumber' }) public readonly verificationNumber:
    | string
    | null;

  /**
   * Deserializes a Insurance Verification object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Insurance Verification object.
   * @throws An error if the value is not a valid Insurance Verification
   * object.
   */
  public static async deserialize(
    value: NonNullable<InsuranceVerificationApi>,
  ): Promise<InsuranceVerification> {
    const decoded = decode(InsuranceVerification.Codec, value);
    return new InsuranceVerification({
      by: decoded.by,
      date: await DateTime.fromISO(decoded.date).toCurrentFacilityTime(),
      signature: decoded.signature
        ? (decoded.signature as Base64<'png'>)
        : null,
      verificationNumber: decoded.verificationNumber,
    });
  }

  /**
   * Deserializes a list of Insurance Verification objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Insurance Verification objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Insurance
   * Verification objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<InsuranceVerificationApi>>,
  ): Promise<readonly InsuranceVerification[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Insurance Verification objects.');
    }
    return Promise.all(values.map(InsuranceVerification.deserialize));
  }
}

export class InsuranceVerificationUpdate extends InsuranceVerification {
  /**
   * Serializes a Insurance Verification object to the API model.
   *
   * @returns The serialized Insurance Verification object.
   */
  public serialize(): InsuranceVerificationUpdateApi {
    return {
      ...this,
      date: this.date.toISO(),
    };
  }
}
