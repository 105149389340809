import { ClientMedicationApi, MedicationTypeApi } from 'api/models';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { Client, ClientMedication, MedicationType } from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the Client Medications API.
 */
@Injectable()
export class ClientMedicationsService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch and return all Client Medication data for the given client.
   *
   * @param clientId The client id to get the Client Medication list for.
   * @returns The Client Medication data on success, undefined on error.
   */
  public getAll(
    clientId: Client['id'],
  ): Observable<readonly ClientMedication[] | undefined> {
    return this.httpClient
      .get<
        readonly ClientMedicationApi[] | undefined
      >(`${config.api.url}/clients/${clientId}/medications`)
      .pipe(
        switchMap((response) =>
          response ? ClientMedication.deserializeList(response) : of([]),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Get all medication types.
   *
   * @returns The medication types on success, undefined on error.
   */
  public getTypes(): Observable<readonly MedicationType[] | undefined> {
    return this.httpClient
      .get<
        readonly MedicationTypeApi[] | undefined
      >(`${config.api.url}/medication-types`)
      .pipe(
        map((response) =>
          response ? MedicationType.deserializeList(response) : [],
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
