import {
  ClientTestingAnalysisApi,
  ClientTestingAnalysisUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode, isNonEmptyString } from 'src/app/utilities';

import { ClientTestingAnalysisType } from 'src/app/models/client/client-testing-analysis-type.model';
import { Reviewer } from 'src/app/models/reviewer.model';
import { Name } from 'src/app/models/user/name.model';
import { UserBase } from 'src/app/models/user/user.model';

const api = apiDecorator<ClientTestingAnalysisApi>();
const apiUpdate = apiDecorator<ClientTestingAnalysisUpdateApi>();

abstract class ClientTestingAnalysisBase {
  public constructor(props: ClassProperties<ClientTestingAnalysisBase>) {
    this.testDescriptions = props.testDescriptions;
    this.type = props.type;
  }

  @api({ key: 'testDescriptions' }) public readonly testDescriptions:
    | string
    | null;
  @api({ key: 'type' }) public readonly type: ClientTestingAnalysisType;
}

export class ClientTestingAnalysis extends ClientTestingAnalysisBase {
  public constructor(props: ClassProperties<ClientTestingAnalysis>) {
    super(props);

    this.dateAdministered = props.dateAdministered;
    this.dateCompleted = props.dateCompleted;
    this.id = props.id;
    this.isPositive = props.isPositive;
    this.isSigned = props.isSigned;
    this.reviewers = props.reviewers;
    this.user = props.user;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Testing Analysis
   * API model.
   */
  public static readonly Codec = io.type(
    {
      dateAdministered: io.string,
      dateCompleted: io.string,
      id: io.number,
      positive: io.boolean,
      reviewers: io.union([io.array(Reviewer.Codec), io.null, io.undefined]),
      signed: io.boolean,
      testDescriptions: io.union([io.string, io.null]),
      type: ClientTestingAnalysisType.Codec,
      user: UserBase.BaseCodec,
    },
    'ClientTestingAnalysisApi',
  );

  @api({ key: 'dateAdministered' }) public readonly dateAdministered: DateTime;
  @api({ key: 'dateCompleted' }) public readonly dateCompleted: DateTime;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'positive' }) public readonly isPositive: boolean;
  @api({ key: 'signed' }) public readonly isSigned: boolean;
  @api({ key: 'reviewers' }) public readonly reviewers:
    | readonly Reviewer[]
    | null;
  @api({ key: 'user' }) public readonly user: UserBase;

  /**
   * Deserializes a Client Testing Analysis object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Testing Analysis object.
   * @throws An error if the value is not a valid Client Testing Analysis
   * object.
   */
  public static async deserialize(
    value: NonNullable<ClientTestingAnalysisApi>,
  ): Promise<ClientTestingAnalysis> {
    const decoded = decode(ClientTestingAnalysis.Codec, value);
    return new ClientTestingAnalysis({
      ...decoded,
      dateAdministered: await DateTime.fromISO(
        decoded.dateAdministered,
      ).toCurrentFacilityTime(),
      dateCompleted: await DateTime.fromISO(
        decoded.dateCompleted,
      ).toCurrentFacilityTime(),
      isPositive: decoded.positive,
      isSigned: decoded.signed,
      reviewers: decoded.reviewers
        ? await Promise.all(
            decoded.reviewers.map((reviewer) =>
              Reviewer.deserialize({
                ...reviewer,
                name: Name.deserialize({
                  first: reviewer.name?.first ?? null,
                  last: reviewer.name?.last ?? null,
                }),
              }),
            ),
          )
        : null,
      type: ClientTestingAnalysisType.deserialize(decoded.type),
      user: UserBase.deserialize({
        ...decoded.user,
        name: Name.deserialize({
          first: decoded.user.name?.first ?? null,
          last: decoded.user.name?.last ?? null,
        }),
      }),
    });
  }

  /**
   * Deserializes a list of Client Testing Analysis objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Testing Analysis objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Testing
   * Analysis objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<ClientTestingAnalysisApi>>,
  ): Promise<readonly ClientTestingAnalysis[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Test Analysis objects.');
    }
    return Promise.all(values.map(ClientTestingAnalysis.deserialize));
  }
}

export class ClientTestingAnalysisUpdate extends ClientTestingAnalysisBase {
  public constructor(props: ClassProperties<ClientTestingAnalysisUpdate>) {
    super(props);

    this.reviewers = props.reviewers;
    this.signature = props.signature;
  }

  @apiUpdate({ key: 'reviewers' }) public readonly reviewers: ReadonlyArray<{
    by: { id: number };
  }> | null;
  @apiUpdate({ key: 'signature' })
  public readonly signature: Base64<'png'> | null;

  /** Serialize the data to the format that the API accepts. */
  public serialize(): ClientTestingAnalysisUpdateApi {
    return {
      reviewers: this.reviewers,
      signature:
        this.signature && isNonEmptyString(this.signature)
          ? this.signature
          : null,
      testDescriptions: this.testDescriptions,
      type: this.type,
    };
  }
}
