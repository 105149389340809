import { LabResultObservationDetailApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<LabResultObservationDetailApi>();

export class LabResultObservationDetail {
  public constructor(props: ClassProperties<LabResultObservationDetail>) {
    this.date = props.date;
    this.getInterpretationCodes = props.getInterpretationCodes;
    this.id = props.id;
    this.isAbnormal = props.isAbnormal;
    this.notes = props.notes;
    this.obrid = props.obrid;
    this.pdfReport = props.pdfReport;
    this.referencesRange = props.referencesRange;
    this.result = props.result;
    this.status = props.status;
    this.testDescription = props.testDescription;
    this.type = props.type;
    this.units = props.units;
  }

  /**
   * The io-ts codec for runtime type checking of the Lab Result Observation
   * Details Api model.
   */
  public static readonly Codec = io.type(
    {
      date: io.union([io.string, io.null]),
      getInterpretationCodes: io.union([io.string, io.null]),
      id: io.number,
      isAbnormal: io.boolean,
      notes: io.union([io.string, io.null]),
      obrid: io.number,
      pdfReport: io.union([io.string, io.null]),
      referencesRange: io.union([io.string, io.null]),
      result: io.string,
      status: io.string,
      testDescription: io.string,
      type: io.string,
      units: io.string,
    },
    'LabResultObservationDetailApi',
  );

  @api({ key: 'date' }) public readonly date: DateTime | null;
  @api({ key: 'getInterpretationCodes' })
  public readonly getInterpretationCodes: string | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'isAbnormal' }) public readonly isAbnormal: boolean;
  @api({ key: 'notes' }) public readonly notes: string | null;
  @api({ key: 'obrid' }) public readonly obrid: number;
  @api({ key: 'pdfReport' }) public readonly pdfReport: string | null;
  @api({ key: 'referencesRange' }) public readonly referencesRange:
    | string
    | null;
  @api({ key: 'result' }) public readonly result: string;
  @api({ key: 'status' }) public readonly status: string;
  @api({ key: 'testDescription' }) public readonly testDescription: string;
  @api({ key: 'type' }) public readonly type: string;
  @api({ key: 'units' }) public readonly units: string;

  /**
   * Deserializes a Lab Result Observation Details object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Lab Result Observation Details object.
   * @throws An error if the value is not a valid Lab Result Observation
   * Details object.
   */
  public static async deserialize(
    value: NonNullable<LabResultObservationDetailApi>,
  ): Promise<LabResultObservationDetail> {
    const decoded = decode(LabResultObservationDetail.Codec, value);
    return new LabResultObservationDetail({
      ...decoded,
      date: decoded.date
        ? await DateTime.fromISO(decoded.date).toCurrentFacilityTime()
        : null,
    });
  }

  /**
   * Deserializes a list of Lab Result Observation Details objects from the
   * API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Lab Result Observation Details objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Lab Result
   * Observation Details objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<LabResultObservationDetailApi>>,
  ): Promise<readonly LabResultObservationDetail[]> {
    if (!Array.isArray(values)) {
      throw new Error(
        'Expected array of Lab Result Observation Details objects.',
      );
    }
    return Promise.all(values.map(LabResultObservationDetail.deserialize));
  }
}
