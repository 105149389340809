import {
  ClientTreatmentLevelOfCareApi,
  ClientTreatmentLevelOfCareUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<ClientTreatmentLevelOfCareApi>();
const apiUpdate = apiDecorator<ClientTreatmentLevelOfCareUpdateApi>();

export abstract class ClientTreatmentLevelOfCareBase {
  public constructor(props: ClassProperties<ClientTreatmentLevelOfCareBase>) {
    this.endDate = props.endDate;
    this.startDate = props.startDate;
  }

  @api({ key: 'endDate' }) public readonly endDate: DateTime | null;
  @api({ key: 'startDate' }) public readonly startDate: DateTime | null;
}

export class ClientTreatmentLevelOfCare extends ClientTreatmentLevelOfCareBase {
  public constructor(props: ClassProperties<ClientTreatmentLevelOfCare>) {
    super(props);

    this.id = props.id;
    this.leadId = props.leadId;
    this.name = props.name;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Treatment Level of
   * Care API model.
   */
  public static readonly Codec = io.type(
    {
      endDate: io.union([io.string, io.null]),
      id: io.number,
      leadId: io.number,
      name: io.union([io.string, io.null]),
      startDate: io.union([io.string, io.null]),
    },
    'ClientTreatmentLevelOfCareApi',
  );

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'leadId' }) public readonly leadId: number;
  @api({ key: 'name' }) public readonly name: string | null;

  /**
   * Deserializes a Client Treatment Level of Care object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Treatment Level of Care object.
   * @throws An error if the value is not a valid Client Treatment Level of Care object.
   */
  public static async deserialize(
    value: NonNullable<ClientTreatmentLevelOfCareApi>,
  ): Promise<ClientTreatmentLevelOfCare> {
    const decoded = decode(ClientTreatmentLevelOfCare.Codec, value);
    return new ClientTreatmentLevelOfCare({
      ...decoded,
      endDate: decoded.endDate
        ? await DateTime.fromISO(decoded.endDate).toCurrentFacilityTime()
        : null,
      startDate: decoded.startDate
        ? await DateTime.fromISO(decoded.startDate).toCurrentFacilityTime()
        : null,
    });
  }

  /**
   * Deserializes a list of Client Treatment Level of Care objects from the
   * API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Treatment Level of Care objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Treatment
   * Level of Care objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<ClientTreatmentLevelOfCareApi>>,
  ): Promise<readonly ClientTreatmentLevelOfCare[]> {
    if (!Array.isArray(values)) {
      throw new Error(
        'Expected array of Client Treatment Level of Care objects.',
      );
    }
    return Promise.all(values.map(ClientTreatmentLevelOfCare.deserialize));
  }
}

export class ClientTreatmentLevelOfCareUpdate extends ClientTreatmentLevelOfCareBase {
  public constructor(props: ClassProperties<ClientTreatmentLevelOfCareUpdate>) {
    super(props);
    this.levelOfCareId = props.levelOfCareId;
  }

  @apiUpdate({ key: 'id' }) public readonly levelOfCareId: number;

  /**
   * Serialize the data to a format that the API accepts.
   *
   * @returns The model used for updating Client Treatment Level of Care data
   * in the API.
   */
  public serialize(): ClientTreatmentLevelOfCareUpdateApi {
    return {
      id: this.levelOfCareId,
      endDate: this.endDate?.toISODate() ?? null,
      startDate: this.startDate?.toISODate() ?? null,
    };
  }
}
