import { ClientCIWACreatorApi, ClientCIWACreatorUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { NameId } from 'src/app/models/core/name-id.model';
import { UserBase } from 'src/app/models/user/user.model';

const api = apiDecorator<ClientCIWACreatorApi>();

export class ClientCIWACreator extends UserBase {
  public constructor(props: ClassProperties<ClientCIWACreator>) {
    super(props);

    this.doctor = props.doctor;
    this.guid = props.guid;
    this.role = props.role;
  }

  /**
   * The io-ts codec for runtime type checking of the Client CIWA Creator
   * API model.
   */
  public static readonly Codec = io.intersection([
    UserBase.BaseCodec,
    io.type(
      {
        doctor: io.boolean,
        guid: io.string,
        role: io.union([NameId.Codec, io.null]),
      },
      'ClientCIWACreatorApi',
    ),
  ]);

  @api({ key: 'doctor' }) public readonly doctor: boolean;
  @api({ key: 'guid' }) public readonly guid: string;
  @api({ key: 'role' }) public readonly role: NameId | null;

  /**
   * Deserializes a Client CIWA Creator object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client CIWA Creator object.
   * @throws An error if the value is not a valid Client CIWA Creator object.
   */
  public static override deserialize(
    value: NonNullable<ClientCIWACreatorApi>,
  ): ClientCIWACreator {
    const decoded = decode(ClientCIWACreator.Codec, value);
    const userBase = UserBase.deserialize(decoded);
    return new ClientCIWACreator({
      ...decoded,
      ...userBase,
      role: decoded.role ? NameId.deserialize(decoded.role) : null,
    });
  }

  /**
   * Deserializes a list of Client CIWA Creator objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client CIWA Creator objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client CIWA Creator
   * objects.
   */
  public static override deserializeList(
    values: ReadonlyArray<NonNullable<ClientCIWACreatorApi>>,
  ): readonly ClientCIWACreator[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client CIWA Creator objects.');
    }
    return values.map(ClientCIWACreator.deserialize);
  }
}

export class ClientCIWACreatorUpdate {
  public constructor(props: ClassProperties<ClientCIWACreatorUpdate>) {
    this.guid = props.guid;
  }

  @api({ key: 'guid' }) public readonly guid: ClientCIWACreator['guid'];

  /**
   * Serializes a Client CIWA Creator object to an API model.
   *
   * @returns The serialized Client CIWA Creator object.
   */
  public serialize(): ClientCIWACreatorUpdateApi {
    return this;
  }
}
