import {
  InsuranceAuthorizationApi,
  InsuranceAuthorizationUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { AuthorizationAgainstEnum } from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

import { InsuranceAuthorizationDiagnosis } from 'src/app/models/insurance/insurance-authorization-diagnosis.model';
import {
  InsuranceAuthorizationNote,
  InsuranceAuthorizationNoteUpdate,
} from 'src/app/models/insurance/insurance-authorization-note.model';
import { LevelOfCare } from 'src/app/models/level-of-care.model';

const api = apiDecorator<InsuranceAuthorizationApi>();
const apiUpdate = apiDecorator<InsuranceAuthorizationUpdateApi>();

abstract class InsuranceAuthorizationBase {
  public constructor(props: ClassProperties<InsuranceAuthorizationBase>) {
    this.authorizationNumber = props.authorizationNumber;
    this.authorizedBy = props.authorizedBy;
    this.completed = props.completed;
    this.dateEnd = props.dateEnd;
    this.dateStart = props.dateStart;
    this.days = props.days;
    this.daysPerWeek = props.daysPerWeek;
    this.hours = props.hours;
    this.hoursPerDay = props.hoursPerDay;
    this.nextURDueDate = props.nextURDueDate;
    this.status = props.status;
  }

  @api({ key: 'authorizationNumber' }) public readonly authorizationNumber:
    | string
    | null;
  @api({ key: 'by' }) public readonly authorizedBy: string | null;
  @api({ key: 'completed' }) public readonly completed: boolean;
  @api({ key: 'dateEnd' }) public readonly dateEnd: DateTime | null;
  @api({ key: 'dateStart' }) public readonly dateStart: DateTime | null;
  @api({ key: 'days' }) public readonly days: number;
  @api({ key: 'daysPerWeek' }) public readonly daysPerWeek: number;
  @api({ key: 'hours' }) public readonly hours: number;
  @api({ key: 'hoursPerDay' }) public readonly hoursPerDay: number;
  @api({ key: 'nextURDue' }) public readonly nextURDueDate: DateTime | null;
  @api({ key: 'status' }) public readonly status: string | null;
}

type InsuranceAuthorizationArgs = Omit<InsuranceAuthorization, 'against'>;

export class InsuranceAuthorization extends InsuranceAuthorizationBase {
  public constructor(props: ClassProperties<InsuranceAuthorizationArgs>) {
    super(props);

    this.diagnosisList = props.diagnosisList;
    this.id = props.id;
    this.levelOfCare = props.levelOfCare;
    this.notes = props.notes;

    // Computed values
    this.against =
      (this.diagnosisList?.length ?? 0) > 0
        ? AuthorizationAgainstEnum.CLINICAL_DIAGNOSIS
        : AuthorizationAgainstEnum.MENTAL_HEALTH;
  }

  /**
   * The io-ts codec for runtime type checking of the Insurance Authorization
   * API model.
   */
  public static readonly Codec = io.type(
    {
      authorizationNumber: io.union([io.string, io.null]),
      by: io.union([io.string, io.null]),
      completed: io.boolean,
      dateEnd: io.union([io.string, io.null]),
      dateStart: io.union([io.string, io.null]),
      days: io.number,
      daysPerWeek: io.number,
      diagnosis: io.union([
        io.array(InsuranceAuthorizationDiagnosis.Codec),
        io.null,
      ]),
      hours: io.number,
      hoursPerDay: io.number,
      id: io.number,
      levelOfCare: LevelOfCare.Codec,
      nextURDue: io.union([io.string, io.null]),
      notes: io.union([io.array(InsuranceAuthorizationNote.Codec), io.null]),
      status: io.union([io.string, io.null]),
    },
    'InsuranceAuthorizationApi',
  );

  @api({ key: 'diagnosis' }) public readonly diagnosisList:
    | readonly InsuranceAuthorizationDiagnosis[]
    | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'levelOfCare' }) public readonly levelOfCare: LevelOfCare;
  @api({ key: 'notes' }) public readonly notes:
    | readonly InsuranceAuthorizationNote[]
    | null;

  // Computed values
  public readonly against: AuthorizationAgainstEnum;

  /**
   * Deserializes an Insurance Authorization object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Insurance Authorization object.
   * @throws An error if the value is not a valid Insurance Authorization
   * object.
   */
  public static async deserialize(
    value: NonNullable<InsuranceAuthorizationApi>,
  ): Promise<InsuranceAuthorization> {
    const decoded = decode(InsuranceAuthorization.Codec, value);
    return new InsuranceAuthorization({
      ...decoded,
      authorizedBy: decoded.by,
      dateEnd: decoded.dateEnd
        ? await DateTime.fromISO(decoded.dateEnd).toCurrentFacilityTime()
        : null,
      dateStart: decoded.dateStart
        ? await DateTime.fromISO(decoded.dateStart).toCurrentFacilityTime()
        : null,
      diagnosisList: decoded.diagnosis
        ? InsuranceAuthorizationDiagnosis.deserializeList(decoded.diagnosis)
        : null,
      levelOfCare: LevelOfCare.deserialize(decoded.levelOfCare),
      nextURDueDate: decoded.nextURDue
        ? await DateTime.fromISO(decoded.nextURDue).toCurrentFacilityTime()
        : null,
      notes: decoded.notes
        ? await InsuranceAuthorizationNote.deserializeList(decoded.notes)
        : null,
    });
  }

  /**
   * Deserializes a list of Insurance Authorization objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Insurance Authorization objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Insurance
   * Authorization objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<InsuranceAuthorizationApi>>,
  ): Promise<readonly InsuranceAuthorization[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Insurance Authorization objects.');
    }
    return Promise.all(values.map(InsuranceAuthorization.deserialize));
  }
}

export class InsuranceAuthorizationUpdate extends InsuranceAuthorizationBase {
  public constructor(props: ClassProperties<InsuranceAuthorizationUpdate>) {
    super(props);

    this.diagnosis = props.diagnosis;
    this.levelOfCare = props.levelOfCare;
    this.notes = props.notes;
  }

  @apiUpdate({ key: 'diagnosis' }) public readonly diagnosis: ReadonlyArray<{
    id: number;
    name: string | null;
  }> | null;
  @apiUpdate({ key: 'levelOfCare' }) public readonly levelOfCare: {
    id: number;
  };
  @apiUpdate({ key: 'notes' })
  public readonly notes: readonly InsuranceAuthorizationNoteUpdate[];

  public serialize(): InsuranceAuthorizationUpdateApi {
    return {
      authorizationNumber: this.authorizationNumber,
      by: this.authorizedBy,
      completed: this.completed,
      dateEnd: this.dateEnd ? this.dateEnd.toISODate() : null,
      dateStart: this.dateStart ? this.dateStart.toISODate() : null,
      days: this.days,
      daysPerWeek: this.daysPerWeek,
      diagnosis: this.diagnosis
        ? this.diagnosis.map((diagnosis) => ({
            id: diagnosis.id,
            name: diagnosis.name,
          }))
        : null,
      hasDiagnosis: (this.diagnosis?.length ?? 0) > 0,
      hours: this.hours,
      hoursPerDay: this.hoursPerDay,
      levelOfCare: { id: this.levelOfCare.id },
      nextURDue: this.nextURDueDate ? this.nextURDueDate.toISODate() : null,
      notes: this.notes
        ? this.notes.map((note) =>
            new InsuranceAuthorizationNoteUpdate(note).serialize(),
          )
        : null,
      status: this.status,
    };
  }
}
