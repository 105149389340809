import { ClientInsurancePlanApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import {
  InsurancePlanPolicy,
  InsurancePlanPolicyUpdate,
} from 'src/app/models/insurance/insurance-plan-policy.model';

const api = apiDecorator<ClientInsurancePlanApi>();

export class ClientInsurancePlan {
  public constructor(props: ClassProperties<ClientInsurancePlan>) {
    this.active = props.active;
    this.coPay = props.coPay;
    this.description = props.description;
    this.group = props.group;
    this.id = props.id;
    this.name = props.name;
    this.policy = props.policy;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Insurance Plan API
   * model.
   */
  public static readonly Codec = io.type(
    {
      active: io.boolean,
      coPay: io.union([io.string, io.null]),
      description: io.union([io.string, io.null]),
      group: io.union([io.string, io.null]),
      id: io.number,
      name: io.union([io.string, io.null]),
      policy: InsurancePlanPolicy.Codec,
    },
    'ClientInsurancePlanApi',
  );

  @api({ key: 'active' }) public readonly active: boolean;
  @api({ key: 'coPay' }) public readonly coPay: string | null;
  @api({ key: 'description' }) public readonly description: string | null;
  @api({ key: 'group' }) public readonly group: string | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'name' }) public readonly name: string | null;
  @api({ key: 'policy' }) public readonly policy: InsurancePlanPolicy;

  /**
   * Deserializes a Client Insurance Plan object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Insurance Plan object.
   * @throws An error if the value is not a valid Client Insurance Plan object.
   */
  public static async deserialize(
    value: NonNullable<ClientInsurancePlanApi>,
  ): Promise<ClientInsurancePlan> {
    const decoded = decode(ClientInsurancePlan.Codec, value);
    return new ClientInsurancePlan({
      active: decoded.active,
      coPay: decoded.coPay,
      description: decoded.description,
      group: decoded.group,
      id: decoded.id,
      name: decoded.name,
      policy: await InsurancePlanPolicy.deserialize(decoded.policy),
    });
  }

  /**
   * Deserializes a list of Client Insurance Plan objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Insurance Plan objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Insurance Plan
   * objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<ClientInsurancePlanApi>>,
  ): Promise<readonly ClientInsurancePlan[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Insurance Plan objects.');
    }
    return Promise.all(values.map(ClientInsurancePlan.deserialize));
  }
}

export class ClientInsurancePlanUpdate extends ClientInsurancePlan {
  public constructor(props: ClassProperties<ClientInsurancePlanUpdate>) {
    super(props);

    this.policy = props.policy;
  }

  @api({ key: 'policy' })
  public override readonly policy: InsurancePlanPolicyUpdate;

  public serialize(): ClientInsurancePlanApi {
    return {
      ...this,
      policy: this.policy ? this.policy.serialize() : null,
    };
  }
}
