import { ClientContactApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { ClientContactPhone } from 'src/app/models/client/client-contact-phone.model';
import { Address } from 'src/app/models/user/address.model';

const api = apiDecorator<ClientContactApi>();

/**
 * The Client Contact model.
 */
export class ClientContact {
  public constructor(props: ClassProperties<ClientContact>) {
    this.address = props.address;
    this.email = props.email;
    this.id = props.id;
    this.isEmergencyContact = props.isEmergencyContact;
    this.isPrimary = props.isPrimary;
    this.isRevoked = props.isRevoked;
    this.isRoi = props.isRoi;
    this.name = props.name;
    this.phone = props.phone;
    this.relationshipName = props.relationshipName;
    this.responsibility = props.responsibility;
    this.roiEndDate = props.roiEndDate;
    this.roiStartDate = props.roiStartDate;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Contact API model.
   */
  public static readonly Codec = io.type(
    {
      address: io.union([Address.Codec, io.null]),
      email: io.union([io.string, io.null]),
      id: io.number,
      isEmergencyContact: io.boolean,
      isPrimary: io.boolean,
      isRevoked: io.boolean,
      isRoi: io.boolean,
      name: io.union([io.string, io.null]),
      phone: io.union([ClientContactPhone.Codec, io.null]),
      relationship: io.union([io.string, io.null]),
      responsibility: io.union([io.string, io.null]),
      roiEndDate: io.union([io.string, io.null]),
      roiStartDate: io.union([io.string, io.null]),
    },
    'ClientContactApi',
  );

  @api({ key: 'address' }) public readonly address: Address | null;
  @api({ key: 'email' }) public readonly email: string | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'isEmergencyContact' })
  public readonly isEmergencyContact: boolean;
  @api({ key: 'isPrimary' }) public readonly isPrimary: boolean;
  @api({ key: 'isRevoked' }) public readonly isRevoked: boolean;
  @api({ key: 'isRoi' }) public readonly isRoi: boolean;
  @api({ key: 'name' }) public readonly name: string | null;
  @api({ key: 'phone' }) public readonly phone: ClientContactPhone | null;
  @api({ key: 'relationship' }) public readonly relationshipName: string | null;
  @api({ key: 'responsibility' }) public readonly responsibility: string | null;
  @api({ key: 'roiEndDate' }) public readonly roiEndDate: DateTime | null;
  @api({ key: 'roiStartDate' }) public readonly roiStartDate: DateTime | null;

  /**
   * Deserializes a Client Contact object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Contact object.
   * @throws An error if the value is not a valid Client Contact object.
   */
  public static async deserialize(
    value: NonNullable<ClientContactApi>,
  ): Promise<ClientContact> {
    const decoded = decode(ClientContact.Codec, value);
    return new ClientContact({
      ...decoded,
      address: decoded.address ? Address.deserialize(decoded.address) : null,
      phone: decoded.phone
        ? ClientContactPhone.deserialize(decoded.phone)
        : null,
      relationshipName: decoded.relationship,
      roiEndDate: decoded.roiEndDate
        ? await DateTime.fromISO(decoded.roiEndDate).toCurrentFacilityTime()
        : null,
      roiStartDate: decoded.roiStartDate
        ? await DateTime.fromISO(decoded.roiStartDate).toCurrentFacilityTime()
        : null,
    });
  }

  /**
   * Deserializes a list of Client Contact objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Contact objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Contact
   * objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<ClientContactApi>>,
  ): Promise<readonly ClientContact[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Contact objects.');
    }
    return Promise.all(values.map(ClientContact.deserialize));
  }
}
