import { ClientTreatmentApi } from 'api/models';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import {
  Client,
  ClientTreatment,
  ClientTreatmentLevelOfCareUpdate,
} from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the Client Treatment API.
 */
@Injectable()
export class ClientTreatmentService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch and return all client treatment history data for the given client.
   *
   * @param clientId The client id to get the treatment history for.
   * @returns The client treatment history on success, undefined on error.
   */
  public getHistoryList(
    clientId: Client['id'],
  ): Observable<readonly ClientTreatment[] | undefined> {
    return this.httpClient
      .get<
        readonly ClientTreatmentApi[] | undefined
      >(`${config.api.url}/clients/${clientId}/treatment-history`)
      .pipe(
        switchMap((response) =>
          response ? ClientTreatment.deserializeList(response) : of([]),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Delete the treatment history for the given client ID and treatment history
   * id.
   *
   * @param clientId The client id to delete the treatment history for.
   * @param clientTreatmentId The ID of the treatment to delete.
   * @returns True on success, false on error.
   */
  public deleteClientTreatmentHistory(
    clientId: Client['id'],
    clientTreatmentId: ClientTreatment['id'],
  ): Observable<boolean> {
    return this.httpClient
      .delete<
        boolean | undefined
      >(`${config.api.url}/clients/${clientId}/treatment-history/${clientTreatmentId}`)
      .pipe(
        map((response) => response ?? false),
        catchError((error: unknown) => {
          console.error(error);
          return of(false);
        }),
      );
  }

  /**
   * Update the treatment history for the given client ID and treatment history
   * lead id.
   *
   * @param clientId The client ID to set the treatment history for.
   * @param clientTreatmentId The ID of the treatment to update.
   * @param clientTreatmentLevelOfCareUpdate The treatment history to update.
   * @returns An observable of the updated treatment history, or undefined if
   * there was an error.
   */
  public updateClientTreatmentLevelOfCare(
    clientId: Client['id'],
    clientTreatmentId: ClientTreatment['id'],
    clientTreatmentLevelOfCareUpdate: ClientTreatmentLevelOfCareUpdate,
  ): Observable<ClientTreatment | undefined> {
    return this.httpClient
      .patch<ClientTreatmentApi | undefined>(
        `${config.api.url}/clients/${clientId}/treatment-history/${clientTreatmentId}`,
        {
          ...clientTreatmentLevelOfCareUpdate,
          levelOfCare: clientTreatmentLevelOfCareUpdate.serialize(),
        },
      )
      .pipe(
        switchMap((response) =>
          response ? ClientTreatment.deserialize(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
