import { ClientCOWSCreatorApi, ClientCOWSCreatorUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { NameId } from 'src/app/models/core/name-id.model';
import { UserBase } from 'src/app/models/user/user.model';

const api = apiDecorator<ClientCOWSCreatorApi>();

export class ClientCOWSCreator extends UserBase {
  public constructor(props: ClassProperties<ClientCOWSCreator>) {
    super(props);

    this.doctor = props.doctor;
    this.guid = props.guid;
    this.role = props.role;
  }

  /**
   * The io-ts codec for runtime type checking of the Client COWS Creator API
   * model.
   */
  public static readonly Codec = io.intersection([
    UserBase.BaseCodec,
    io.type(
      {
        doctor: io.boolean,
        guid: io.string,
        role: io.union([NameId.Codec, io.null]),
      },
      'ClientCOWSCreatorApi',
    ),
  ]);

  @api({ key: 'doctor' }) public readonly doctor: boolean;
  @api({ key: 'guid' }) public readonly guid: string;
  @api({ key: 'role' }) public readonly role: NameId | null;

  /**
   * Deserializes a Client COWS Creator object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client COWS Creator object.
   * @throws An error if the value is not a valid Client COWS Creator object.
   */
  public static override deserialize(
    value: NonNullable<ClientCOWSCreatorApi>,
  ): ClientCOWSCreator {
    const decoded = decode(ClientCOWSCreator.Codec, value);
    const userBase = UserBase.deserialize(decoded);
    return new ClientCOWSCreator({
      ...decoded,
      ...userBase,
      role: decoded.role ? NameId.deserialize(decoded.role) : null,
    });
  }

  /**
   * Deserializes a list of Client COWS Creator objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client COWS Creator objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client COWS Creator
   * objects.
   */
  public static override deserializeList(
    values: ReadonlyArray<NonNullable<ClientCOWSCreatorApi>>,
  ): readonly ClientCOWSCreator[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client COWS Creator objects.');
    }
    return values.map(ClientCOWSCreator.deserialize);
  }
}

export class ClientCOWSCreatorUpdate {
  public constructor(props: ClassProperties<ClientCOWSCreatorUpdate>) {
    this.guid = props.guid;
  }

  @api({ key: 'guid' }) public readonly guid: ClientCOWSCreator['guid'];

  /**
   * Serializes a Client COWS Creator object to an API model.
   *
   * @returns The serialized Client COWS Creator object.
   */
  public serialize(): ClientCOWSCreatorUpdateApi {
    return this;
  }
}
