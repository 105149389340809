import { FamilyMessageDetailsApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { ClientName } from 'src/app/models/client/client-name.model';
import {
  FamilyMessageBase,
  FamilyMessageBaseArgs,
} from 'src/app/models/family-message/family-message.model';
import { MessageAttachment } from 'src/app/models/message/message-attachment.model';
import { Name } from 'src/app/models/user/name.model';

const api = apiDecorator<FamilyMessageDetailsApi>();

type FamilyMessageDetailsArgs = Omit<
  FamilyMessageDetails & FamilyMessageBaseArgs,
  // Omit computed properties set on construct from outside data.
  'toClientFullName' | 'fromClientFullName' | 'fromUserFullName'
>;

export class FamilyMessageDetails extends FamilyMessageBase {
  public constructor(props: ClassProperties<FamilyMessageDetailsArgs>) {
    super(props);

    this.attachments = props.attachments;
    this.body = props.body;
  }

  /**
   * The io-ts codec for runtime type checking of the Family Message Details
   * model.
   */
  public static readonly Codec = io.type(
    {
      attachedFiles: io.union([io.null, io.array(MessageAttachment.Codec)]),
      attachments: io.number,
      body: io.string,
      dateTime: io.string,
      draft: io.boolean,
      fromClient: io.union([
        io.type({
          id: io.number,
          name: ClientName.Codec,
        }),
        io.null,
      ]),
      fromFacility: io.union([
        io.type({
          id: io.number,
          name: io.string,
        }),
        io.null,
      ]),
      fromUser: io.union([
        io.type({
          id: io.number,
          name: Name.Codec,
        }),
        io.null,
      ]),
      id: io.number,
      preview: io.union([io.string, io.null]),
      read: io.boolean,
      readBy: io.union([
        io.type({
          id: io.number,
          name: Name.Codec,
        }),
        io.null,
      ]),
      subject: io.string,
      toClient: io.union([
        io.type({
          id: io.number,
          name: ClientName.Codec,
        }),
        io.null,
      ]),
      toFacility: io.union([
        io.type({
          id: io.number,
          name: io.string,
        }),
        io.null,
      ]),
    },
    'FamilyMessageDetailsApi',
  );

  @api({ key: 'attachedFiles' }) public readonly attachments:
    | readonly MessageAttachment[]
    | null;
  @api({ key: 'body' }) public readonly body: string | null;

  /**
   * Deserializes a Family Message Details object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Family Message Details  object.
   * @throws An error if the value is not a valid Family Message Details
   * object.
   */
  public static async deserialize(
    value: NonNullable<FamilyMessageDetailsApi>,
  ): Promise<FamilyMessageDetails> {
    const decoded = decode(FamilyMessageDetails.Codec, value);
    return new FamilyMessageDetails({
      attachments: decoded.attachedFiles
        ? MessageAttachment.deserializeList(decoded.attachedFiles)
        : null,
      attachmentsCount: decoded.attachments,
      body: decoded.body || null,
      dateTime: await DateTime.fromISO(
        decoded.dateTime,
      ).toCurrentFacilityTime(),
      fromClient: decoded.fromClient
        ? {
            id: decoded.fromClient.id,
            name: ClientName.deserialize(decoded.fromClient.name),
          }
        : null,
      fromFacility: decoded.fromFacility
        ? {
            id: decoded.fromFacility.id,
            name: decoded.fromFacility.name,
          }
        : null,
      fromUser: decoded.fromUser
        ? {
            id: decoded.fromUser.id,
            name: Name.deserialize(decoded.fromUser.name),
          }
        : null,
      id: decoded.id,
      isDraft: decoded.draft,
      isRead: decoded.read,
      preview: decoded.preview || null,
      readBy: decoded.readBy
        ? {
            id: decoded.readBy.id,
            name: Name.deserialize(decoded.readBy.name),
          }
        : null,
      subject: decoded.subject,
      toClient: decoded.toClient
        ? {
            id: decoded.toClient.id,
            name: ClientName.deserialize(decoded.toClient.name),
          }
        : null,
      toFacility: decoded.toFacility
        ? {
            id: decoded.toFacility.id,
            name: decoded.toFacility.name,
          }
        : null,
    });
  }

  /**
   * Deserializes a list of Family Message Details objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Family Message objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Family Message
   * objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<FamilyMessageDetailsApi>>,
  ): Promise<readonly FamilyMessageDetails[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Family Message Details objects.');
    }
    return Promise.all(values.map(FamilyMessageDetails.deserialize));
  }
}
