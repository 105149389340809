import { ClientUrgentAlertAcknowledgementApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { ClientUrgentAlertUser } from 'src/app/models/client/client-urgent-alert-user.model';

const api = apiDecorator<ClientUrgentAlertAcknowledgementApi>();

export class ClientUrgentAlertAcknowledgement {
  public constructor(props: ClassProperties<ClientUrgentAlertAcknowledgement>) {
    this.by = props.by;
    this.date = props.date;
    this.id = props.id;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Urgent Alert
   * Acknowledgement API model.
   */
  public static readonly Codec = io.type(
    {
      by: ClientUrgentAlertUser.Codec,
      date: io.string,
      id: io.number,
    },
    'ClientUrgentAlertAcknowledgementApi',
  );

  @api({ key: 'by' }) public readonly by: ClientUrgentAlertUser;
  @api({ key: 'date' }) public readonly date: DateTime;
  @api({ key: 'id' }) public readonly id: number;

  /**
   * Deserializes a Client Urgent Alert Acknowledgement object from the API
   * model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Urgent Alert Acknowledgement object.
   * @throws An error if the value is not a valid Client Urgent Alert A
   * cknowledgement object.
   */
  public static async deserialize(
    value: NonNullable<ClientUrgentAlertAcknowledgementApi>,
  ): Promise<ClientUrgentAlertAcknowledgement> {
    const decoded = decode(ClientUrgentAlertAcknowledgement.Codec, value);
    return new ClientUrgentAlertAcknowledgement({
      by: await ClientUrgentAlertUser.deserialize(decoded.by),
      date: await DateTime.fromISO(decoded.date).toCurrentFacilityTime(),
      id: decoded.id,
    });
  }

  /**
   * Deserializes a list of Client Urgent Alert Acknowledgement objects from
   * the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Urgent Alert Acknowledgement objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Urgent Alert
   * Acknowledgement objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<ClientUrgentAlertAcknowledgementApi>>,
  ): Promise<readonly ClientUrgentAlertAcknowledgement[]> {
    if (!Array.isArray(values)) {
      throw new Error(
        'Expected array of Client Urgent Alert Acknowledgement objects.',
      );
    }
    return Promise.all(
      values.map(ClientUrgentAlertAcknowledgement.deserialize),
    );
  }
}
