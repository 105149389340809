import { ClientBedApi, ClientBedUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import {
  ClientRoom,
  ClientRoomUpdate,
} from 'src/app/models/client/client-room.model';

const api = apiDecorator<ClientBedApi>();

class ClientBedBase {
  public constructor(props: ClassProperties<ClientBedBase>) {
    this.endDate = props.endDate;
    this.isActiveBed = props.isActiveBed;
    this.name = props.name;
    this.room = props.room;
    this.startDate = props.startDate;
  }

  @api({ key: 'endDate' }) public readonly endDate: DateTime;
  @api({ key: 'isActiveBed' }) public readonly isActiveBed: boolean;
  @api({ key: 'name' }) public readonly name: string;
  @api({ key: 'room' }) public readonly room: ClientRoom;
  @api({ key: 'startDate' }) public readonly startDate: DateTime;
}

export class ClientBed extends ClientBedBase {
  public constructor(props: ClassProperties<ClientBed>) {
    super(props);

    this.id = props.id;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Bed API model.
   */
  public static readonly Codec = io.type(
    {
      endDate: io.string,
      id: io.number,
      isActiveBed: io.boolean,
      name: io.string,
      room: ClientRoom.Codec,
      startDate: io.string,
    },
    'ClientBedApi',
  );

  @api({ key: 'id' }) public readonly id: number;

  /**
   * Deserializes a Client Bed object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Bed object.
   * @throws An error if the value is not a valid Client Bed object.
   */
  public static async deserialize(
    value: NonNullable<ClientBedApi>,
  ): Promise<ClientBed> {
    const decoded = decode(ClientBed.Codec, value);
    return new ClientBed({
      endDate: await DateTime.fromISO(decoded.endDate).toCurrentFacilityTime(),
      id: decoded.id,
      isActiveBed: decoded.isActiveBed,
      name: decoded.name,
      room: ClientRoom.deserialize(decoded.room),
      startDate: await DateTime.fromISO(
        decoded.startDate,
      ).toCurrentFacilityTime(),
    });
  }

  /**
   * Deserializes a list of Client Bed objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Bed objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Bed objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<ClientBedApi>>,
  ): Promise<readonly ClientBed[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Bed objects.');
    }
    return Promise.all(values.map(ClientBed.deserialize));
  }
}

export class ClientBedUpdate extends ClientBedBase {
  public constructor(props: ClassProperties<ClientBedUpdate>) {
    super(props);
  }

  /** Serialize the data to a format that the API accepts. */
  public serialize(): ClientBedUpdateApi {
    return {
      endDate: this.endDate.toISO()!,
      isActiveBed: this.isActiveBed,
      name: this.name,
      room: new ClientRoomUpdate(this.room).serialize(),
      startDate: this.startDate.toISO()!,
    };
  }
}
