import { ClientApi, ClientPagedListApi, RelationshipApi } from 'api/models';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import {
  ClientOrderByEnum,
  ClientStatusEnum,
  SortOrderEnum,
} from 'src/app/enumerators';
import { Client, ClientUpdate, NameId } from 'src/app/models';
import {
  PagedListCodec,
  decode,
  isNonEmptyString,
  parseHttpParams,
} from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AlertService } from '../root/alert.service';
import { FacilityService } from '../root/facility.service';

/**
 * A service for interacting with the client API.
 */
@Injectable()
export class ClientsService {
  public constructor(
    private readonly alertService: AlertService,
    private readonly facilityService: FacilityService,
    private readonly httpClient: HttpClient,
  ) {}

  /**
   * Fetch and return data for a single client by ID.
   *
   * @param id The client ID to fetch.
   * @returns Full model on success, null on not found, undefined on error.
   */
  public get(clientId: Client['id']): Observable<Client | null | undefined> {
    return this.facilityService.currentFacilityChanges.pipe(
      switchMap((currentFacility) =>
        this.httpClient
          .get<ClientApi | undefined>(`${config.api.url}/clients/${clientId}`, {
            params: { facilityId: currentFacility.id },
          })
          .pipe(
            switchMap((response) =>
              response ? Client.deserialize(response) : of(null),
            ),
            catchError((error: unknown) => {
              console.error(error);
              return of(undefined);
            }),
          ),
      ),
    );
  }

  /**
   * Get the list of available client relationships.
   *
   * @returns The list of all client relationships on success, undefined on
   * error.
   */
  public getClientRelationshipsList(
    requestParameters?: readonly ClientsRequestParam[],
  ): Observable<readonly NameId[] | undefined> {
    return this.httpClient
      .get<readonly RelationshipApi[]>(`${config.api.url}/relationships`, {
        params: parseHttpParams(requestParameters),
      })
      .pipe(
        map((response) => (response ? NameId.deserializeList(response) : [])),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch and return the paged data for all clients.
   *
   * @param requestParameters The request parameters to use.
   * @returns All paged client model data on success, undefined on error.
   */
  public getPagedList(
    requestParameters?: readonly ClientsRequestParam[],
  ): Observable<PagedList<Client> | undefined> {
    // Construct the HTTP parameters from the request parameters. Append the
    // the client flag to ensure we only get clients.
    const params = parseHttpParams(requestParameters)?.append('client', true);
    return this.httpClient
      .get<ClientPagedListApi | undefined>(`${config.api.url}/clients`, {
        params,
      })
      .pipe(
        switchMap(async (response) => {
          if (response === undefined) return undefined;
          const pagedClientList: PagedList<Client> = {
            ...decode(ClientPagedListCodec, response),
            results: await Client.deserializeList(response.results),
          };
          return pagedClientList;
        }),
        catchError((error: unknown) => {
          this.alertService.error({
            message:
              'An error occurred while fetching clients. Please contact Support if this issue persists.',
          });
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Update a clients admission date.
   *
   * @param id The client ID to update.
   * @param admissionDate The value to update.
   * @returns The updated client on success, undefined on error.
   */
  public patchAdmissionDate(
    id: Client['id'],
    admissionDate: ClientUpdate['admissionDateTime'],
  ): Observable<Client | undefined> {
    return this.httpClient
      .patch<ClientApi | undefined>(
        `${config.api.url}/clients/${id}/admissions`,
        {
          admissionDate,
        },
      )
      .pipe(
        switchMap((response) =>
          response ? Client.deserialize(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Update a clients code-status.
   *
   * @param id The client ID to update.
   * @param value The value to update.
   * @returns The updated client on success, undefined on error.
   */
  public patchCodeStatus(
    id: Client['id'],
    codeStatus: ClientUpdate['codeStatus'],
  ): Observable<Client | undefined> {
    return this.httpClient
      .patch<ClientApi | undefined>(
        `${config.api.url}/clients/${id}/code-status`,
        {
          codeStatus,
        },
      )
      .pipe(
        switchMap((response) =>
          response ? Client.deserialize(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Update a clients discharge date.
   *
   * @param id The client ID to update.
   * @param dischargeDate The value to update.
   * @returns The updated client on success, undefined on error.
   */
  public patchDischargeDate(
    id: Client['id'],
    dischargeDate: ClientUpdate['dischargeDateTime'],
  ): Observable<Client | undefined> {
    return this.httpClient
      .patch<ClientApi | undefined>(
        `${config.api.url}/clients/${id}/discharges`,
        {
          dischargeDate,
        },
      )
      .pipe(
        switchMap((response) =>
          response ? Client.deserialize(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Update a clients status.
   *
   * @param id The client ID to update.
   * @param value The value to update.
   * @returns The updated client on success, undefined on error.
   */
  public patchStatus(
    id: Client['id'],
    status: ClientUpdate['status'],
  ): Observable<Client | undefined> {
    return this.httpClient
      .patch<ClientApi | undefined>(`${config.api.url}/clients/${id}/status`, {
        status,
      })
      .pipe(
        switchMap((response) =>
          response ? Client.deserialize(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Update a client resource.
   *
   * @param id The client ID to update.
   * @param value The value to update.
   * @returns The updated client on success, undefined on error.
   */
  public put(
    id: Client['id'],
    value: ClientUpdate,
  ): Observable<Client | undefined> {
    return this.httpClient
      .put<
        ClientApi | undefined
      >(`${config.api.url}/clients/${id}`, value.serialize())
      .pipe(
        switchMap((response) =>
          response ? Client.deserialize(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          // Show first error message from API if it exists.
          if (error instanceof HttpErrorResponse) {
            if (isNonEmptyString(error.error.errors[0].message)) {
              // Display toast message.
              this.alertService.error({
                message: error.error.errors[0].message,
              });
            }
          }

          console.error(error);
          return of(undefined);
        }),
      );
  }
}

const ClientPagedListCodec = PagedListCodec(Client.Codec, 'ClientPagedListApi');

/**
 * Request parameter interface for use with the Client API.
 */
export interface ClientsRequestParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key:
    | 'facilityId'
    | 'levelOfCare'
    | 'limit'
    | 'order'
    | 'pageNumber'
    | 'pageSize'
    | 'search'
    | 'sort'
    | 'status';
  /** The value to use for the query parameter. */
  value: ClientOrderByEnum | ClientStatusEnum | SortOrderEnum | string | number;
}
