import { ReplaySubject, firstValueFrom, map } from 'rxjs';
import { ClientStatusEnum } from 'src/app/enumerators';
import { Client, ReferralContact, UserBase } from 'src/app/models';
import { FacilityService, SearchAll, SearchService } from 'src/app/services';
import { shareSingleReplay } from 'src/app/utilities';

import { CommonModule } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { ButtonComponent } from 'src/app/components/button/button.component';
import { CardsGridModule } from 'src/app/components/cards-grid/cards-grid.module';
import { ClientStatusBadgeKeyDisplayModule } from 'src/app/components/client/status/badge-key-display/client-status-badge-key-display.module';
import { ClientStatusBadgeModule } from 'src/app/components/client/status/badge/client-status-badge.module';
import { DialogRef } from 'src/app/components/dialogs/dialog-ref';
import { DialogModule } from 'src/app/components/dialogs/dialog.module';
import { FormDirective } from 'src/app/components/forms/form.directive';
import { InputDropdownModule } from 'src/app/components/forms/input-dropdown/input-dropdown.module';
import { InputRadioModule } from 'src/app/components/forms/input-radio/input-radio.module';
import { InputModule } from 'src/app/components/forms/input/input.module';
import { IconModule } from 'src/app/components/icon/icon.module';
import { LoadingIndicatorModule } from 'src/app/components/loading-indicator/loading-indicator.module';
import { TableColumn } from 'src/app/components/table/table.component';
import { TableModule } from 'src/app/components/table/table.module';
import { TabsModule } from 'src/app/components/tabs/tabs.module';

import { DirectivesModule } from 'src/app/directives/directives.module';

import { PipesModule } from 'src/app/pipes/pipes.module';

@Component({
  selector: 'alleva-person-search-dialog',
  templateUrl: './person-search-dialog.component.html',
  styleUrls: ['./person-search-dialog.component.scss'],
  imports: [
    ButtonComponent,
    CardsGridModule,
    ClientStatusBadgeKeyDisplayModule,
    ClientStatusBadgeModule,
    CommonModule,
    DialogModule,
    DirectivesModule,
    IconModule,
    InputDropdownModule,
    InputModule,
    InputRadioModule,
    LoadingIndicatorModule,
    MatBadgeModule,
    MatTooltipModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
    TableModule,
    TabsModule,
  ],
  providers: [SearchService],
  standalone: true,
})
export class PersonSearchDialogComponent
  extends FormDirective<PersonSearchFormGroup>
  implements OnInit
{
  public constructor(
    private readonly dialog: DialogRef<
      PersonSearchDialogComponentOutput | undefined
    >,
    private readonly facilityService: FacilityService,
    private readonly searchService: SearchService,
  ) {
    super();
  }

  protected get areTabsLoading(): boolean {
    return this.#areTabsLoading;
  }
  protected set areTabsLoading(value: boolean) {
    this.#areTabsLoading = value;
  }
  #areTabsLoading = false;

  /** The template ref for the custom __table__ "Image" columns. */
  @ViewChild('displayImageTemplateRef', { static: false })
  public readonly displayImageTemplateRef!: TemplateRef<unknown>;

  /** The template ref for the custom __table__ "Email" columns. */
  @ViewChild('emailTemplateRef', { static: false })
  public readonly emailTemplateRef!: TemplateRef<unknown>;

  /** The template ref for the custom __table__ "Active" columns. */
  @ViewChild('isActiveTemplateRef', { static: false })
  public readonly isActiveTemplateRef!: TemplateRef<unknown>;

  /** The template ref for the custom __table__ "Phone" columns. */
  @ViewChild('phoneTemplateRef', { static: false })
  public readonly phoneTemplateRef!: TemplateRef<unknown>;

  /** The template ref for the custom __table__ status column. */
  @ViewChild('statusTemplate', { static: false })
  private readonly statusTemplateRef!: TemplateRef<unknown>;

  protected hasSearched = false;
  protected isLoading = false;

  protected override readonly formGroup = formGroup;

  protected readonly ClientStatusEnum = ClientStatusEnum;

  protected readonly ViewStyleEnum = ViewStyleEnum;
  protected viewStyle: ViewStyleEnum = ViewStyleEnum.TABLE;

  protected readonly SearchInEnum = SearchInEnum;

  protected readonly clientTableColumns = clientTableColumns;
  protected readonly prospectiveClientTableColumns =
    prospectiveClientTableColumns;
  protected readonly referralsTableColumns = referralsTableColumns;
  protected readonly usersTableColumns = usersTableColumns;

  // TrackBy functions.
  protected readonly clientTrackBy = Client.trackBy;
  protected readonly prospectiveClientTrackBy = Client.trackBy;
  protected readonly referralContactTrackBy = ReferralContact.trackBy;
  protected readonly userBaseTrackBy = UserBase.trackBy;

  /** The previously searched string value. */
  protected previouslySearched: string | null = null;

  private readonly searchAllSubject = new ReplaySubject<SearchAll | null>(1);

  protected readonly clientsChanges = this.searchAllSubject.pipe(
    map((searchAll) => searchAll?.clients ?? null),
    map((clients) => {
      if (!clients) {
        return null;
      }
      return clients.map(
        (
          client,
        ): Client &
          DisplayImageCustomColumn &
          EmailCustomColumn &
          PhoneCustomColumn &
          DisplayStatusCustomColumn => ({
          ...client,
          displayImageTemplate: {
            context: { ...client },
            templateRef: this.displayImageTemplateRef,
          },
          emailTemplate: {
            context: { ...client },
            templateRef: this.emailTemplateRef,
          },
          phoneTemplate: {
            context: { ...client },
            templateRef: this.phoneTemplateRef,
          },
          statusTemplate: {
            context: { client },
            templateRef: this.statusTemplateRef,
          },
        }),
      );
    }),
    shareSingleReplay(),
  );

  protected readonly prospectiveClientsChanges = this.searchAllSubject.pipe(
    map((searchAll) => searchAll?.prospects ?? null),
    map((prospectiveClients) => {
      if (!prospectiveClients) {
        return null;
      }
      return prospectiveClients.map(
        (
          prospectiveClient,
        ): Client &
          DisplayImageCustomColumn &
          EmailCustomColumn &
          PhoneCustomColumn => ({
          ...prospectiveClient,
          displayImageTemplate: {
            context: { ...prospectiveClient },
            templateRef: this.displayImageTemplateRef,
          },
          emailTemplate: {
            context: { ...prospectiveClient },
            templateRef: this.emailTemplateRef,
          },
          phoneTemplate: {
            context: { ...prospectiveClient },
            templateRef: this.phoneTemplateRef,
          },
        }),
      );
    }),
    shareSingleReplay(),
  );

  protected readonly referralUsersChanges = this.searchAllSubject.pipe(
    map((searchAll) => searchAll?.referrals ?? null),
    map((referrals) => {
      if (!referrals) {
        return null;
      }
      return referrals.map(
        (
          referral,
        ): ReferralContact &
          DisplayImageCustomColumn &
          EmailCustomColumn &
          PhoneCustomColumn => ({
          ...referral,
          displayImageTemplate: {
            context: { profileImage: referral.image },
            templateRef: this.displayImageTemplateRef,
          },
          emailTemplate: {
            context: { ...referral },
            templateRef: this.emailTemplateRef,
          },
          phoneTemplate: {
            context: { ...referral },
            templateRef: this.phoneTemplateRef,
          },
        }),
      );
    }),
    shareSingleReplay(),
  );

  protected readonly usersChanges = this.searchAllSubject.pipe(
    map((searchAll) => searchAll?.users ?? null),
    map((users) => {
      if (!users) {
        return null;
      }
      return users.map(
        (
          user,
        ): UserBase &
          DisplayImageCustomColumn &
          PhoneCustomColumn &
          IsActiveCustomColumn => ({
          ...user,
          displayImageTemplate: {
            context: { profileImage: user.image },
            templateRef: this.displayImageTemplateRef,
          },
          isActiveTemplate: {
            context: { isActive: user.active },
            templateRef: this.isActiveTemplateRef,
          },
          phoneTemplate: {
            context: { phoneDisplay: user.phone || '—' },
            templateRef: this.phoneTemplateRef,
          },
        }),
      );
    }),
    shareSingleReplay(),
  );

  public ngOnInit(): void {
    this.formGroup.reset();
    this.formGroup.controls.searchIn.setValue(SearchInEnum.CURRENT_FACILITY);
  }

  protected async onClientClick(client: Client): Promise<void> {
    this.dialog.close({ selectedClient: client });
  }

  protected async onUserClick(user: UserBase): Promise<void> {
    this.dialog.close({ selectedUser: user });
  }

  protected async search(): Promise<void> {
    const { search, searchIn } = this.formGroup.controls;
    if (
      !search.valid ||
      !searchIn.valid ||
      search.value === null ||
      searchIn.value === null
    ) {
      return;
    }

    this.searchAllSubject.next(null);
    this.isLoading = true;

    const currentFacility = await firstValueFrom(
      this.facilityService.currentFacilityChanges,
    );

    const searchAllResult = await firstValueFrom(
      this.searchService.searchAll(
        search.value,
        searchIn.value === SearchInEnum.CURRENT_FACILITY
          ? currentFacility
          : null,
      ),
    );

    if (!searchAllResult) {
      throw new Error('Error, empty search response from API.');
    }

    this.previouslySearched = search.value;

    this.searchAllSubject.next(searchAllResult);

    this.hasSearched = true;
    this.isLoading = false;
  }
}

enum SearchInEnum {
  CURRENT_FACILITY = 'Current Facility',
  ALL_FACILITIES = 'All Facilities',
}

enum ViewStyleEnum {
  GRID = 'grid',
  TABLE = 'table',
}

interface PersonSearchFormGroup {
  search: FormControl<string | null>;
  searchIn: FormControl<SearchInEnum | null>;
}

const formGroup: FormGroup<PersonSearchFormGroup> = new FormGroup({
  search: new FormControl<string | null>(null, Validators.required),
  searchIn: new FormControl<SearchInEnum | null>(
    SearchInEnum.CURRENT_FACILITY,
    Validators.required,
  ),
});

export interface PersonSearchDialogComponentOutput {
  selectedClient?: Client;
  selectedUser?: UserBase;
}

interface DisplayImageCustomColumn {
  displayImageTemplate: TableTemplateRefObject<{
    profileImage: ReferralContact['image'];
  }>;
}

interface EmailCustomColumn {
  emailTemplate: TableTemplateRefObject<{ email: ReferralContact['email'] }>;
}

interface PhoneCustomColumn {
  phoneTemplate: TableTemplateRefObject<{
    phoneDisplay: ReferralContact['phoneDisplay'];
  }>;
}

interface IsActiveCustomColumn {
  isActiveTemplate: TableTemplateRefObject<{
    isActive: UserBase['active'];
  }>;
}

interface DisplayStatusCustomColumn {
  statusTemplate: TableTemplateRefObject<{ client: Client }>;
}

const clientTableColumns: ReadonlyArray<
  TableColumn<
    Client &
      DisplayImageCustomColumn &
      EmailCustomColumn &
      PhoneCustomColumn &
      DisplayStatusCustomColumn
  >
> = [
  {
    key: 'displayImageTemplate',
    label: '', // Empty label.
  },
  {
    key: 'fullName',
    label: 'Name',
  },
  {
    key: 'medicalRecordId',
    label: 'MRN',
  },
  {
    key: 'birthdate',
    label: 'DOB',
  },
  {
    key: 'age',
    label: 'Age',
  },
  {
    key: 'admissionDateTime',
    label: 'Admitted',
  },
  {
    key: 'dischargeDateTime',
    label: 'Discharged',
  },
  {
    key: 'phoneTemplate',
    label: 'Phone',
    isClickToCopyEnabled: true,
  },
  {
    key: 'emailTemplate',
    label: 'Email',
    isClickToCopyEnabled: true,
  },
  {
    key: 'facility.name',
    label: 'Facility',
  },
  {
    key: 'statusTemplate',
    label: 'Status',
  },
];

const prospectiveClientTableColumns: ReadonlyArray<
  TableColumn<
    Client & DisplayImageCustomColumn & EmailCustomColumn & PhoneCustomColumn
  >
> = [
  {
    key: 'displayImageTemplate',
    label: '', // Empty label.
  },
  {
    key: 'fullName',
    label: 'Name',
  },
  {
    key: 'medicalRecordId',
    label: 'MRN',
  },
  {
    key: 'birthdate',
    label: 'DOB',
  },
  {
    key: 'age',
    label: 'Age',
  },
  {
    key: 'admissionDateTime',
    label: 'Admitted',
  },
  {
    key: 'dischargeDateTime',
    label: 'Discharged',
  },
  {
    key: 'phoneTemplate',
    label: 'Phone',
    isClickToCopyEnabled: true,
  },
  {
    key: 'emailTemplate',
    label: 'Email',
    isClickToCopyEnabled: true,
  },
  {
    key: 'facility.name',
    label: 'Facility',
  },
];

const referralsTableColumns: ReadonlyArray<
  TableColumn<
    ReferralContact &
      DisplayImageCustomColumn &
      EmailCustomColumn &
      PhoneCustomColumn
  >
> = [
  {
    key: 'displayImageTemplate',
    label: '', // Empty label.
  },
  {
    key: 'name.fullName',
    label: 'Name',
  },
  {
    key: 'emailTemplate',
    label: 'Email',
    isClickToCopyEnabled: true,
  },
  {
    key: 'phoneTemplate',
    label: 'Phone',
    isClickToCopyEnabled: true,
  },
];

const usersTableColumns: ReadonlyArray<
  TableColumn<
    UserBase &
      DisplayImageCustomColumn &
      PhoneCustomColumn &
      IsActiveCustomColumn
  >
> = [
  {
    key: 'displayImageTemplate',
    label: '', // Empty label.
  },
  {
    key: 'fullName',
    label: 'Name',
  },
  {
    key: 'phoneTemplate',
    label: 'Phone',
    isClickToCopyEnabled: true,
  },
  {
    key: 'isActiveTemplate',
    label: 'Active',
  },
];
