import {
  LabResultApi,
  LabResultObservationApi,
  LabResultObservationDetailApi,
} from 'api/models';
import { Observable, catchError, of, switchMap } from 'rxjs';
import {
  Client,
  LabResult,
  LabResultObservation,
  LabResultObservationDetail,
} from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for retrieving lab results, including the lab observations/tests
 * and observation/test data.
 */
@Injectable()
export class LabsService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch the lab results.
   *
   * @param clientId The client ID to get the lab results for.
   * @returns An observable of the lab results for the given client ID, or
   * undefined if there was an error.
   */
  public getLabResults(
    clientId: Client['id'],
  ): Observable<readonly LabResult[] | undefined> {
    return this.httpClient
      .get<
        readonly LabResultApi[] | undefined
      >(`${config.api.url}/clients/${clientId}/lab-results`)
      .pipe(
        switchMap((response) =>
          response ? LabResult.deserializeList(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch the lab result observations.
   *
   * @param clientId The client ID to get the lab results for.
   * @param labResultId The lab result ID to get the observations for.
   * @returns The lab result observations for the given client ID and lab
   * result ID, or undefined if there was an error.
   */
  public getLabResultObservations(
    clientId: Client['id'],
    labResultId: LabResult['id'],
  ): Observable<readonly LabResultObservation[] | undefined> {
    return this.httpClient
      .get<
        readonly LabResultObservationApi[] | undefined
      >(`${config.api.url}/clients/${clientId}/lab-results/${labResultId}/observations`)
      .pipe(
        switchMap((response) =>
          response
            ? LabResultObservation.deserializeList(response)
            : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch the lab result observation details.
   *
   * @param clientId The related client id.
   * @param labResultId The related lab result id.
   * @param observationId The observation ID to get the details for.
   * @returns The lab result observation details for the given client ID or
   * undefined if there was an error.
   */
  public getLabResultObservationDetails(
    clientId: Client['id'],
    labResultId: LabResult['id'],
    observationId: LabResultObservation['id'],
  ): Observable<readonly LabResultObservationDetail[] | undefined> {
    return this.httpClient
      .get<
        readonly LabResultObservationDetailApi[] | undefined
      >(`${config.api.url}/clients/${clientId}/lab-results/${labResultId}/observations/${observationId}/details`)
      .pipe(
        switchMap((response) =>
          response
            ? LabResultObservationDetail.deserializeList(response)
            : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
