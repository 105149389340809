import { ClientInsuranceApi } from 'api/models';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { Client, ClientInsurance, ClientInsuranceUpdate } from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the Billing Client Insurance API.
 */
@Injectable()
export class ClientInsuranceService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Delete an insurance record by id.
   *
   * @param insuranceId The id of the insurance to delete.
   * @returns True if the insurance was deleted, false otherwise.
   */
  public delete(
    clientId: Client['id'],
    insuranceId: ClientInsurance['id'],
  ): Observable<boolean> {
    return this.httpClient
      .delete<boolean | undefined>(
        `${config.api.url}/clients/${clientId}/insurance/${insuranceId}`,
        {
          observe: 'response',
        },
      )
      .pipe(
        // Return bool based on 'success' status codes (200's) check.
        map((response) => response.status >= 200 && response.status < 300),
        catchError((error: unknown) => {
          console.error(error);
          return of(false);
        }),
      );
  }

  /**
   * Fetches a list of insurance for a client.
   *
   * @param clientId The id of the client to fetch insurance for.
   * @returns A list of insurance for the client.
   */
  public getList(
    clientId: Client['id'],
  ): Observable<readonly ClientInsurance[] | undefined> {
    return this.httpClient
      .get<
        readonly ClientInsuranceApi[] | undefined
      >(`${config.api.url}/clients/${clientId}/insurance`)
      .pipe(
        switchMap((response) =>
          response ? ClientInsurance.deserializeList(response) : of([]),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Patch a client's insurance record.
   *
   * @param clientId The id of the client to patch insurance for.
   * @param insuranceId The id of the insurance to patch.
   * @param insuranceUpdate The values to patch.
   * @returns The patched insurance record, or undefined on error.
   */
  public patch(
    clientId: Client['id'],
    insuranceId: ClientInsurance['id'],
    insuranceUpdate: ClientInsuranceUpdate,
  ): Observable<ClientInsurance | undefined> {
    return this.httpClient
      .patch<
        ClientInsuranceApi | undefined
      >(`${config.api.url}/clients/${clientId}/insurance/${insuranceId}`, insuranceUpdate.serialize())
      .pipe(
        switchMap((response) =>
          response ? ClientInsurance.deserialize(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Add (post) a client's ClientInsurance record.
   *
   * @param clientId The id of the client to add the new ClientInsurance for.
   * @param insuranceUpdate The ClientInsurance value to post.
   * @returns The new ClientInsurance record, or undefined on error.
   */
  public post(
    clientId: Client['id'],
    insuranceUpdate: ClientInsuranceUpdate,
  ): Observable<ClientInsurance | undefined> {
    return this.httpClient
      .post<
        ClientInsuranceApi | undefined
      >(`${config.api.url}/clients/${clientId}/insurance`, insuranceUpdate.serialize())
      .pipe(
        switchMap((response) =>
          response ? ClientInsurance.deserialize(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
