<alleva-screen-lock *ngIf="isScreenLockedChanges | async"></alleva-screen-lock>

<ng-container *ngIf="isAuthenticatedChanges | async; else loadingTemplate">
  <alleva-layout-header></alleva-layout-header>
  <alleva-layout-navbar></alleva-layout-navbar>
  <alleva-layout-page-content [isLoading]="isLoadingChanges | async">
    <ng-container [ngTemplateOutlet]="pageContentTemplate"></ng-container>
  </alleva-layout-page-content>
  <alleva-layout-quick-launch-menu
    *ngIf="isQuickLaunchMenuEnabled"
  ></alleva-layout-quick-launch-menu>
  <alleva-layout-footer></alleva-layout-footer>
</ng-container>

<ng-template #pageContentTemplate>
  <ng-container
    *ngIf="(isLoggingOutChanges | async) === false; else loadingTemplate"
  >
    <router-outlet></router-outlet>
  </ng-container>
</ng-template>

<ng-template #loadingTemplate>
  <div class="loading-container">
    <alleva-loading-indicator
      *ngIf="hasAuthenticationErrorSignal() === false; else loginErrorTemplate"
      [isCentered]="true"
    ></alleva-loading-indicator>
    <ng-template #loginErrorTemplate>
      <div class="error-message">
        <h2>
          <alleva-icon color="warn" inline="true" name="error"></alleva-icon>
          Login Error
        </h2>
        <p>Click <a href (click)="navigateToLogin()">here</a> to try again.</p>
      </div>
    </ng-template>
  </div>
</ng-template>
