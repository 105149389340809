import { InsuranceDocumentApi, InsuranceDocumentUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { NameId } from 'src/app/models/core/name-id.model';

const api = apiDecorator<InsuranceDocumentApi>();

abstract class InsuranceDocumentBase {
  public constructor(props: ClassProperties<InsuranceDocumentBase>) {
    this.fileName = props.fileName;
    this.path = props.path;
  }

  @api({ key: 'fileName' }) public readonly fileName: string;
  @api({ key: 'path' }) public readonly path: string;
}

export class InsuranceDocument extends InsuranceDocumentBase {
  public constructor(props: ClassProperties<InsuranceDocument>) {
    super(props);

    this.description = props.description;
    this.documentName = props.documentName;
    this.id = props.id;
    this.messageId = props.messageId;
    this.tableId = props.tableId;
    this.type = props.type;
    this.uploadedAt = props.uploadedAt;
  }

  /**
   * The io-ts codec for runtime type checking of the Insurance Document model.
   */
  public static readonly Codec = io.type(
    {
      description: io.union([io.string, io.null]),
      documentName: io.union([io.string, io.null]),
      fileName: io.string,
      id: io.number,
      messageId: io.union([io.number, io.null]),
      path: io.string,
      tableId: io.union([io.number, io.null]),
      type: io.union([NameId.Codec, io.null]),
      uploadedAt: io.string,
    },
    'InsuranceDocumentApi',
  );

  @api({ key: 'description' }) public readonly description: string | null;
  @api({ key: 'documentName' }) public readonly documentName: string | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'messageId' }) public readonly messageId: number | null;
  @api({ key: 'tableId' }) public readonly tableId: number | null;
  @api({ key: 'type' }) public readonly type: NameId | null;
  @api({ key: 'uploadedAt' }) public readonly uploadedAt: DateTime;

  /**
   * Deserializes a Insurance Document object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Insurance Document object.
   * @throws An error if the value is not a valid Insurance Document object.
   */
  public static async deserialize(
    value: NonNullable<InsuranceDocumentApi>,
  ): Promise<InsuranceDocument> {
    const decoded = decode(InsuranceDocument.Codec, value);
    return new InsuranceDocument({
      description: decoded.description,
      documentName: decoded.documentName,
      fileName: decoded.fileName,
      id: decoded.id,
      messageId: decoded.messageId,
      path: decoded.path,
      tableId: decoded.tableId,
      type: decoded.type ? NameId.deserialize(decoded.type) : null,
      uploadedAt: await DateTime.fromISO(
        decoded.uploadedAt,
      ).toCurrentFacilityTime(),
    });
  }

  /**
   * Deserializes a list of Insurance Document objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Insurance Document objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Insurance Document objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<InsuranceDocumentApi>>,
  ): Promise<readonly InsuranceDocument[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Insurance Document objects.');
    }
    return Promise.all(values.map(InsuranceDocument.deserialize));
  }
}

export class InsuranceDocumentUpdate extends InsuranceDocumentBase {
  public constructor(props: ClassProperties<InsuranceDocumentUpdate>) {
    super(props);
  }

  public serialize(): InsuranceDocumentUpdateApi {
    return { ...this };
  }
}
