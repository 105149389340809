import { ClientTreatmentApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { ClientTreatmentLevelOfCare } from 'src/app/models/client/client-treatment-level-of-care.model';
import { Facility } from 'src/app/models/facility/facility.model';

const api = apiDecorator<ClientTreatmentApi>();

export class ClientTreatment {
  public constructor(props: ClassProperties<ClientTreatment>) {
    this.clientTreatmentLevelOfCare = props.clientTreatmentLevelOfCare;
    this.facility = props.facility;
    this.id = props.id;
    this.leadId = props.leadId;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Treatment API
   * model.
   */
  public static readonly Codec = io.type(
    {
      facility: Facility.Codec,
      id: io.number,
      leadId: io.number,
      levelOfCare: ClientTreatmentLevelOfCare.Codec,
    },
    'ClientTreatmentApi',
  );

  @api({ key: 'levelOfCare' })
  public readonly clientTreatmentLevelOfCare: ClientTreatmentLevelOfCare;
  @api({ key: 'facility' }) public readonly facility: Facility;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'leadId' }) public readonly leadId: number;

  /**
   * Deserializes a Client Treatment object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Treatment object.
   * @throws An error if the value is not a valid Client Treatment object.
   */
  public static async deserialize(
    value: NonNullable<ClientTreatmentApi>,
  ): Promise<ClientTreatment> {
    const decoded = decode(ClientTreatment.Codec, value);
    return new ClientTreatment({
      clientTreatmentLevelOfCare: await ClientTreatmentLevelOfCare.deserialize(
        decoded.levelOfCare,
      ),
      facility: Facility.deserialize(decoded.facility),
      id: decoded.id,
      leadId: decoded.leadId,
    });
  }

  /**
   * Deserializes a list of Client Treatment objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Treatment objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Treatment
   * objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<ClientTreatmentApi>>,
  ): Promise<readonly ClientTreatment[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Treatment objects.');
    }
    return Promise.all(values.map(ClientTreatment.deserialize));
  }
}
