import { ClientAppointmentApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { ClientAppointmentsStatusEnum } from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

import { UserBase } from 'src/app/models/user/user.model';

const api = apiDecorator<ClientAppointmentApi>();

export class ClientAppointment {
  public constructor(props: ClassProperties<ClientAppointment>) {
    this.endDate = props.endDate;
    this.id = props.id;
    this.leader = props.leader;
    this.name = props.name;
    this.startDate = props.startDate;
    this.status = props.status;
  }

  public static readonly Codec = io.type(
    {
      endDate: io.string,
      id: io.number,
      leader: UserBase.BaseCodec,
      name: io.string,
      startDate: io.string,
      participantStatus: io.union([
        io.literal(ClientAppointmentsStatusEnum.ATTENDED),
        io.literal(ClientAppointmentsStatusEnum.CANCELED),
        io.literal(ClientAppointmentsStatusEnum.INCOMPLETE),
        io.literal(ClientAppointmentsStatusEnum.NO_SHOW),
      ]),
    },
    'ClientAppointmentApi',
  );

  @api({ key: 'endDate' }) public readonly endDate: DateTime;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'leader' }) public readonly leader: UserBase;
  @api({ key: 'name' }) public readonly name: string;
  @api({ key: 'startDate' }) public readonly startDate: DateTime;
  @api({ key: 'participantStatus' })
  public readonly status: ClientAppointmentsStatusEnum;

  /**
   * Deserializes a Client Appointment object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Appointment object.
   * @throws An error if the value is not a valid Client Appointment object.
   */
  public static async deserialize(
    value: NonNullable<ClientAppointmentApi>,
  ): Promise<ClientAppointment> {
    const decoded = decode(ClientAppointment.Codec, value);
    return new ClientAppointment({
      ...decoded,
      endDate: await DateTime.fromISO(decoded.endDate).toCurrentFacilityTime(),
      leader: UserBase.deserialize(decoded.leader),
      startDate: await DateTime.fromISO(
        decoded.startDate,
      ).toCurrentFacilityTime(),
      status: decoded.participantStatus,
    });
  }

  /**
   * Deserializes a list of Client Appointment objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Appointment objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Appointment
   * objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<ClientAppointmentApi>>,
  ): Promise<readonly ClientAppointment[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Appointment objects.');
    }
    return Promise.all(values.map(ClientAppointment.deserialize));
  }
}
