import { BillingRuleRateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<BillingRuleRateApi>();

type BillingRuleRateArgs = Omit<
  ClassProperties<BillingRuleRate>,
  // Omit computed properties based on core model data.
  'endDate' | 'startDate'
>;

export class BillingRuleRate {
  public constructor(props: ClassProperties<BillingRuleRateArgs>) {
    this.contractRate = props.contractRate;
    this.endDateTime = props.endDateTime;
    this.id = props.id;
    this.isCurrent = props.isCurrent;
    this.startDateTime = props.startDateTime;
    this.unitRate = props.unitRate;

    // Computed values
    this.endDate = this.endDateTime.toJSDate();
    this.startDate = this.startDateTime.toJSDate();
  }

  /**
   * The io-ts codec for runtime type checking of the Billing Payor Rule Rate
   * API model.
   */
  public static readonly Codec = io.type(
    {
      contractRate: io.union([io.number, io.null]),
      endDate: io.string,
      id: io.number,
      isCurrent: io.boolean,
      startDate: io.string,
      unitRate: io.number,
    },
    'BillingRuleRateApi',
  );

  @api({ key: 'contractRate' }) public readonly contractRate: number | null;
  @api({ key: 'endDate' }) public readonly endDateTime: DateTime;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'isCurrent' }) public readonly isCurrent: boolean;
  @api({ key: 'startDate' }) public readonly startDateTime: DateTime;
  @api({ key: 'unitRate' }) public readonly unitRate: number;

  // Computed values
  public readonly endDate: Date;
  public readonly startDate: Date;

  /**
   * Deserializes a Billing Payor Rule Rate object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Billing Payor Rule Rate object.
   * @throws An error if the value is not a valid Billing Payor Rule Rate
   * object.
   */
  public static async deserialize(
    value: NonNullable<BillingRuleRateApi>,
  ): Promise<BillingRuleRate> {
    const decoded = decode(BillingRuleRate.Codec, value);

    return new BillingRuleRate({
      contractRate: decoded.contractRate,
      endDateTime: await DateTime.fromISO(
        decoded.endDate,
      ).toCurrentFacilityTime(),
      id: decoded.id,
      isCurrent: decoded.isCurrent,
      startDateTime: await DateTime.fromISO(
        decoded.startDate,
      ).toCurrentFacilityTime(),
      unitRate: decoded.unitRate,
    });
  }

  /**
   * Deserializes a list of Billing Payor Rule Rate objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Billing Payor Rule Rate objects.
   */
  public static async deserializeList(
    values: readonly BillingRuleRateApi[],
  ): Promise<readonly BillingRuleRate[]> {
    return Promise.all(values.map(BillingRuleRate.deserialize));
  }
}
