import { UserNotificationApi, UserNotificationUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { ClientBase } from '../client/client.model';
import { UserBase } from './user.model';

const api = apiDecorator<UserNotificationApi>();

export class UserNotification {
  public constructor(props: ClassProperties<UserNotification>) {
    this.client = props.client ?? null;
    this.date = props.date;
    this.id = props.id;
    this.isRead = props.isRead;
    this.message = props.message;
    this.raisedBy = props.raisedBy;
  }

  /**
   * The io-ts codec for runtime type checking of the User Notification API
   * model.
   */
  public static readonly Codec = io.type(
    {
      client: io.union([ClientBase.BaseCodec, io.null]),
      date: io.string,
      id: io.number,
      isRead: io.boolean,
      message: io.union([io.string, io.null]),
      raisedBy: UserBase.BaseCodec,
    },
    'UserNotificationApi',
  );

  @api({ key: 'client' }) public readonly client: ClientBase | null;
  @api({ key: 'date' }) public readonly date: DateTime;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'isRead' }) public readonly isRead: boolean;
  @api({ key: 'message' }) public readonly message: string | null;
  @api({ key: 'raisedBy' }) public readonly raisedBy: UserBase;

  /**
   * Deserializes a User Notification object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized User Notification object.
   * @throws An error if the value is not a valid User Notification object.
   */
  public static async deserialize(
    value: NonNullable<UserNotificationApi>,
  ): Promise<UserNotification> {
    const decoded = decode(UserNotification.Codec, value);
    return new UserNotification({
      client: decoded.client
        ? await ClientBase.deserialize(decoded.client)
        : null,
      date: await DateTime.fromISO(decoded.date).toCurrentFacilityTime(),
      id: decoded.id,
      isRead: decoded.isRead,
      message: decoded.message,
      raisedBy: UserBase.deserialize(decoded.raisedBy),
    });
  }

  /**
   * Deserializes a list of User Notification objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized User Notification objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid User Notification
   * objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<UserNotificationApi>>,
  ): Promise<readonly UserNotification[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of User Notification objects.');
    }
    return Promise.all(values.map(UserNotification.deserialize));
  }
}

export class UserNotificationUpdate {
  public constructor(props: ClassProperties<UserNotificationUpdate>) {
    this.isRead = props.isRead;
  }

  /**
   * The io-ts codec for runtime type checking of the User Notification API
   * model.
   */
  public static readonly Codec = io.type(
    {
      isRead: io.boolean,
    },
    'UserNotificationUpdateApi',
  );

  @api({ key: 'isRead' }) public readonly isRead: boolean;

  /**
   * Serialize the Client Diagnosis Update object to an API model.
   *
   * @returns The serialized Client Diagnosis Update object.
   */
  public serialize(): UserNotificationUpdateApi {
    return {
      isRead: this.isRead,
    };
  }
}
