import { ReviewerApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import { Client, Reviewer } from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the Client Reviewers API.
 */
@Injectable()
export class ReviewersService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch and return the reviewers data for the given client.
   *
   * @param clientId The client id to get the reviewers for.
   * @returns The reviewers list on success, undefined on error.
   */
  public getAll(
    clientId: Client['id'],
  ): Observable<readonly Reviewer[] | undefined> {
    return this.httpClient
      .get<readonly ReviewerApi[] | undefined>(`${config.api.url}/reviewers`, {
        params: { clientId },
      })
      .pipe(
        map((response) => (response ? Reviewer.deserializeList(response) : [])),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
