import { AuthModule } from 'angular-auth-oidc-client';
import { RootHttpInterceptor } from 'src/app/root-http.interceptor';
import { RootRoutingModule } from 'src/app/root-routing.module';
import { RootComponent } from 'src/app/root.component';
import { FacilityService, openIdConfiguration } from 'src/app/services';
import { initializeDateTime } from 'src/app/utilities';
import { initializeStringPrototype } from 'src/global';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule, isDevMode } from '@angular/core';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { DialogModule } from 'src/app/components/dialogs/dialog.module';
import { IconModule } from 'src/app/components/icon/icon.module';
import { LoadingIndicatorModule } from 'src/app/components/loading-indicator/loading-indicator.module';
import { ScreenLockModule } from 'src/app/components/screen-lock/screen-lock.module';

import { LayoutModule } from 'src/app/layout/layout.module';

import {
  RootConsoleInterceptor,
  rootConsoleInterceptorFactory,
} from './root-console.interceptor';

@NgModule({
  declarations: [RootComponent],
  imports: [
    // OIDC Setup & Configuration
    AuthModule.forRoot({ config: openIdConfiguration }),
    BrowserAnimationsModule,
    BrowserModule,
    DialogModule,
    HttpClientModule,
    IconModule,
    LayoutModule,
    LoadingIndicatorModule,
    MatLuxonDateModule,
    MatSnackBarModule,
    RootRoutingModule,
    ScreenLockModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  bootstrap: [RootComponent],
  providers: [
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: RootHttpInterceptor,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: rootConsoleInterceptorFactory,
      deps: [RootConsoleInterceptor],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeDateTime,
      deps: [FacilityService],
      multi: true,
    },
  ],
})
export class RootModule {
  public constructor(private readonly injector: Injector) {
    initializeStringPrototype(this.injector);
  }
}
