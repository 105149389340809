import { Injectable } from '@angular/core';

import { AlertService } from './services/root/alert.service';

/**
 * A service to intercept console logs and handle them globally in a more
 * specific way. Both from the Alleva app and 3rd party libraries.
 */
@Injectable({ providedIn: 'root' })
export class RootConsoleInterceptor {
  public constructor(private readonly alertService: AlertService) {}

  public overrideConsole(): void {
    const originalWarn = console.warn;
    const originalError = console.error;

    console.warn = (...args: unknown[]) => {
      this.handleLog('warn', args);
      originalWarn.apply(console, args);
    };

    console.error = (...args: unknown[]) => {
      this.handleLog('error', args);
      originalError.apply(console, args);
    };
  }

  private handleLog(_type: 'warn' | 'error', args: unknown[]): void {
    const message = args.join(' ');

    handleOidcLogs(message, this.alertService);
  }
}

/**
 * Handle specific `angular-auth-oidc-client` messages with toast.
 *
 * @param message The message to display in the toast.
 * @param alertService The alert service to use for displaying toast.
 */
function handleOidcLogs(message: string, alertService: AlertService): void {
  const durationSeconds = 9999;
  if (message.includes('authCallback Validation')) {
    if (message.includes('iat rejected id_token')) {
      alertService.error({
        durationSeconds,
        message:
          'Login error: Your device clock is out of sync with the server.',
      });
    } else if (message.includes('incorrect state')) {
      alertService.error({
        durationSeconds,
        message:
          'Login error: Session state mismatch. Please try logging in again.',
      });
    } else if (message.includes('nonce')) {
      alertService.error({
        durationSeconds,
        message: 'Login error: Authentication token validation failed.',
      });
    } else if (message.includes('id token expired')) {
      alertService.error({
        durationSeconds,
        message: 'Session expired: Please log in again.',
      });
    }
  }
}

export function rootConsoleInterceptorFactory(
  interceptor: RootConsoleInterceptor,
): () => void {
  return () => interceptor.overrideConsole();
}
