import {
  RevenueCodeApi,
  RevenueCodeCreateApi,
  RevenueCodeUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<RevenueCodeApi>();

type RevenueCodeArgs = Omit<
  ClassProperties<RevenueCode>,
  // Omit UI only, computed properties based on core model data.
  'displayValue'
>;

export class RevenueCodeBase {
  public constructor(props: ClassProperties<RevenueCodeBase>) {
    this.code = props.code;
    this.description = props.description;
    this.isActive = props.isActive;
  }

  @api({ key: 'code' }) public readonly code: string;
  @api({ key: 'description' }) public readonly description: string;
  @api({ key: 'isActive' }) public readonly isActive: boolean;
}

export class RevenueCode extends RevenueCodeBase {
  public constructor(props: ClassProperties<RevenueCodeArgs>) {
    super(props);

    this.id = props.id;
    this.isCustom = props.isCustom;

    // Computed values
    this.displayValue = `${this.code} - ${this.description}`;
  }

  /**
   * The io-ts codec for runtime type checking of the Revenue Code API model.
   */
  public static readonly Codec = io.type(
    {
      code: io.string,
      description: io.string,
      id: io.number,
      isActive: io.boolean,
      isCustom: io.boolean,
    },
    'RevenueCodeApi',
  );

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'isCustom' }) public readonly isCustom: boolean;

  // Computed properties.
  public readonly displayValue: string;

  /**
   * Deserializes a Revenue Code object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Revenue Code object.
   */
  public static deserialize(value: NonNullable<RevenueCodeApi>): RevenueCode {
    const decoded = decode(RevenueCode.Codec, value);

    return new RevenueCode(decoded);
  }

  /**
   * Deserializes a list of Revenue Code objects from the API model.
   *
   * @param values The value to deserialize.
   * @returns The deserialized list of Revenue Code objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Revenue Code objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<RevenueCodeApi>>,
  ): readonly RevenueCode[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Revenue Code objects.');
    }
    return values.map(RevenueCode.deserialize);
  }
}

/** The Revenue Code create (POST) model. */
export class RevenueCodeCreate extends RevenueCodeBase {
  public serialize(): RevenueCodeCreateApi {
    return {
      code: this.code,
      description: this.description,
      isActive: this.isActive,
    };
  }
}

/** The Revenue Code update (PUT) model. */
export class RevenueCodeUpdate extends RevenueCode {
  public serialize(): RevenueCodeUpdateApi {
    return {
      code: this.code,
      description: this.description,
      id: this.id,
      isActive: this.isActive,
      isCustom: this.isCustom,
    };
  }
}
