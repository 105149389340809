import { ReleaseOfInformationApi } from 'api/models';
import { Observable, catchError, of, switchMap } from 'rxjs';
import {
  Client,
  ClientContact,
  ReleaseOfInformation,
  ReleaseOfInformationUpdate,
} from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the Release of Information (ROI) API.
 */
@Injectable()
export class ReleaseOfInformationService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Get a list of release of information history for the provided client
   * contact.
   *
   * @param clientId The client id of the client contact.
   * @param clientContactId The client contact id.
   * @returns The a list of release of information history for the provided
   * client contact.
   */
  public getClientContactRoiHistoryList(
    clientId: Client['id'],
    clientContactId: ClientContact['id'],
  ): Observable<readonly ReleaseOfInformation[] | undefined> {
    return this.httpClient
      .get<
        readonly ReleaseOfInformationApi[] | undefined
      >(`${config.api.url}/clients/${clientId}/contacts/${clientContactId}/roi`)
      .pipe(
        switchMap((response) =>
          response ? ReleaseOfInformation.deserializeList(response) : of([]),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Create a new release of information for the provided client contact.
   *
   * @param clientId The client id of the client contact.
   * @param clientContactId The client contact id.
   * @param releaseOfInformationUpdate The release of information update.
   * @returns The created release of information object or undefined if the
   * request fails.
   */
  public createClientContactRoi(
    clientId: Client['id'],
    clientContactId: ClientContact['id'],
    releaseOfInformationUpdate: ReleaseOfInformationUpdate,
  ): Observable<ReleaseOfInformation | undefined> {
    return this.httpClient
      .post<
        ReleaseOfInformationApi | undefined
      >(`${config.api.url}/clients/${clientId}/contacts/${clientContactId}/roi`, releaseOfInformationUpdate.serialize())
      .pipe(
        switchMap((response) =>
          response ? ReleaseOfInformation.deserialize(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Revoke a release of information for the provided client contact.
   *
   * @param clientContactId The client contact id to remove the release of
   * information from.
   * @param clientId The parent client id of the client contact.
   * @param releaseOfInformationId The release of information id to revoke.
   * @param revokeReason The reason for revoking the release of information.
   * @param revokeSignature The signature of the revoking user.
   * @returns The revoked release of information object or undefined if the
   * request fails.
   */
  public revokeClientContactRoi({
    clientContactId,
    clientId,
    releaseOfInformationId: id,
    revokeReason,
    revokeSignature,
  }: {
    clientContactId: ClientContact['id'];
    clientId: Client['id'];
    releaseOfInformationId: ReleaseOfInformation['id'];
    revokeReason: string;
    revokeSignature: Base64<'png'>;
  }): Observable<ReleaseOfInformation | undefined> {
    return this.httpClient
      .delete<ReleaseOfInformationApi | undefined>(
        `${config.api.url}/clients/${clientId}/contacts/${clientContactId}/roi`,
        {
          body: {
            id,
            revokeDate: new Date().toISOString(),
            revokeReason,
            revokeSignature,
          },
        },
      )
      .pipe(
        switchMap((response) =>
          response ? ReleaseOfInformation.deserialize(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
