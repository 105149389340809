import { ClientContactsApi } from 'api/models';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { Client, ClientContact, ClientContactReferral } from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the client contacts API.
 */
@Injectable()
export class ClientContactsService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Gets the client contacts for the given client ID.
   *
   * @param clientId The client ID to get the contacts for.
   * @returns The client contacts for the given client ID, undefined on error.
   */
  public get(
    clientId: Client['id'],
  ): Observable<ClientContactsResponse | undefined> {
    return this.httpClient
      .get<
        ClientContactsApi | undefined
      >(`${config.api.url}/clients/${clientId}/contacts`)
      .pipe(
        switchMap(async (response) => {
          if (response === undefined) {
            return undefined;
          }

          const result: ClientContactsResponse = {
            contacts: response.emergency
              ? await ClientContact.deserializeList(response.emergency)
              : null,
            referralContact:
              // If all nested properties are null, return null.
              response.referral.company || response.referral.contact
                ? ClientContactReferral.deserialize(response.referral)
                : null,
          };
          return result;
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}

export interface ClientContactsResponse {
  contacts: readonly ClientContact[] | null;
  referralContact: ClientContactReferral | null;
}
