import {
  BillingCodeApi,
  BillingCodeCreateApi,
  BillingCodeUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { AppliesToEnum } from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<BillingCodeApi>();

abstract class BillingCodeBase {
  public constructor(props: ClassProperties<BillingCodeBase>) {
    this.appliesTo = props.appliesTo;
    this.code = props.code;
    this.description = props.description;
    this.isActive = props.isActive;
    this.isCustom = props.isCustom;
    this.shortDescription = props.shortDescription;
    this.unitCost = props.unitCost;
  }

  @api({ key: 'appliesTo' }) public readonly appliesTo: AppliesToEnum | null;
  @api({ key: 'code' }) public readonly code: string;
  @api({ key: 'description' }) public readonly description: string | null;
  @api({ key: 'isActive' }) public readonly isActive: boolean;
  @api({ key: 'isCustom' }) public readonly isCustom: boolean;
  @api({ key: 'shortDescription' }) public readonly shortDescription:
    | string
    | null;
  @api({ key: 'unitCost' }) public readonly unitCost: number | null;
}

export class BillingCode extends BillingCodeBase {
  public constructor(props: ClassProperties<BillingCode>) {
    super(props);

    this.id = props.id;
  }

  /** The io-ts codec for runtime type checking of the Billing Code model. */
  public static readonly Codec = io.type(
    {
      appliesTo: io.union([
        io.literal(AppliesToEnum.GROUP),
        io.literal(AppliesToEnum.INDIVIDUAL),
        io.literal(AppliesToEnum.INTAKE),
        io.null,
      ]),
      code: io.string,
      description: io.union([io.string, io.null]),
      id: io.number,
      isActive: io.boolean,
      isCustom: io.boolean,
      shortDescription: io.union([io.string, io.null]),
      unitCost: io.union([io.number, io.null]),
    },
    'BillingCodeApi',
  );

  @api({ key: 'id' }) public readonly id: number;

  /**
   * Deserializes a Billing Code object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Billing Code object.
   * @throws An error if the value is not a valid Billing Code object.
   */
  public static deserialize(value: NonNullable<BillingCodeApi>): BillingCode {
    const decoded = decode(BillingCode.Codec, value);
    return new BillingCode({
      appliesTo: decoded.appliesTo,
      code: decoded.code,
      description: decoded.description,
      id: decoded.id,
      isActive: decoded.isActive,
      isCustom: decoded.isCustom,
      shortDescription: value.shortDescription,
      unitCost: decoded.unitCost,
    });
  }

  /**
   * Deserializes a list of Billing Code objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Billing Code objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Billing Code objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<BillingCodeApi>>,
  ): readonly BillingCode[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Billing Code objects.');
    }
    return values.map(BillingCode.deserialize);
  }
}

export class BillingCodeCreate extends BillingCodeBase {
  public constructor(props: ClassProperties<BillingCodeCreate>) {
    super(props);
  }

  /** Serialize the data to a format that the API accepts. */
  public serialize(): BillingCodeCreateApi {
    return {
      appliesTo: this.appliesTo,
      code: this.code,
      description: this.description,
      isActive: this.isActive,
      isCustom: this.isCustom,
      shortDescription: this.shortDescription,
      unitCost: this.unitCost,
    };
  }
}

export class BillingCodeUpdate extends BillingCode {
  public constructor(props: ClassProperties<BillingCodeUpdate>) {
    super(props);
  }

  /** Serialize the data to a format that the API accepts. */
  public serialize(): BillingCodeUpdateApi {
    return {
      appliesTo: this.appliesTo,
      code: this.code,
      description: this.description,
      id: this.id,
      isActive: this.isActive,
      isCustom: this.isCustom,
      shortDescription: this.shortDescription,
      unitCost: this.unitCost,
    };
  }
}
