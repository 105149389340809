import { NurseApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { Name } from 'src/app/models/user/name.model';
import { UserLicense } from 'src/app/models/user/user-license.model';

const api = apiDecorator<NurseApi>();

export class Nurse {
  public constructor(props: ClassProperties<Nurse>) {
    this.active = props.active;
    this.id = props.id;
    this.image = props.image;
    this.licenses = props.licenses;
    this.phone = props.phone;
    this.userName = props.userName;
  }

  /**
   * The io-ts codec for runtime type checking of the Nurse model.
   */
  public static readonly Codec = io.type(
    {
      active: io.boolean,
      id: io.number,
      image: io.union([io.string, io.null]),
      licenses: io.union([io.array(UserLicense.Codec), io.null]),
      phone: io.union([io.string, io.null]),
      name: io.union([Name.Codec, io.null]),
    },
    'NurseApi',
  );

  @api({ key: 'active' }) public readonly active: boolean;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'image' }) public readonly image: string | null;
  @api({ key: 'licenses' }) public readonly licenses:
    | readonly UserLicense[]
    | null;
  @api({ key: 'phone' }) public readonly phone: string | null;
  @api({ key: 'name' }) public readonly userName: Name | null;

  /**
   * Deserializes a Nurse object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Nurse object.
   * @throws An error if the value is not a valid Nurse object.
   */
  public static async deserialize(
    value: NonNullable<NurseApi>,
  ): Promise<Nurse> {
    const decoded = decode(Nurse.Codec, value);
    return new Nurse({
      ...decoded,
      licenses: decoded.licenses
        ? await UserLicense.deserializeList(decoded.licenses)
        : null,
      userName: decoded.name ? Name.deserialize(decoded.name) : null,
    });
  }

  /**
   * Deserializes a list of Nurse objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Nurse objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Nurse objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<NurseApi>>,
  ): Promise<readonly Nurse[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Nurse objects.');
    }
    return Promise.all(values.map(Nurse.deserialize));
  }
}
