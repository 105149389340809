import { ClientApi, ClientBaseApi, ClientUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { GenderEnum, LanguageEnum, RaceEnum } from 'src/app/enumerators';
import { decode, getFullName } from 'src/app/utilities';

import { TimeZonesCodec } from 'src/app/constants/time-zones';

import { Allergen } from 'src/app/models/allergen/allergen.model';
import { ClientBed } from 'src/app/models/client/client-bed.model';
import { ClientIntakesCompleted } from 'src/app/models/client/client-intakes-completed.model';
import { ClientLevelOfCare } from 'src/app/models/client/client-level-of-care.model';
import { ClientName } from 'src/app/models/client/client-name.model';
import { CodeStatus } from 'src/app/models/client/code-status.model';
import { NameId } from 'src/app/models/core/name-id.model';
import { Facility } from 'src/app/models/facility/facility.model';
import { Address } from 'src/app/models/user/address.model';
import { Phone } from 'src/app/models/user/phone.model';
import { Pronouns } from 'src/app/models/user/pronoun.model';
import { Religion } from 'src/app/models/user/religion.model';

type BaseClientArgs = Omit<
  ClassProperties<ClientBase>,
  // Omit computed properties based on core model data.
  'fullName'
>;

type ClientArgs = Omit<
  ClassProperties<Client>,
  // Omit computed properties based on core model data.
  | 'activeBed'
  | 'age'
  | 'allergyNames'
  | 'fullName'
  | 'levelOfCareNames'
  | 'phoneDisplay'
  | 'phoneNumbers'
>;

const api = apiDecorator<ClientApi>();
const apiBase = apiDecorator<ClientBaseApi>();

export class ClientBase {
  public constructor(props: ClassProperties<BaseClientArgs>) {
    this.chartId = props.chartId;
    this.birthdate = props.birthdate;
    this.email = props.email;
    this.gender = props.gender;
    this.name = props.name;
    this.profileImage = props.profileImage;
    this.timeZone = props.timeZone;

    // Computed properties based on core model data.
    this.fullName = getFullName({ ...props.name }) || 'N/A';
  }

  /**
   * The io-ts codec for runtime type checking of the Client Base model.
   */
  public static readonly BaseCodec = io.type(
    {
      chartId: io.union([io.number, io.null]),
      dateOfBirth: io.union([io.string, io.null]),
      email: io.union([io.string, io.null]),
      gender: io.union([
        io.literal(GenderEnum.FEMALE),
        io.literal(GenderEnum.GENDER_VARIANT_NON_CONFORMING),
        io.literal(GenderEnum.MALE),
        io.literal(GenderEnum.NON_BINARY),
        io.literal(GenderEnum.NO_PREFERRENCE),
        io.literal(GenderEnum.PREFFER_NOT_TO_ANSWER),
        io.literal(GenderEnum.TRANSGENDER),
        io.null,
      ]),
      name: ClientName.Codec,
      profileImage: io.union([io.string, io.null]),
      timeZone: io.union([io.string, io.null]),
    },
    'ClientBaseApi',
  );

  @apiBase({ key: 'dateOfBirth' }) public readonly birthdate: Date | null;
  /**
   * The chart ID of the client, currently only used for passing along for
   * legacy routing.
   *
   * @deprecated This property is deprecated and will be removed in the future.
   */
  @apiBase({ key: 'chartId' }) public readonly chartId: number | null;
  @apiBase({ key: 'email' }) public readonly email: string | null;
  @apiBase({ key: 'gender' }) public readonly gender: GenderEnum | null;
  @apiBase({ key: 'name' }) public readonly name: ClientName;
  @apiBase({ key: 'profileImage' }) public readonly profileImage: string | null;
  @apiBase({ key: 'timeZone' }) public readonly timeZone: TimeZone | null;

  // Computed properties based on core model data.
  public readonly fullName: string;

  /**
   * Deserializes a Client User Base object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client User Base object.
   * @throws An error if the value is not a valid Client User Base object.
   */
  public static async deserialize(
    value: NonNullable<ClientBaseApi>,
  ): Promise<ClientBase> {
    const decoded = decode(ClientBase.BaseCodec, value);
    const decodedTimeZone = decode<TimeZone | null>(
      TimeZonesCodec,
      decoded.timeZone,
      false,
    );

    return new ClientBase({
      birthdate: decoded.dateOfBirth
        ? new Date(DateTime.fromISO(decoded.dateOfBirth).toFormat('MM/dd/yyyy'))
        : null,
      chartId: decoded.chartId,
      email: decoded.email,
      gender: decoded.gender,
      name: ClientName.deserialize(decoded.name),
      profileImage: decoded.profileImage,
      timeZone: decodedTimeZone,
    });
  }
}

export class Client extends ClientBase {
  public constructor(props: ClassProperties<ClientArgs>) {
    super(props);

    this.address = props.address;
    this.admissionDateTime = props.admissionDateTime;
    this.allergies = props.allergies;
    this.beds = props.beds;
    this.codeStatus = props.codeStatus;
    this.dischargeDateTime = props.dischargeDateTime;
    this.externalId = props.externalId;
    this.facility = props.facility;
    this.id = props.id;
    this.identifiesAs = props.identifiesAs;
    this.intakesCompleted = props.intakesCompleted;
    this.isClient = props.isClient;
    this.levelsOfCare = props.levelsOfCare;
    this.luin = props.luin;
    this.medicaid = props.medicaid;
    this.medicalRecordId = props.medicalRecordId;
    this.phone = props.phone;
    this.primaryLanguage = props.primaryLanguage;
    this.pronouns = props.pronouns;
    this.race = props.race;
    this.religion = props.religion;
    this.socialSecurityNumber = props.socialSecurityNumber;
    this.status = props.status;

    // Computed properties based on core model data.
    this.activeBed = this.beds?.filter((bed) => bed.isActiveBed)[0] ?? null;
    this.age = this.birthdate
      ? Math.floor(
          DateTime.local().diff(DateTime.fromJSDate(this.birthdate), 'years')
            .years,
        )
      : null;
    this.allergyNames = props.allergies?.map((allergy) => allergy.name) ?? null;
    this.levelOfCareNames = props.levelsOfCare.map(({ name }) => name);
    this.phoneDisplay =
      this.phone.mobile ||
      this.phone.home ||
      this.phone.office ||
      this.phone.other ||
      'N/A';
  }

  /**
   * The io-ts codec for runtime type checking of the Client API model.
   */
  public static readonly Codec = io.type(
    {
      address: Address.Codec,
      admissionDate: io.union([io.string, io.null, io.undefined]),
      allergies: io.union([io.array(Allergen.Codec), io.null]),
      beds: io.union([io.array(ClientBed.Codec), io.null]),
      chartId: io.union([io.number, io.null]),
      codeStatus: io.union([CodeStatus.Codec, io.null]),
      dateOfBirth: io.union([io.string, io.null]),
      dischargeDate: io.union([io.string, io.null, io.undefined]),
      email: io.union([io.string, io.null]),
      externalId: io.union([io.string, io.null]),
      facility: Facility.Codec,
      gender: io.union([
        io.literal(GenderEnum.FEMALE),
        io.literal(GenderEnum.GENDER_VARIANT_NON_CONFORMING),
        io.literal(GenderEnum.MALE),
        io.literal(GenderEnum.NON_BINARY),
        io.literal(GenderEnum.NO_PREFERRENCE),
        io.literal(GenderEnum.PREFFER_NOT_TO_ANSWER),
        io.literal(GenderEnum.TRANSGENDER),
        io.null,
      ]),
      id: io.number,
      identifiesAs: io.union([io.string, io.null]),
      intakesCompleted: ClientIntakesCompleted.Codec,
      isClient: io.boolean,
      levelOfCare: io.union([io.array(ClientLevelOfCare.Codec), io.null]),
      luin: io.union([io.string, io.null]),
      medicaid: io.union([io.string, io.null]),
      mrn: io.union([io.string, io.null]),
      name: ClientName.Codec,
      phone: Phone.Codec,
      primaryLanguage: io.union([
        io.literal(LanguageEnum.ARABIC),
        io.literal(LanguageEnum.ASSYRIAN),
        io.literal(LanguageEnum.CAMBODIAN),
        io.literal(LanguageEnum.CHINESE_CANTONESE),
        io.literal(LanguageEnum.CHINESE_MANDARIN),
        io.literal(LanguageEnum.ENGLISH),
        io.literal(LanguageEnum.FARSI),
        io.literal(LanguageEnum.FIJIAN),
        io.literal(LanguageEnum.FRENCH),
        io.literal(LanguageEnum.GERMAN),
        io.literal(LanguageEnum.GREEK),
        io.literal(LanguageEnum.HEBREW),
        io.literal(LanguageEnum.HINDI),
        io.literal(LanguageEnum.ITALIAN),
        io.literal(LanguageEnum.JAPANESE),
        io.literal(LanguageEnum.KOREAN),
        io.literal(LanguageEnum.LAOTIAN),
        io.literal(LanguageEnum.MICRONESSIAN),
        io.literal(LanguageEnum.PASHTUN),
        io.literal(LanguageEnum.PERSIAN),
        io.literal(LanguageEnum.POLISH),
        io.literal(LanguageEnum.ROMANIAN),
        io.literal(LanguageEnum.RUSSIAN),
        io.literal(LanguageEnum.SAMOAN),
        io.literal(LanguageEnum.SPANISH),
        io.literal(LanguageEnum.TAGALOG),
        io.literal(LanguageEnum.THAI),
        io.literal(LanguageEnum.UKRAINIAN),
        io.literal(LanguageEnum.VIETNAMESE),
        io.null,
      ]),
      profileImage: io.union([io.string, io.null]),
      pronouns: io.union([Pronouns.Codec, io.null]),
      race: io.union([
        io.literal(RaceEnum.AMERICAN_INDIAN_OR_ALASKA_NATIVE),
        io.literal(RaceEnum.ASIAN),
        io.literal(RaceEnum.BLACK_OR_AFRICAN_AMERICAN),
        io.literal(RaceEnum.DECLINED_TO_STATE),
        io.literal(RaceEnum.HISPANIC_OR_LATINO),
        io.literal(RaceEnum.MULTIRACIAL_TWO_OR_MORE_RACES),
        io.literal(RaceEnum.NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER),
        io.literal(RaceEnum.SOME_OTHER_RACE),
        io.literal(RaceEnum.WHITE),
        io.null,
      ]),
      religion: io.union([Religion.Codec, io.null]),
      ssn: io.union([io.string, io.null]),
      status: NameId.Codec,
      timeZone: io.union([io.string, io.null]),
    },
    'ClientApi',
  );

  @api({ key: 'address' }) public readonly address: Address;
  @api({ key: 'admissionDate' })
  public readonly admissionDateTime: DateTime | null;
  @api({ key: 'allergies' }) public readonly allergies:
    | readonly Allergen[]
    | null;
  @api({ key: 'beds' }) public readonly beds: readonly ClientBed[] | null;
  @api({ key: 'codeStatus' }) public readonly codeStatus: CodeStatus | null;
  @api({ key: 'dischargeDate' })
  public readonly dischargeDateTime: DateTime | null;
  @api({ key: 'externalId' }) public readonly externalId: string | null;
  @api({ key: 'facility' }) public readonly facility: Facility;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'identifiesAs' }) public readonly identifiesAs: string | null;
  @api({ key: 'intakesCompleted' })
  public readonly intakesCompleted: ClientIntakesCompleted;
  @api({ key: 'isClient' }) public readonly isClient: boolean;
  @api({ key: 'levelOfCare' })
  public readonly levelsOfCare: readonly ClientLevelOfCare[];
  @api({ key: 'luin' }) public readonly luin: string | null;
  @api({ key: 'medicaid' }) public readonly medicaid: string | null;
  @api({ key: 'mrn' }) public readonly medicalRecordId: string | null;
  @api({ key: 'phone' }) public readonly phone: Phone;
  @api({ key: 'primaryLanguage' })
  public readonly primaryLanguage: LanguageEnum | null;
  @api({ key: 'pronouns' }) public readonly pronouns: Pronouns | null;
  @api({ key: 'race' }) public readonly race: RaceEnum | null;
  @api({ key: 'religion' }) public readonly religion: Religion | null;
  @api({ key: 'ssn' }) public readonly socialSecurityNumber: string | null;
  @api({ key: 'status' }) public readonly status: NameId;

  // Computed properties based on core model data.
  public readonly activeBed: ClientBed | null;
  public readonly age: number | null;
  public readonly allergyNames: string[] | null;
  public readonly levelOfCareNames: string[] | null;
  public readonly phoneDisplay: string;

  /**
   * Deserializes a Client object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client object.
   * @throws An error if the value is not a valid Client object.
   */
  public static override async deserialize(
    value: NonNullable<ClientApi>,
  ): Promise<Client> {
    const decoded = decode(Client.Codec, value);
    const decodedTimeZone = decode<TimeZone | null>(
      TimeZonesCodec,
      decoded.timeZone,
      false,
    );

    return new Client({
      address: Address.deserialize(decoded.address),
      allergies: decoded.allergies
        ? Allergen.deserializeList(decoded.allergies)
        : null,
      admissionDateTime: decoded.admissionDate
        ? await DateTime.fromISO(decoded.admissionDate).toCurrentFacilityTime()
        : null,
      beds: decoded.beds ? await ClientBed.deserializeList(decoded.beds) : null,
      codeStatus: decoded.codeStatus,
      birthdate: decoded.dateOfBirth
        ? new Date(DateTime.fromISO(decoded.dateOfBirth).toFormat('MM/dd/yyyy'))
        : null,
      chartId: decoded.chartId,
      dischargeDateTime: decoded.dischargeDate
        ? await DateTime.fromISO(decoded.dischargeDate).toCurrentFacilityTime()
        : null,
      email: decoded.email,
      externalId: decoded.externalId,
      facility: Facility.deserialize(decoded.facility),
      gender: decoded.gender,
      id: decoded.id,
      identifiesAs: decoded.identifiesAs,
      intakesCompleted: ClientIntakesCompleted.deserialize(
        decoded.intakesCompleted,
      ),
      isClient: decoded.isClient,
      levelsOfCare: decoded.levelOfCare
        ? ClientLevelOfCare.deserializeList(decoded.levelOfCare)
        : [],
      luin: decoded.luin,
      medicalRecordId: decoded.mrn,
      name: ClientName.deserialize(decoded.name),
      medicaid: decoded.medicaid,
      phone: Phone.deserialize(decoded.phone),
      primaryLanguage: decoded.primaryLanguage,
      profileImage: decoded.profileImage,
      pronouns: decoded.pronouns
        ? Pronouns.deserialize(decoded.pronouns)
        : null,
      race: decoded.race,
      religion: decoded.religion
        ? Religion.deserialize(decoded.religion)
        : null,
      socialSecurityNumber: decoded.ssn,
      status: NameId.deserialize(decoded.status),
      timeZone: decodedTimeZone,
    });
  }

  /**
   * Deserializes a list of Client objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<ClientApi>>,
  ): Promise<readonly Client[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client objects.');
    }
    return Promise.all(values.map(Client.deserialize));
  }

  /**
   * Determine if the item is an instance of the model.
   *
   * @param item The item to check the instance of.
   * @returns True if the item is an instance of the model, otherwise false.
   */
  public static isInstanceFrom(item: unknown): item is Client {
    if (item instanceof Client) {
      // Item is an instance of the model.
      return true;
    } else if (!item || !(typeof item === 'object')) {
      // Is not an object or is null.
      return false;
    }
    // Check if all keys are present in the item.
    return modelPropertyKeys.every((property) => property in item);
  }

  /**
   * A pure function that returns the unique identifier for a Client. This is
   * specifically used for the `trackBy` function in Angular's `ngFor` directive
   * to improve rendering performance when a list of Clients is rendered.
   */
  public static trackBy(_index: number, client: Client): number {
    return client.id;
  }
}

export class ClientUpdate extends Client {
  public constructor(props: ClassProperties<ClientArgs>) {
    super(props);
  }

  /** Serialize the data to a format that the API accepts. */
  public serialize(): ClientUpdateApi {
    return {
      address: this.address,
      admissionDate: this.admissionDateTime?.toISO(),
      allergies: this.allergies,
      beds:
        this.beds?.map((bed) => ({
          ...bed,
          endDate: bed.endDate.toISO()!,
          startDate: bed.startDate.toISO()!,
        })) ?? null,
      chartId: this.chartId,
      codeStatus: this.codeStatus,
      dateOfBirth: this.birthdate ? this.birthdate.toISOString() : null,
      dischargeDate: this.dischargeDateTime?.toISO() ?? null,
      email: this.email,
      facility: {
        ...this.facility,
        settings: {
          ...this.facility.settings,
          defaultUnits: this.facility.settings.measurementSystem,
        },
      },
      gender: this.gender,
      id: this.id,
      identifiesAs: this.identifiesAs,
      intakesCompleted: this.intakesCompleted,
      isClient: this.isClient,
      levelOfCare: this.levelsOfCare,
      luin: this.luin,
      medicaid: this.medicaid,
      mrn: this.medicalRecordId,
      name: this.name,
      phone: this.phone,
      primaryLanguage: this.primaryLanguage,
      profileImage: this.profileImage,
      pronouns: this.pronouns,
      race: this.race,
      religion: this.religion,
      ssn: this.socialSecurityNumber,
      status: this.status,
      timeZone: this.timeZone,
    };
  }
}

const modelPropertyKeys: ReadonlyArray<keyof Client> = [
  'activeBed',
  'address',
  'admissionDateTime',
  'age',
  'allergies',
  'allergyNames',
  'beds',
  'birthdate',
  'chartId',
  'codeStatus',
  'dischargeDateTime',
  'email',
  'externalId',
  'facility',
  'gender',
  'id',
  'identifiesAs',
  'intakesCompleted',
  'isClient',
  'levelOfCareNames',
  'levelsOfCare',
  'luin',
  'medicaid',
  'medicalRecordId',
  'name',
  'phone',
  'phoneDisplay',
  'primaryLanguage',
  'profileImage',
  'pronouns',
  'race',
  'religion',
  'socialSecurityNumber',
  'status',
  'timeZone',
];
