import { ReviewerApi, ReviewerUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { Name, NameUpdate } from 'src/app/models/user/name.model';
import { Role, RoleUpdate } from 'src/app/models/user/role.model';
import {
  UserLicense,
  UserLicenseUpdate,
} from 'src/app/models/user/user-license.model';
import { UserBase } from 'src/app/models/user/user.model';

const api = apiDecorator<ReviewerApi>();

type ReviewerArgs = Omit<
  Reviewer,
  // Omit computed properties set on construct from outside data.
  'fullName'
>;

export class Reviewer extends UserBase {
  public constructor(props: ClassProperties<ReviewerArgs>) {
    super(props);

    this.isDoctor = props.isDoctor;
    this.role = props.role;
  }

  /**
   * The io-ts codec for runtime type checking of the Reviewer API model.
   */
  public static readonly Codec = io.type(
    {
      active: io.boolean,
      doctor: io.boolean,
      id: io.number,
      image: io.union([io.string, io.null]),
      licenses: io.union([io.array(UserLicense.Codec), io.null]),
      name: io.union([Name.Codec, io.null]),
      npi: io.union([io.string, io.null]),
      phone: io.union([io.string, io.null]),
      role: io.union([Role.Codec, io.null]),
      supervisor: io.boolean,
    },
    'ReviewerApi',
  );

  @api({ key: 'doctor' }) public readonly isDoctor: boolean;
  @api({ key: 'role' }) public readonly role: Role | null;

  /**
   * Deserializes a Reviewer object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Reviewer object.
   * @throws An error if the value is not a valid Reviewer object.
   */
  public static override deserialize(
    value: NonNullable<ReviewerApi>,
  ): Reviewer {
    const decoded = decode(Reviewer.Codec, value);
    return new Reviewer({
      ...decoded,
      firstName: decoded.name?.first ?? null,
      isDoctor: decoded.doctor,
      isSupervisor: decoded.supervisor,
      lastName: decoded.name?.last ?? null,
      licenses: decoded.licenses
        ? UserLicense.deserializeList(decoded.licenses)
        : null,
      name: decoded.name ? Name.deserialize(decoded.name) : null,
      npi: decoded.npi,
      role: decoded.role ? Role.deserialize(decoded.role) : null,
    });
  }

  /**
   * Deserializes a list of Reviewer objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Reviewer objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Reviewer objects.
   */
  public static override deserializeList(
    values: ReadonlyArray<NonNullable<ReviewerApi>>,
  ): readonly Reviewer[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Reviewer objects.');
    }
    return values.map(Reviewer.deserialize);
  }
}

export class ReviewerUpdate extends Reviewer {
  public constructor(props: ClassProperties<ReviewerUpdate>) {
    super(props);
  }

  public serialize(): ReviewerUpdateApi {
    return {
      active: this.active,
      doctor: this.isDoctor,
      image: this.image ?? null,
      licenses:
        this.licenses?.map((license) =>
          new UserLicenseUpdate(license).serialize(),
        ) ?? null,
      name: this.name ? new NameUpdate(this.name).serialize() : null,
      phone: this.phone ?? null,
      role: this.role ? new RoleUpdate(this.role).serialize() : null,
      npi: this.npi,
      supervisor: this.isSupervisor,
    };
  }
}
