import { ClientCOWSReviewerApi, ClientCOWSReviewerUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { Reviewer } from 'src/app/models/reviewer.model';

const api = apiDecorator<ClientCOWSReviewerApi>();

abstract class ClientCOWSReviewerBase {
  public constructor(props: ClassProperties<ClientCOWSReviewerBase>) {
    this.date = props.date;
    this.signature = props.signature;
  }

  @api({ key: 'date' }) public readonly date: DateTime | null;
  @api({ key: 'signature' }) public readonly signature: Base64<'png'> | null;
}

export class ClientCOWSReviewer extends ClientCOWSReviewerBase {
  public constructor(props: ClassProperties<ClientCOWSReviewer>) {
    super(props);

    this.by = props.by;
  }

  /**
   * The io-ts codec for runtime type checking of the Client COWS Reviewer API
   * model.
   */
  public static readonly Codec = io.type(
    {
      by: io.union([Reviewer.Codec, io.undefined]),
      date: io.union([io.string, io.null]),
      signature: io.union([io.string, io.null]),
    },
    'ClientCOWSReviewerApi',
  );

  @api({ key: 'by' }) public readonly by: Reviewer | null;

  /**
   * Deserializes a Client COWS Reviewer object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client COWS Reviewer object.
   * @throws An error if the value is not a valid Client COWS Reviewer object.
   */
  public static async deserialize(
    value: NonNullable<ClientCOWSReviewerApi>,
  ): Promise<ClientCOWSReviewer> {
    const decoded = decode(ClientCOWSReviewer.Codec, value);
    return new ClientCOWSReviewer({
      by: decoded.by ? Reviewer.deserialize(decoded.by) : null,
      date: decoded.date
        ? await DateTime.fromISO(decoded.date).toCurrentFacilityTime()
        : null,
      signature: decoded.signature
        ? (decoded.signature as Base64<'png'>)
        : null,
    });
  }

  /**
   * Deserializes a list of Client COWS Reviewer objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client COWS Reviewer objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client COWS Reviewer
   * objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<ClientCOWSReviewerApi>>,
  ): Promise<readonly ClientCOWSReviewer[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client COWS Reviewer objects.');
    }
    return Promise.all(values.map(ClientCOWSReviewer.deserialize));
  }
}

export class ClientCOWSReviewerUpdate extends ClientCOWSReviewerBase {
  public constructor(props: ClassProperties<ClientCOWSReviewerUpdate>) {
    super(props);

    this.by = props.by;
  }

  @api({ key: 'by' }) public readonly by: { id: Reviewer['id'] };

  /**
   * Serializes a Client COWS Reviewer object to an API model.
   *
   * @returns The serialized Client COWS Reviewer object.
   */
  public serialize(): ClientCOWSReviewerUpdateApi {
    return {
      by: this.by,
      date: this.date?.toISO() ?? null,
      signature: this.signature ?? null,
    };
  }
}
