import { LabResultApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { FileAttachment } from 'src/app/models/core/file-attachment.model';

const api = apiDecorator<LabResultApi>();

export class LabResult {
  public constructor(props: ClassProperties<LabResult>) {
    this.attachments = props.attachments;
    this.date = props.date;
    this.id = props.id;
    this.labName = props.labName;
    this.physicianName = props.physicianName;
    this.receivedDate = props.receivedDate;
    this.specimenNumber = props.specimenNumber;
  }

  /**
   * The io-ts codec for runtime type checking of the Lab Result Api model.
   */
  public static readonly Codec = io.type(
    {
      attachments: io.union([io.array(FileAttachment.Codec), io.null]),
      date: io.string,
      id: io.number,
      labName: io.string,
      physicianName: io.string,
      receivedDate: io.union([io.string, io.null]),
      specimenNumber: io.string,
    },
    'LabResultApi',
  );

  @api({ key: 'attachments' }) public readonly attachments:
    | readonly FileAttachment[]
    | null;
  @api({ key: 'date' }) public readonly date: DateTime;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'labName' }) public readonly labName: string;
  @api({ key: 'physicianName' }) public readonly physicianName: string;
  @api({ key: 'receivedDate' }) public readonly receivedDate: DateTime | null;
  @api({ key: 'specimenNumber' }) public readonly specimenNumber: string;

  /**
   * Deserializes a Lab Result object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Lab Result object.
   * @throws An error if the value is not a valid Lab Result object.
   */
  public static async deserialize(
    value: NonNullable<LabResultApi>,
  ): Promise<LabResult> {
    const decoded = decode(LabResult.Codec, value);
    return new LabResult({
      attachments: decoded.attachments
        ? FileAttachment.deserializeList(decoded.attachments)
        : null,
      date: await DateTime.fromISO(decoded.date).toCurrentFacilityTime(),
      id: decoded.id,
      labName: decoded.labName,
      physicianName: decoded.physicianName,
      receivedDate: decoded.receivedDate
        ? await DateTime.fromISO(decoded.receivedDate).toCurrentFacilityTime()
        : null,
      specimenNumber: decoded.specimenNumber,
    });
  }

  /**
   * Deserializes a list of Lab Result objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Lab Result objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Lab Result objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<LabResultApi>>,
  ): Promise<readonly LabResult[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Lab Result objects.');
    }
    return Promise.all(values.map(LabResult.deserialize));
  }
}
