import { ClientCIWAReviewerApi, ClientCIWAReviewerUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { Reviewer } from 'src/app/models/reviewer.model';

const api = apiDecorator<ClientCIWAReviewerApi>();

abstract class ClientCIWAReviewerBase {
  public constructor(props: ClassProperties<ClientCIWAReviewerBase>) {
    this.date = props.date;
    this.signature = props.signature;
  }

  @api({ key: 'date' }) public readonly date: DateTime | null;
  @api({ key: 'signature' }) public readonly signature: Base64<'png'> | null;
}

export class ClientCIWAReviewer extends ClientCIWAReviewerBase {
  public constructor(props: ClassProperties<ClientCIWAReviewer>) {
    super(props);

    this.by = props.by;
  }

  /**
   * The io-ts codec for runtime type checking of the Client CIWA Reviewer API
   * model.
   */
  public static readonly Codec = io.type(
    {
      by: io.union([Reviewer.Codec, io.undefined]),
      date: io.union([io.string, io.null]),
      signature: io.union([io.string, io.null]),
    },
    'ClientCIWAReviewerApi',
  );

  @api({ key: 'by' }) public readonly by: Reviewer | null;

  /**
   * Deserializes a Client CIWA Reviewer object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client CIWA Reviewer object.
   * @throws An error if the value is not a valid Client CIWA Reviewer object.
   */
  public static async deserialize(
    value: NonNullable<ClientCIWAReviewerApi>,
  ): Promise<ClientCIWAReviewer> {
    const decoded = decode(ClientCIWAReviewer.Codec, value);
    return new ClientCIWAReviewer({
      by: decoded.by ? Reviewer.deserialize(decoded.by) : null,
      date: decoded.date
        ? await DateTime.fromISO(decoded.date).toCurrentFacilityTime()
        : null,
      signature: decoded.signature
        ? (decoded.signature as Base64<'png'>)
        : null,
    });
  }

  /**
   * Deserializes a list of Client CIWA Reviewer objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client CIWA Reviewer objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client CIWA Reviewer
   * objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<ClientCIWAReviewerApi>>,
  ): Promise<readonly ClientCIWAReviewer[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client CIWA Reviewer objects.');
    }
    return Promise.all(values.map(ClientCIWAReviewer.deserialize));
  }
}

export class ClientCIWAReviewerUpdate extends ClientCIWAReviewerBase {
  public constructor(props: ClassProperties<ClientCIWAReviewerUpdate>) {
    super(props);

    this.by = props.by;
  }

  @api({ key: 'by' }) public readonly by: { id: Reviewer['id'] };

  /**
   * Serializes a Client CIWA Reviewer object to an API model.
   *
   * @returns The serialized Client CIWA Reviewer object.
   */
  public serialize(): ClientCIWAReviewerUpdateApi {
    return {
      by: this.by,
      date: this.date?.toISO() ?? null,
      signature: this.signature ?? null,
    };
  }
}
