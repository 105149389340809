import {
  FamilyMessageApi,
  FamilyMessageDetailsApi,
  MessageRecipientApi,
} from 'api/models';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { SortOrderEnum } from 'src/app/enumerators';
import {
  Client,
  FamilyMessage,
  FamilyMessageDetails,
  FamilyMessageUpdate,
  MessageCounts,
  MessageRecipient,
} from 'src/app/models';
import { parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the family messages API.
 */
@Injectable()
export class FamilyMessagesService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Get family message details for a specific client and family message.
   *
   * @param clientId The id of the client to fetch family message details for.
   * @param messageId The id of the family message to fetch details for.
   * @returns The family message details on success, undefined on error.
   */
  public getDetails(
    clientId: Client['id'],
    messageId: FamilyMessage['id'],
  ): Observable<FamilyMessageDetails | undefined> {
    return this.httpClient
      .get<
        FamilyMessageDetailsApi | undefined
      >(`${config.api.url}/clients/${clientId}/family/messages/${messageId}`)
      .pipe(
        switchMap((response) =>
          response ? FamilyMessageDetails.deserialize(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch and return all family messages data by client id.
   *
   * @param clientId The id of the client to fetch family messages for.
   * @param requestParameters Optional request parameters.
   * @returns All family messages data on success, undefined on error.
   */
  public getAll(
    clientId: Client['id'],
    requestParameters?: readonly FamilyMessageRequestParam[],
  ): Observable<readonly FamilyMessage[] | undefined> {
    const params = parseHttpParams(requestParameters);
    return this.httpClient
      .get<
        readonly FamilyMessageApi[] | undefined
      >(`${config.api.url}/clients/${clientId}/family/messages`, { params })
      .pipe(
        switchMap((response) =>
          response ? FamilyMessage.deserializeList(response) : of([]),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Get the family message counts for a client.
   *
   * @param clientId The id of the client to fetch family message counts for.
   * @returns The family message counts on success, undefined on error.
   */
  public getCounts(
    clientId: Client['id'],
  ): Observable<MessageCounts | undefined> {
    return this.httpClient
      .get<MessageCounts>(
        `${config.api.url}/clients/${clientId}/family/messages/count`,
      )
      .pipe(
        map((response) => MessageCounts.deserialize(response)),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch and return all family message recipients by client id.
   *
   * @param clientId The id of the client to fetch family message recipients for.
   * @returns All family message recipients on success, undefined on error.
   */
  public getRecipients(
    clientId: Client['id'],
  ): Observable<readonly MessageRecipient[] | undefined> {
    return this.httpClient
      .get<
        readonly MessageRecipientApi[] | undefined
      >(`${config.api.url}/clients/${clientId}/family/messages/recipients`)
      .pipe(
        map((response) =>
          response ? MessageRecipient.deserializeList(response) : [],
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Create and send a message.
   *
   * @param messageRecipientId The id of the message recipient.
   * @param familyMessageUpdate The family message update object.
   * @param attachments The optional attachments to send with the message.
   * @returns The newly created family message on success, null on error.
   */
  public create(
    messageRecipientId: MessageRecipient['id'],
    familyMessageUpdate: FamilyMessageUpdate,
    attachments?: readonly File[] | null | undefined,
  ): Observable<FamilyMessage | undefined> {
    let payload: FormData | null = null;
    let url = `${config.api.url}/clients/${messageRecipientId}/family/messages`;

    if (attachments && attachments.length > 0) {
      payload = new FormData();
      url = `${config.api.url}/clients/${messageRecipientId}/family/messages/attachments`;

      for (const attachment of attachments) {
        payload.append('attachments', attachment);
      }

      payload.append('message', JSON.stringify(familyMessageUpdate));
    }

    return (
      this.httpClient
        /** @todo - Update API to only return a single object here. */
        .post<readonly FamilyMessageApi[] | undefined>(
          url,
          payload ? payload : familyMessageUpdate.serialize(),
        )
        .pipe(
          switchMap((response) =>
            response && response[0]
              ? FamilyMessage.deserialize(response[0])
              : of(undefined),
          ),
          catchError((error: unknown) => {
            console.error(error);
            return of(undefined);
          }),
        )
    );
  }

  /**
   * Delete a family message.
   *
   * @param messageId The id of the message to delete.
   * @param clientId The id of the client to delete the message for.
   * @returns True on success, false on error.
   */
  public delete(
    messageId: number,
    clientId: Client['id'],
  ): Observable<boolean> {
    return this.httpClient
      .delete<
        boolean | undefined
      >(`${config.api.url}/clients/${clientId}/family/messages/${messageId}`)
      .pipe(
        map((response) => response ?? false),
        catchError((error: unknown) => {
          console.error(error);
          return of(false);
        }),
      );
  }
}

/**
 * Request parameter interface for use with the Family Messages API.
 */
interface FamilyMessageRequestParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'orderby' | 'sort';
  /** The value to use for the query parameter. */
  value: keyof FamilyMessage | SortOrderEnum;
}
