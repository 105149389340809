import { DailyQuestionnaireResultsApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { DailyQuestionnaireScore } from 'src/app/models/daily-questionnaire/daily-questionnaire-score.model';

const api = apiDecorator<DailyQuestionnaireResultsApi>();

export class DailyQuestionnaireResults {
  public constructor(props: ClassProperties<DailyQuestionnaireResults>) {
    this.day1 = props.day1;
    this.day2 = props.day2;
    this.day3 = props.day3;
    this.day4 = props.day4;
    this.day5 = props.day5;
    this.day6 = props.day6;
    this.day7 = props.day7;
    this.groupId = props.groupId;
    this.groupName = props.groupName;
  }

  /**
   * The io-ts codec for runtime type checking of the Daily Questionnaire
   * Results API model.
   */
  public static readonly Codec = io.type(
    {
      day1: DailyQuestionnaireScore.Codec,
      day2: DailyQuestionnaireScore.Codec,
      day3: DailyQuestionnaireScore.Codec,
      day4: DailyQuestionnaireScore.Codec,
      day5: DailyQuestionnaireScore.Codec,
      day6: DailyQuestionnaireScore.Codec,
      day7: DailyQuestionnaireScore.Codec,
      groupId: io.number,
      groupName: io.union([io.string, io.null]),
    },
    'DailyQuestionnaireResultsApi',
  );

  @api({ key: 'day1' }) public readonly day1: DailyQuestionnaireScore;
  @api({ key: 'day2' }) public readonly day2: DailyQuestionnaireScore;
  @api({ key: 'day3' }) public readonly day3: DailyQuestionnaireScore;
  @api({ key: 'day4' }) public readonly day4: DailyQuestionnaireScore;
  @api({ key: 'day5' }) public readonly day5: DailyQuestionnaireScore;
  @api({ key: 'day6' }) public readonly day6: DailyQuestionnaireScore;
  @api({ key: 'day7' }) public readonly day7: DailyQuestionnaireScore;
  @api({ key: 'groupId' }) public readonly groupId: number;
  @api({ key: 'groupName' }) public readonly groupName: string | null;

  /**
   * Deserializes a Daily Questionnaire Results object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Daily Questionnaire Results object.
   * @throws An error if the value is not a valid Daily Questionnaire Results
   * object.
   */
  public static async deserialize(
    value: NonNullable<DailyQuestionnaireResultsApi>,
  ): Promise<DailyQuestionnaireResults> {
    const decoded = decode(DailyQuestionnaireResults.Codec, value);
    return new DailyQuestionnaireResults({
      day1: await DailyQuestionnaireScore.deserialize(decoded.day1),
      day2: await DailyQuestionnaireScore.deserialize(decoded.day2),
      day3: await DailyQuestionnaireScore.deserialize(decoded.day3),
      day4: await DailyQuestionnaireScore.deserialize(decoded.day4),
      day5: await DailyQuestionnaireScore.deserialize(decoded.day5),
      day6: await DailyQuestionnaireScore.deserialize(decoded.day6),
      day7: await DailyQuestionnaireScore.deserialize(decoded.day7),
      groupId: decoded.groupId,
      groupName: decoded.groupName,
    });
  }

  /**
   * Deserializes a list of Daily Questionnaire Results objects from the API
   * model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Daily Questionnaire Results objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Daily Questionnaire
   * Results objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<DailyQuestionnaireResultsApi>>,
  ): Promise<readonly DailyQuestionnaireResults[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Daily Questionnaire Results objects.');
    }
    return Promise.all(values.map(DailyQuestionnaireResults.deserialize));
  }
}
