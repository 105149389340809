import {
  BillingCodeModifierApi,
  BillingCodeModifierCreateApi,
  BillingCodeModifierUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<BillingCodeModifierApi>();

abstract class BillingCodeModifierBase {
  public constructor(props: ClassProperties<BillingCodeModifierBase>) {
    this.code = props.code;
    this.description = props.description;
    this.isActive = props.isActive;
  }

  @api({ key: 'code' }) public readonly code: string;
  @api({ key: 'description' }) public readonly description: string | null;
  @api({ key: 'isActive' }) public readonly isActive: boolean;
}

export class BillingCodeModifier extends BillingCodeModifierBase {
  public constructor(props: ClassProperties<BillingCodeModifier>) {
    super(props);

    this.id = props.id;
    this.isCustom = props.isCustom;
  }

  /**
   * The io-ts codec for runtime type checking of the Billing Code Modifiers
   * API model.
   */
  public static readonly Codec = io.type(
    {
      code: io.string,
      description: io.union([io.string, io.null]),
      id: io.number,
      isActive: io.boolean,
      isCustom: io.boolean,
    },
    'BillingCodeModifierApi',
  );

  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'isCustom' }) public readonly isCustom: boolean;

  /**
   * Deserializes a Billing Code Modifiers object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Billing Code Modifiers object.
   * @throws An error if the value is not a valid Billing Code
   * Modifiers object.
   */
  public static deserialize(
    value: NonNullable<BillingCodeModifierApi>,
  ): BillingCodeModifier {
    const decoded = decode(BillingCodeModifier.Codec, value);
    return new BillingCodeModifier(decoded);
  }

  /**
   * Deserializes a list of Billing Code Modifiers objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Billing Code  Modifiers objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Billing Code Modifiers objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<BillingCodeModifierApi>>,
  ): readonly BillingCodeModifier[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Billing Code Modifiers objects.');
    }
    return values.map(BillingCodeModifier.deserialize);
  }
}

/** The billing Code Modifier create (POST) model. */
export class BillingCodeModifierCreate extends BillingCodeModifierBase {
  public serialize(): BillingCodeModifierCreateApi {
    return {
      code: this.code,
      description: this.description,
      isActive: this.isActive,
    };
  }
}

/** The Billing Code Modifier update (PUT) model. */
export class BillingCodeModifierUpdate extends BillingCodeModifier {
  public serialize(): BillingCodeModifierUpdateApi {
    return {
      code: this.code,
      description: this.description,
      id: this.id,
      isActive: this.isActive,
      isCustom: this.isCustom,
    };
  }
}
