import { FamilyMessageApi, FamilyMessageUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode, getFullName } from 'src/app/utilities';

import { ClientName } from 'src/app/models/client/client-name.model';
import { Name } from 'src/app/models/user/name.model';

const api = apiDecorator<FamilyMessageApi>();
const apiUpdate = apiDecorator<FamilyMessageUpdateApi>();

type FamilyMessageArgs = Omit<
  FamilyMessage & FamilyMessageBaseArgs,
  // Omit computed properties set on construct from outside data.
  'toClientFullName' | 'fromClientFullName' | 'fromUserFullName'
>;

export type FamilyMessageBaseArgs = Omit<
  FamilyMessageBase,
  // Omit computed properties set on construct from outside data.
  'toClientFullName' | 'fromClientFullName' | 'fromUserFullName'
>;

export abstract class FamilyMessageBase {
  public constructor(props: ClassProperties<FamilyMessageBaseArgs>) {
    this.attachmentsCount = props.attachmentsCount;
    this.dateTime = props.dateTime;
    this.fromClient = props.fromClient;
    this.fromFacility = props.fromFacility;
    this.fromUser = props.fromUser;
    this.id = props.id;
    this.isDraft = props.isDraft;
    this.isRead = props.isRead;
    this.preview = props.preview;
    this.readBy = props.readBy;
    this.subject = props.subject;
    this.toClient = props.toClient;
    this.toFacility = props.toFacility;

    // Computed values
    this.toClientFullName = getFullName({ ...this.toClient?.name });
    this.fromClientFullName = getFullName({ ...this.fromClient?.name });
    this.fromUserFullName = this.fromUser?.name.fullName || null;
  }

  @api({ key: 'attachments' }) public readonly attachmentsCount: number;
  @api({ key: 'dateTime' }) public readonly dateTime: DateTime;
  @api({ key: 'fromClient' }) public readonly fromClient: {
    readonly id: number;
    readonly name: ClientName;
  } | null;
  @api({ key: 'fromFacility' }) public readonly fromFacility: {
    readonly id: number;
    readonly name: string | null;
  } | null;
  @api({ key: 'fromUser' }) public readonly fromUser: {
    readonly id: number;
    readonly name: Name;
  } | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'draft' }) public readonly isDraft: boolean;
  @api({ key: 'read' }) public readonly isRead: boolean;
  @api({ key: 'preview' }) public readonly preview: string | null;
  @api({ key: 'readBy' }) public readonly readBy: {
    readonly id: number;
    readonly name: Name;
  } | null;
  @api({ key: 'subject' }) public readonly subject: string | null;
  @api({ key: 'toClient' }) public readonly toClient: {
    readonly id: number;
    readonly name: ClientName;
  } | null;
  @api({ key: 'toFacility' }) public readonly toFacility: {
    readonly id: number;
    readonly name: string | null;
  } | null;

  // Computed values
  public readonly toClientFullName: string | null;
  public readonly fromClientFullName: string | null;
  public readonly fromUserFullName: string | null;
}

export class FamilyMessage extends FamilyMessageBase {
  public constructor(props: ClassProperties<FamilyMessageArgs>) {
    super(props);
  }

  /**
   * The io-ts codec for runtime type checking of the Family Message model.
   */
  public static readonly Codec = io.type(
    {
      attachments: io.number,
      dateTime: io.string,
      draft: io.boolean,
      fromClient: io.union([
        io.type({
          id: io.number,
          name: ClientName.Codec,
        }),
        io.null,
      ]),
      fromFacility: io.union([
        io.type({
          id: io.number,
          name: io.string,
        }),
        io.null,
      ]),
      fromUser: io.union([
        io.type({
          id: io.number,
          name: Name.Codec,
        }),
        io.null,
      ]),
      id: io.number,
      preview: io.union([io.string, io.null]),
      read: io.boolean,
      readBy: io.union([
        io.type({
          id: io.number,
          name: Name.Codec,
        }),
        io.null,
      ]),
      subject: io.string,
      toClient: io.union([
        io.type({
          id: io.number,
          name: ClientName.Codec,
        }),
        io.null,
      ]),
      toFacility: io.union([
        io.type({
          id: io.number,
          name: io.string,
        }),
        io.null,
      ]),
    },
    'FamilyMessageApi',
  );

  /**
   * Deserializes a Family Message object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Family Message object.
   * @throws An error if the value is not a valid Family Message object.
   */
  public static async deserialize(
    value: NonNullable<FamilyMessageApi>,
  ): Promise<FamilyMessage> {
    const decoded = decode(FamilyMessage.Codec, value);
    return new FamilyMessage({
      ...decoded,
      attachmentsCount: decoded.attachments,
      dateTime: await DateTime.fromISO(
        decoded.dateTime,
      ).toCurrentFacilityTime(),
      fromClient: decoded.fromClient
        ? {
            id: decoded.fromClient.id,
            name: ClientName.deserialize(decoded.fromClient.name),
          }
        : null,
      fromUser: decoded.fromUser
        ? {
            id: decoded.fromUser.id,
            name: Name.deserialize(decoded.fromUser.name),
          }
        : null,
      isDraft: decoded.draft,
      isRead: decoded.read,
      readBy: decoded.readBy
        ? {
            id: decoded.readBy.id,
            name: Name.deserialize(decoded.readBy.name),
          }
        : null,
      toClient: decoded.toClient
        ? {
            id: decoded.toClient.id,
            name: ClientName.deserialize(decoded.toClient.name),
          }
        : null,
    });
  }

  /**
   * Deserializes a list of Family Message objects from an API
   * model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Family Message objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Family Message
   * objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<FamilyMessageApi>>,
  ): Promise<readonly FamilyMessage[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Family Message objects.');
    }
    return Promise.all(
      values.map((familyMessage) => FamilyMessage.deserialize(familyMessage)),
    );
  }
}

export class FamilyMessageUpdate {
  public constructor(props: ClassProperties<FamilyMessageUpdate>) {
    this.body = props.body;
    this.fromFacility = props.fromFacility;
    this.fromUser = props.fromUser;
    this.subject = props.subject;
    this.toClients = props.toClients;
  }

  @apiUpdate({ key: 'body' }) public readonly body: string;
  @apiUpdate({ key: 'fromFacility' }) public readonly fromFacility: {
    readonly id: number;
  };
  @apiUpdate({ key: 'fromUser' }) public readonly fromUser: {
    readonly id: number;
  };
  @apiUpdate({ key: 'subject' }) public readonly subject: string | null;
  @apiUpdate({ key: 'toClients' }) public readonly toClients: ReadonlyArray<{
    readonly id: number;
  }>;

  public serialize(): FamilyMessageUpdateApi {
    return {
      ...this,
      fromFacility: {
        id: this.fromFacility.id,
      },
      fromUser: {
        id: this.fromUser.id,
      },
      toClients: this.toClients.map((client) => ({
        id: client.id,
      })),
    };
  }
}
