import { UserLicenseApi, UserLicenseUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { LicenseBase } from 'src/app/models/license.model';

const api = apiDecorator<UserLicenseApi>();

export class UserLicense extends LicenseBase {
  public constructor(props: ClassProperties<UserLicense>) {
    super(props);

    this.acronym = props.acronym;
    this.effectiveDate = props.effectiveDate;
    this.expiryDate = props.expiryDate;
    this.id = props.id;
    this.isCurrent = props.isCurrent;
    this.order = props.order;
  }

  /**
   * The io-ts codec for runtime type checking of the User License model.
   */
  public static readonly Codec = io.type(
    {
      acronym: io.union([io.string, io.null]),
      active: io.boolean,
      description: io.union([io.string, io.null]),
      effectiveDate: io.union([io.string, io.null]),
      expiryDate: io.union([io.string, io.null]),
      id: io.number,
      isCurrent: io.boolean,
      name: io.union([io.string, io.null]),
      order: io.number,
    },
    'UserLicenseApi',
  );

  @api({ key: 'acronym' }) public readonly acronym: string | null;
  @api({ key: 'effectiveDate' }) public readonly effectiveDate: DateTime | null;
  @api({ key: 'expiryDate' }) public readonly expiryDate: DateTime | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'isCurrent' }) public readonly isCurrent: boolean;
  @api({ key: 'order' }) public readonly order: number;

  /**
   * Deserializes a User License object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized User License object.
   * @throws An error if the value is not a valid User License object.
   */
  public static deserialize(value: NonNullable<UserLicenseApi>): UserLicense {
    const decoded = decode(UserLicense.Codec, value);
    return new UserLicense({
      acronym: decoded.acronym,
      active: decoded.active,
      description: decoded.description,
      effectiveDate: decoded.effectiveDate
        ? DateTime.fromISO(decoded.effectiveDate)
        : null,
      expiryDate: decoded.expiryDate
        ? DateTime.fromISO(decoded.expiryDate)
        : null,
      id: decoded.id,
      isCurrent: decoded.isCurrent,
      name: decoded.name,
      order: decoded.order,
    });
  }

  /**
   * Deserializes a list of User License objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized User License objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid User License objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<UserLicenseApi>>,
  ): readonly UserLicense[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of User License objects.');
    }
    return values.map(UserLicense.deserialize);
  }
}

export class UserLicenseUpdate extends UserLicense {
  public serialize(): UserLicenseUpdateApi {
    return {
      acronym: this.acronym,
      active: this.active,
      description: this.description,
      effectiveDate: this.effectiveDate ? this.effectiveDate.toISO() : null,
      expiryDate: this.expiryDate ? this.expiryDate.toISO() : null,
      id: this.id,
      isCurrent: this.isCurrent,
      name: this.name,
      order: this.order,
    };
  }
}
