import { ClientAllergyApi } from 'api/models';
import { Observable, catchError, of, switchMap } from 'rxjs';
import {
  Client,
  ClientAllergy,
  ClientAllergyCreate,
  ClientAllergyUpdate,
} from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the allergies API.
 */
@Injectable()
export class ClientAllergiesService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Delete the allergy for the given client ID and allergy ID.
   *
   * @param clientId The client ID to delete the allergy for.
   * @param clientAllergyId The ID of the allergy to delete.
   * @returns True on success, false on error.
   */
  public delete(
    clientId: Client['id'],
    clientAllergyId: ClientAllergy['id'],
  ): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(
        `${config.api.url}/clients/${clientId}/allergies/${clientAllergyId}`,
      )
      .pipe(
        catchError((error: unknown) => {
          console.error(error);
          return of(false);
        }),
      );
  }

  /**
   * Fetch and return data for all Client Allergy resources by client id.
   *
   * @param clientId The client ID to fetch the allergy list for.
   * @returns All Client Allergy resource data on success, undefined on error.
   */
  public getListByClient(
    clientId: Client['id'],
  ): Observable<readonly ClientAllergy[] | undefined> {
    return this.httpClient
      .get<
        readonly ClientAllergyApi[] | undefined
      >(`${config.api.url}/clients/${clientId}/allergies`)
      .pipe(
        switchMap((response) =>
          response ? ClientAllergy.deserializeList(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Update the client allergy for the given client ID and client allergy ID.
   *
   * @param clientId The client ID to patch the allergy for.
   * @param clientAllergyId The client allergy ID to patch.
   * @param clientAllergyUpdate The client allergy update data.
   * @returns The updated client allergy on success, undefined on error.
   */
  public patch(
    clientId: Client['id'],
    clientAllergyUpdate: ClientAllergyUpdate,
  ): Observable<ClientAllergy | undefined> {
    return this.httpClient
      .patch<ClientAllergyApi>(
        `${config.api.url}/clients/${clientId}/allergies/${clientAllergyUpdate.id}`,
        clientAllergyUpdate.serialize(),
      )
      .pipe(
        switchMap((response) => ClientAllergy.deserialize(response)),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Create a new client allergy for the given client ID.
   *
   * @param clientId The client ID to create the allergy for.
   * @param clientAllergyUpdate The client allergy update data.
   * @returns The created client allergy on success, undefined on error.
   */
  public post(
    clientId: Client['id'],
    clientAllergyCreate: ClientAllergyCreate,
  ): Observable<ClientAllergy | undefined> {
    return this.httpClient
      .post<ClientAllergyApi>(
        `${config.api.url}/clients/${clientId}/allergies`,
        clientAllergyCreate.serialize(),
      )
      .pipe(
        switchMap((response) => ClientAllergy.deserialize(response)),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
