import {
  InsuranceCompanyApi,
  InsuranceCompanyCreateApi,
  InsuranceCompanyUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<InsuranceCompanyApi>();

abstract class InsuranceCompanyBase {
  public constructor(props: ClassProperties<InsuranceCompanyBase>) {
    this.isActive = props.isActive;
    this.isCustom = props.isCustom;
    this.isPrivatePay = props.isPrivatePay;
    this.name = props.name;
  }

  @api({ key: 'isActive' }) public readonly isActive: boolean;
  @api({ key: 'isCustom' }) public readonly isCustom: boolean;
  @api({ key: 'isPrivatePay' }) public readonly isPrivatePay: boolean;
  @api({ key: 'name' }) public readonly name: string;
}

export class InsuranceCompany extends InsuranceCompanyBase {
  public constructor(props: ClassProperties<InsuranceCompany>) {
    super(props);

    this.id = props.id;
  }

  /**
   * The io-ts codec for runtime type checking of the Insurance Company API
   * model.
   */
  public static readonly Codec = io.type(
    {
      id: io.number,
      isActive: io.boolean,
      isCustom: io.boolean,
      isPrivatePay: io.boolean,
      name: io.string,
    },
    'InsuranceCompanyApi',
  );

  @api({ key: 'id' }) public readonly id: number;

  /**
   * Deserializes a Insurance Company object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Insurance Company object.
   * @throws An error if the value is not a valid Insurance Company object.
   */
  public static deserialize(
    value: NonNullable<InsuranceCompanyApi>,
  ): InsuranceCompany {
    const decoded = decode(InsuranceCompany.Codec, value);
    return new InsuranceCompany({
      id: decoded.id,
      isActive: decoded.isActive,
      isCustom: decoded.isCustom,
      isPrivatePay: decoded.isPrivatePay,
      name: decoded.name,
    });
  }

  /**
   * Deserializes a list of Insurance Company objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Insurance Company objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Insurance Company objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<InsuranceCompanyApi>>,
  ): readonly InsuranceCompany[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Insurance Company objects.');
    }
    return values.map(InsuranceCompany.deserialize);
  }
}

/** The Insurance Company create (POST) model. */
export class InsuranceCompanyCreate extends InsuranceCompanyBase {
  public serialize(): InsuranceCompanyCreateApi {
    return {
      isActive: this.isActive,
      isCustom: this.isCustom,
      isPrivatePay: this.isPrivatePay,
      name: this.name,
    };
  }
}

/** The Insurance Company update (PUT) model. */
export class InsuranceCompanyUpdate extends InsuranceCompany {
  public serialize(): InsuranceCompanyUpdateApi {
    return {
      id: this.id,
      isActive: this.isActive,
      isCustom: this.isCustom,
      isPrivatePay: this.isPrivatePay,
      name: this.name,
    };
  }
}
