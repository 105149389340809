import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ReplaySubject,
  combineLatest,
  delay,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  of,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { ScreenSizeEnum, SortOrderEnum } from 'src/app/enumerators';
import { UserNotification, UserNotificationUpdate } from 'src/app/models';
import {
  AlertService,
  AuthenticationService,
  FacilityService,
  MenuService,
  ScreenSizeService,
  UserService,
} from 'src/app/services';
import {
  isNonEmptyValue,
  navigateOffsiteTo,
  shareSingleReplay,
} from 'src/app/utilities';

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'alleva-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss'],
  animations: [
    // Match the fade-out animation for the notifications menu where needed.
    trigger('fade-out', [
      state('void', style({ opacity: 0 })),
      transition(':leave', [animate('0.5s ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class LayoutHeaderComponent implements OnInit {
  public constructor(
    private readonly alertService: AlertService,
    private readonly authenticationService: AuthenticationService,
    private readonly datePipe: DatePipe,
    private readonly facilityService: FacilityService,
    private readonly menuService: MenuService,
    private readonly router: Router,
    private readonly screenSizeService: ScreenSizeService,
    private readonly userService: UserService,
  ) {}

  protected readonly isMobileChanges =
    this.screenSizeService.currentScreenSizeChanges.pipe(
      map((screenSize) => screenSize === ScreenSizeEnum.MOBILE),
    );

  protected readonly notificationsPage: PageRoute = '/notifications';

  /**
   * Whether or not the notifications menu is open. This is used to suspend
   * subscriptions until the menu is open.
   */
  protected isNotificationsMenuOpen = false;

  // Active notification index.
  protected activeNotificationId: number | null = null;

  private readonly userNotificationsCountSubject = new ReplaySubject<number>(1);
  protected readonly userNotificationsCountChanges =
    this.userNotificationsCountSubject.asObservable().pipe(shareSingleReplay());

  private readonly userNotificationsSubject = new ReplaySubject<
    readonly UserNotification[]
  >(1);
  protected readonly userNotificationsChanges = this.userNotificationsSubject
    .asObservable()
    .pipe(shareSingleReplay());

  public async ngOnInit(): Promise<void> {
    // Reactively check for notifications on every route or facility change.
    combineLatest([
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged(),
      ),
      this.facilityService.currentFacilityChanges,
    ])
      .pipe(startWith(null), untilDestroyed(this))
      .subscribe(() => {
        this.getSetUserNotificationsCount();
        this.getSetUserNotifications();
      });
  }

  /** Handle the toggle collapse/expand navigation menu button click event. */
  protected async onToggleMenuClick(): Promise<void> {
    const isMenuCollapsed = await firstValueFrom(
      this.menuService.isCollapsedChanges,
    );
    const nextState = !isMenuCollapsed;
    this.menuService.isCollapsed = nextState;
  }

  protected async markNotificationAsRead(
    notificationId: UserNotification['id'],
  ): Promise<void> {
    if (this.activeNotificationId === notificationId) {
      // Button is already active, so do nothing. This will prevent any extra
      // clicks while the button is disabled during the mark as read API call.
      return;
    }

    // Set the active notification id to the current notification id. This will
    // disable the button while the API call is in progress.
    this.activeNotificationId = notificationId;

    await firstValueFrom(of(null).pipe(delay(500)));

    const user = await firstValueFrom(this.authenticationService.userChanges);
    if (!user) {
      return;
    }

    const update = new UserNotificationUpdate({ isRead: true });
    const success = await firstValueFrom(
      this.userService.updateNotification(user, notificationId, update),
    );
    if (success) {
      const currentUserNotifications = await firstValueFrom(
        this.userNotificationsChanges,
      );
      // Remove the now read user notification from the list.
      this.userNotificationsSubject.next(
        currentUserNotifications.filter(
          (notification) => notification.id !== notificationId,
        ),
      );
      // Subtract one from the current user notifications count.
      const userNotificationsCount = await firstValueFrom(
        this.userNotificationsCountChanges,
      );
      this.userNotificationsCountSubject.next(userNotificationsCount - 1);
      this.alertService.success({
        message: 'Notification successfully marked as read.',
      });
    } else {
      this.alertService.error({
        message:
          'There was an issue marking the notification as read. Please try again or contact support.',
      });
    }

    this.activeNotificationId = null;
  }

  /**
   * Navigates to the given link in a new window.
   *
   * @param url The URL to navigate to.
   */
  protected navigateOffsiteTo(url: string): void {
    navigateOffsiteTo(url, { openInNewTab: true });
  }

  protected onCalendarClick(date: Date): void {
    const formattedDate = this.datePipe.transform(date, 'MM/dd/yyyy');
    // @todo - Navigate to the calendar/tasks page for the selected date.
    this.alertService.success({
      message: `Pending: Open calendar task list for "${formattedDate}".`,
    });
  }

  protected async getSetUserNotifications(): Promise<void> {
    if (!this.isNotificationsMenuOpen) {
      return;
    }

    const userNotifications = await firstValueFrom(
      this.authenticationService.userChanges.pipe(
        filter(isNonEmptyValue),
        withLatestFrom(this.facilityService.currentFacilityChanges),
        switchMap(([user, currentFacility]) =>
          this.userService.getPagedNotificationList(user, [
            { key: 'facilityId', value: currentFacility.id },
            { key: 'pageNumber', value: 1 },
            { key: 'pageSize', value: 25 }, // Max of 25 unread notifications.
            { key: 'isRead', value: false },
            { key: 'order', value: 'date' },
            { key: 'sort', value: SortOrderEnum.DESC },
          ]),
        ),
        map((pagedList) => pagedList?.results ?? []),
      ),
    );
    // Set the initial value of the `userNotifications` observable stream.
    this.userNotificationsSubject.next(userNotifications);
  }

  private async getSetUserNotificationsCount(): Promise<void> {
    const userNotificationsCount = await firstValueFrom(
      this.authenticationService.userChanges.pipe(
        filter(isNonEmptyValue),
        withLatestFrom(this.facilityService.currentFacilityChanges),
        switchMap(([user, currentFacility]) =>
          this.userService.getUnreadNotificationsCount(user, [
            { key: 'facilityId', value: currentFacility.id },
          ]),
        ),
      ),
    );
    this.userNotificationsCountSubject.next(userNotificationsCount);
  }
}
