import { TreatmentPlanReviewApi } from 'api/models';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { Client, TreatmentPlanReview } from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the Client Treatment Plan Reviews API.
 */
@Injectable()
export class TreatmentPlanReviewsService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch and return all client treatment plan reviews data for the given
   * client.
   *
   * @param clientId The client id to get the treatment plan reviews for.
   * @returns The client treatment plan reviews on success, undefined on error.
   */
  public getByClientId(
    clientId: Client['id'],
  ): Observable<readonly TreatmentPlanReview[] | undefined> {
    return this.httpClient
      .get<
        readonly TreatmentPlanReviewApi[] | undefined
      >(`${config.api.url}/clients/${clientId}/treatment-plan-reviews`, { params: { clientId } })
      .pipe(
        switchMap((response) =>
          response
            ? TreatmentPlanReview.deserializeList(response)
            : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
