import { SanitizerService } from 'src/app/services';

import { Injector, SecurityContext } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

declare global {
  // eslint-disable-next-line id-blacklist
  interface Number {
    /**
     * Checks if a number value is between two other values.
     *
     * Note: The `min` and `max` values are inclusive (`>=` and `<=` checks).
     *
     * @param min The minimum value.
     * @param max The maximum value.
     * @returns True if the value is between the min and max values.
     */
    isBetween(min: number, max: number): boolean;
  }

  // eslint-disable-next-line id-blacklist
  interface String {
    /** Converts a string to a SafeHtml object. */
    toSafeHtml(): SafeHtml;
    /** Strips all markup from a string. */
    stripHtml(): string;
  }
}

/**
 * Extends the `Number` prototype with additional `isBetween` method.
 *
 * @param min The minimum value.
 * @param max The maximum value.
 */
Number.prototype.isBetween = function (min: number, max: number): boolean {
  return this.valueOf() >= min && this.valueOf() <= max;
};

String.prototype.stripHtml = function (): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(this as string, 'text/html');
  return doc.body.textContent || '';
};

/**
 * Extends the `String` prototype with additional Angular `toSafeHtml` method.
 *
 * @param injector The Angular injector instance.
 */
export function initializeStringPrototype(injector: Injector): void {
  const sanitizerService = injector.get(SanitizerService);

  /**
   * Converts a string to a SafeHtml object.
   *
   * @param sanitize Whether to sanitize the string.
   */
  String.prototype.toSafeHtml = function (sanitize = true): SafeHtml {
    const value = sanitize
      ? sanitizerService.sanitizer.sanitize(
          SecurityContext.HTML,
          this as string,
        ) || ''
      : (this as string);
    return sanitizerService.sanitizer.bypassSecurityTrustHtml(value);
  };
}

export {};
